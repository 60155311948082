import { Pressable, View, ViewStyle } from 'react-native';

import { Text } from '@rneui/themed';

import { colors, fontFamily } from '../theme/theme';

export type Breadcrumb = {
  active: boolean;
  link?: string;
  label: string;
};

interface BreadcrumbsProps {
  breadcrumbs: Array<Breadcrumb>;
  rightComponent?: React.ReactNode;
  rightComponentContainerStyle?: ViewStyle;
  onPressCrumb?: (item: Breadcrumb, index: number) => void;
}

export function Breadcrumbs({
  breadcrumbs,
  rightComponent,
  rightComponentContainerStyle,
  onPressCrumb,
}: BreadcrumbsProps) {
  const activeCrumbIndex = breadcrumbs.findIndex((c) => c.active);

  return (
    <View
      style={{
        backgroundColor: colors.llSubnavDarkGray,
        height: 40,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 40,
        alignItems: 'center',
      }}
    >
      <View style={{ flexDirection: 'row', height: 20 }}>
        {breadcrumbs.map((c, i) => {
          const isCrumbPassed = i < activeCrumbIndex;

          const crumb = (
            <Text
              style={{
                color: isCrumbPassed
                  ? colors.llSubnavGrayLink
                  : c.active
                  ? colors.llSubnavActiveLink
                  : colors.llSubnavDarkLink,

                borderBottomWidth: isCrumbPassed ? 1 : 0,
                borderBottomColor: colors.llSubnavGrayLink,
                fontFamily: fontFamily.semiBold600,
              }}
            >
              {c.label}
            </Text>
          );
          const resolvedCrumb = isCrumbPassed ? (
            <Pressable onPress={() => onPressCrumb(c, i)}>{crumb}</Pressable>
          ) : (
            crumb
          );

          return (
            <View
              style={{ flexDirection: 'row' }}
              key={`crumb-${i}`}
              data-testID={`crumb-${i}`}
            >
              {resolvedCrumb}
              <Text
                style={{
                  color: isCrumbPassed
                    ? colors.llSubnavGrayLink
                    : c.active
                    ? colors.llSubnavActiveLink
                    : colors.llSubnavDarkLink,
                  fontFamily: fontFamily.semiBold600,
                  display: i === breadcrumbs.length - 1 ? 'none' : 'flex',
                }}
              >
                {' > '}
              </Text>
            </View>
          );
        })}
      </View>
      <View style={{ ...rightComponentContainerStyle }}>
        {rightComponent ? rightComponent : null}
      </View>
    </View>
  );
}
