import { api } from '../../../api';

export async function updatePickupTime(
  legID: number,
  offset: number,
): Promise<boolean> {
  const data = {
    pick_up_details: {
      offset,
    },
  };

  try {
    await api.patch(`v2/booking/pick_up_details/${legID}/`, data);
    return true;
  } catch (e) {
    return false;
  }
}
