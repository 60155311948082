import Constants from 'expo-constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Button, Card, Text } from '@rneui/themed';

import { PlainButton } from '../../../../components/button/PlainButton';
import {
  colors,
  fontFamily,
  sizing,
  spacing,
} from '../../../../components/theme/theme';
import { MainRouteConfig } from '../../../../routes/types';

const { MARKETING_SITE_URL } = Constants.manifest.extra;

interface WelcomeCardProps {
  returnScreen: string;
}

export function WelcomeCard({ returnScreen }: WelcomeCardProps) {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<MainRouteConfig>>();

  return (
    <Card
      containerStyle={{
        padding: 0,
        borderRadius: 5,
      }}
    >
      <Card.Title
        style={{
          fontSize: sizing.xl,
          backgroundColor: colors.primaryText,
          color: 'white',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          padding: spacing.md,
        }}
      >
        {t('screens.auth.welcomeCard.title')}
      </Card.Title>
      <View
        style={{
          padding: spacing.md,
          paddingTop: 0,
        }}
      >
        <Text
          style={{
            fontFamily: fontFamily.bold,
            alignSelf: 'center',
          }}
        >
          {t('screens.auth.welcomeCard.subtitle')}
        </Text>
        <Card.Divider />
        <Text>{t('screens.auth.welcomeCard.description')}</Text>
        <View
          style={{
            flexDirection: 'row',
            marginTop: spacing.lg,
          }}
        >
          <PlainButton
            title={t('screens.auth.register.link')}
            testID="welcome-card-register-button"
            containerStyle={{
              flex: 1,
              marginRight: spacing.lg,
              borderRadius: 4,
            }}
            onPress={() =>
              navigation.navigate('register', { return: returnScreen })
            }
          />
          <Button
            title={t('screens.auth.loginButton.label')}
            testID="welcome-card-login-button"
            containerStyle={{
              flex: 1,
            }}
            onPress={() =>
              navigation.navigate('login', { return: returnScreen })
            }
          />
        </View>
        {/* <ExternalLink
          url={`${MARKETING_SITE_URL}/milemarkers/`}
          title={t('screens.auth.welcomeCard.milemarkersLink')}
          titleStyle={{
            fontSize: sizing.md,
            fontFamily: fontFamily.bold,
            borderBottomWidth: 1,
          }}
        /> */}
      </View>
    </Card>
  );
}
