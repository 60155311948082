import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Avatar, Card, Image, Text } from '@rneui/themed';

import { ContactButton } from '../../../../components/button/ContactButton';
import { colors, sizing, spacing } from '../../../../components/theme/theme';
import { Driver, Vehicle } from '../../api/types';

interface DriverCardProps {
  driver: Driver;
  vehicle?: Vehicle;
}

export function DriverCard({ driver, vehicle }: DriverCardProps) {
  const { t } = useTranslation();
  return (
    <View>
      <Card>
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Avatar
              source={{ uri: driver.avatar }}
              title={`${driver.first_name[0]}${driver.last_name[0]}`}
              containerStyle={{
                borderWidth: 2,
                borderColor: colors.primaryText,
                marginRight: sizing.md,
              }}
              titleStyle={{
                color: colors.primaryText,
              }}
              rounded
            />
            <Text>{driver.first_name} </Text>
            <Text>{driver.last_name}</Text>
          </View>
          <View style={{ alignSelf: 'flex-end' }}>
            <ContactButton
              phone={driver.phone_number}
              title={`${t('global.common.call')} ${driver.first_name}`}
              triggerButtonProps={{
                titleStyle: {
                  fontSize: sizing.md,
                },
              }}
            />
          </View>
        </View>
        {vehicle ? (
          <View
            testID="vehicle-card"
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: spacing.md,
            }}
          >
            <Image
              source={
                false
                  ? { uri: vehicle.vehicle_image }
                  : require('../../../../../assets/default-car.png')
              }
              style={{
                width: 38,
                height: 25,
              }}
            />
            <View
              style={{
                marginLeft: spacing.md,
              }}
            >
              <Text>{vehicle.vehicle_color}</Text>
              <Text>{vehicle.vehicle_model}</Text>
              <Text>{vehicle.registration}</Text>
            </View>
          </View>
        ) : null}
      </Card>
    </View>
  );
}
