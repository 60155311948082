import { LEG_SERVICE_TYPE, Leg, Segment, Trip } from '../../../api/types';

interface HomeServiceLeg {
  type: 'pickup' | 'dropoff';
  iata: string;
  airportIata: string;
  leg: Leg;
}

export function getHomeServiceLegs(segment: Segment): Array<HomeServiceLeg> {
  return (segment?.legs ?? [])
    .filter(
      (leg) =>
        leg.is_editable &&
        leg.service_type === LEG_SERVICE_TYPE.SELECT &&
        (leg.depart.virtual || leg.arrive.virtual),
    )
    .map((leg) => {
      return {
        type: leg.depart.virtual ? 'pickup' : 'dropoff',
        iata: leg.depart.virtual ? leg.depart.iata : leg.arrive.iata,
        airportIata: leg.depart.virtual ? leg.arrive.iata : leg.depart.iata,
        leg,
      };
    });
}

export function findLeg(legId: number, trip: Trip): Leg {
  let leg: Leg = null;

  trip.segments.forEach((segment) => {
    if (leg) {
      return;
    }

    segment.legs.forEach((l) => {
      if (l.id === legId) {
        leg = l;
      }
    });
  });

  return leg;
}
