import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import base64 from 'react-native-base64';

import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Tab, TabView } from '@rneui/themed';

import { colors, sizing } from '../../components/theme/theme';
import { MainRouteConfig } from '../../routes/types';
import { set } from '../../state/secureStore';
import { LoginPrompt } from '../auth/LoginPrompt';
import { useAuth } from '../auth/components/context/useAuth';
import { getPastTrips } from './api/getPastTrips';
import { getUpcomingTrips } from './api/getUpcomingTrips';
import { Trip } from './api/types';
import { TripList } from './components/tripList/TripList';
import { tripState } from './state/tripState';

export function MyTripsScreen({
  navigation,
}: NativeStackScreenProps<MainRouteConfig, 'my-trips'>) {
  const [index, setIndex] = React.useState(0);
  const { t } = useTranslation();

  const handlePressTrip = useCallback(async (item: Trip) => {
    tripState.set((current) => ({
      ...current,
      currentTrip: item,
    }));

    // Accessing data so it becomes available in the next screen immediately
    tripState.get();

    await Promise.all([
      set('currentPnr', item.rloc),
      set('lastname', base64.encode(item.passengers[0].last_name)),
    ]);

    navigation.navigate('trip-details', {
      pnr: item.rloc,
    });
  }, []);

  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <LoginPrompt returnScreen="my-trips" />;
  }

  return (
    <View style={{ flex: 1 }}>
      <Tab
        value={index}
        onChange={(e) => setIndex(e)}
        indicatorStyle={{
          backgroundColor: colors.primaryText,
          height: 3,
        }}
        variant="primary"
      >
        <Tab.Item
          title={t('screens.tripDetail.myTrips.upcomingTripsTab')}
          titleStyle={{ fontSize: sizing.md }}
          testID="upcoming-trips-tab"
        />
        <Tab.Item
          title={t('screens.tripDetail.myTrips.pastTripsTab')}
          titleStyle={{ fontSize: sizing.md }}
          testID="past-trips-tab"
        />
      </Tab>

      <TabView value={index} onChange={setIndex} animationType="spring">
        <TabView.Item style={{ width: '100%' }}>
          <TripList getList={getUpcomingTrips} onPressTrip={handlePressTrip} />
        </TabView.Item>
        <TabView.Item style={{ width: '100%' }}>
          <TripList getList={getPastTrips} onPressTrip={handlePressTrip} />
        </TabView.Item>
      </TabView>
    </View>
  );
}
