import { api } from '../../../api';

type CreatePaymentMethodIntentResponse = {
  secret: string;
};

export async function createPaymentMethodIntent(): Promise<CreatePaymentMethodIntentResponse> {
  try {
    const { data } = await api.post<CreatePaymentMethodIntentResponse>(
      `door_to_door_payments/payment_method/`,
      {},
    );
    return data;
  } catch (e) {
    return null;
  }
}
