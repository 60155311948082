import Constants from 'expo-constants';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet, View, ViewStyle } from 'react-native';

import {
  BottomTabNavigationEventMap,
  BottomTabNavigationOptions,
} from '@react-navigation/bottom-tabs';
import { BottomTabNavigationConfig } from '@react-navigation/bottom-tabs/lib/typescript/src/types';
import {
  DefaultNavigatorOptions,
  ParamListBase,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  createNavigatorFactory,
  useNavigation,
  useNavigationBuilder,
} from '@react-navigation/native';
import { Image } from '@rneui/themed';

import { PlainButton } from '../button/PlainButton';
import { colors } from '../theme/theme';
import { TopTabBarContainer } from './TopTabBarContainer';

const { MARKETING_SITE_URL } = Constants.manifest.extra;

interface HeaderLinkProps {
  route: string;
  children: React.ReactNode;
  external?: boolean;
  containerStyle?: ViewStyle;
  testID?: string;
}

function HeaderLink({
  route,
  children,
  external,
  containerStyle,
  testID,
}: HeaderLinkProps) {
  const nav = useNavigation();
  return (
    <PlainButton
      testID={testID ?? 'top-nav-link'}
      containerStyle={{ width: 100, ...containerStyle }}
      titleStyle={{ color: colors.headerLink, fontSize: 16 }}
      buttonStyle={{ padding: 0, paddingHorizontal: 0, paddingVertical: 0 }}
      onPress={() => {
        if (external) {
          return Linking.openURL(route);
        }
        nav.navigate(route as any);
      }}
      hideBorder
    >
      {children}
    </PlainButton>
  );
}

type TabNavigatorProps = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  BottomTabNavigationOptions & {
    hideTabButton?: boolean;
  },
  BottomTabNavigationEventMap
> &
  TabRouterOptions &
  BottomTabNavigationConfig & {
    tabBarStyle?: ViewStyle;
    contentStyle?: ViewStyle;
  };

function TabNavigator({
  id,
  initialRouteName,
  backBehavior,
  children,
  screenListeners,
  screenOptions,
  tabBarStyle,
  contentStyle,
}: TabNavigatorProps) {
  const { t } = useTranslation();
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder(TabRouter, {
      id,
      initialRouteName,
      backBehavior,
      children,
      screenListeners,
      screenOptions,
    });

  const focusedRouteKey = state.routes[state.index].key;
  const [loaded, setLoaded] = React.useState([focusedRouteKey]);

  if (!loaded.includes(focusedRouteKey)) {
    setLoaded([...loaded, focusedRouteKey]);
  }

  return (
    <NavigationContent>
      <TopTabBarContainer>
        {/* Disabling direct book link until we can support independent bookings */}
        {/* <HeaderLink route="book">
          {t('components.navigators.bookNow')}
        </HeaderLink> */}
        <HeaderLink route={`${MARKETING_SITE_URL}/how-it-works/`} external>
          {t('components.navigators.howItWorks')}
        </HeaderLink>
        <HeaderLink route="about">
          {t('components.navigators.getHelp')}
        </HeaderLink>
        <Image
          source={{
            uri: require('../../../assets/landline-separator.svg'),
          }}
          style={{
            width: 6,
            height: 20,
          }}
        />
        <HeaderLink route="my-trips" testID="top-nav-link-my-trips">
          {t('components.navigators.myTrips')}
        </HeaderLink>
        <HeaderLink
          route="account"
          containerStyle={{ width: 20 }}
          testID="top-nav-link-account-screen"
        >
          <Image
            source={{ uri: require('../../../assets/icons/user-profile.svg') }}
            style={{
              width: 20,
              height: 20,
            }}
          />
        </HeaderLink>
      </TopTabBarContainer>
      <View style={[{ flex: 1, overflow: 'hidden' }, contentStyle]}>
        {state.routes.map((route, index) => {
          const descriptor = descriptors[route.key];
          const { lazy = true, unmountOnBlur = true } = descriptor.options;
          const isFocused = state.index === index;

          if (unmountOnBlur && !isFocused) {
            return null;
          }

          if (lazy && !loaded.includes(route.key) && !isFocused) {
            // Don't render a lazy screen if we've never navigated to it
            return null;
          }

          return (
            <View
              key={route.key}
              style={[
                StyleSheet.absoluteFill,
                { display: isFocused ? 'flex' : 'none' },
              ]}
            >
              {descriptors[route.key].render()}
            </View>
          );
        })}
      </View>
    </NavigationContent>
  );
}

export const createTopTabNavigator = createNavigatorFactory(TabNavigator);
