import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckBox } from '@rneui/themed';

import { cardMargin } from '../../../../components/theme/theme';
import { Segment } from '../../api/types';
import { updateSegmentBaggage } from '../../api/updateSegmentBaggage';
import { tripState } from '../../state/tripState';

interface CheckedBagInputProps {
  segment: Segment;
  onUpdateBaggage: () => void;
}

export function CheckedBagInput({
  segment,
  onUpdateBaggage,
}: CheckedBagInputProps) {
  const [checked, setChecked] = useState(!!segment.checked_baggage);
  const { t } = useTranslation();

  const handleSetHasBaggage = useCallback(
    async (checked: boolean) => {
      try {
        setChecked(checked);
        await updateSegmentBaggage(segment.id, checked);

        onUpdateBaggage();
      } catch (e) {
        // Something didn't work, reverse checked state
        setChecked(!checked);
        console.log('Faild to update bag', e);
      }
    },
    [segment],
  );

  return (
    <CheckBox
      containerStyle={{
        padding: cardMargin,
      }}
      title={t('screens.tripDetail.checkedBag.label')}
      checked={checked}
      onPress={() => handleSetHasBaggage(!checked)}
    />
  );
}
