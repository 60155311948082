import { FieldError } from 'react-hook-form';

import { CreateUserValidationErrors } from '../../api/createUser';

interface RegisterFormErrors {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  password?: string;
  verifyPassword?: string;
}

interface RegisterFormValidationErrors {
  first_name?: FieldError;
  last_name?: FieldError;
  phone_number?: FieldError;
  email?: FieldError;
  password?: FieldError;
  verifyPassword?: FieldError;
}

export function getErrors(
  formErrors: RegisterFormValidationErrors,
  serverErrors: CreateUserValidationErrors,
): RegisterFormErrors {
  const formErrorMessages = Object.keys(formErrors ?? {}).reduce((acc, key) => {
    return {
      ...acc,
      [key]: formErrors[key].message,
    };
  }, {});

  const serverErrorMessages = Object.keys(serverErrors ?? {}).reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: serverErrors[key].join(','),
      };
    },
    {},
  );

  return {
    ...formErrorMessages,
    ...serverErrorMessages,
  };
}
