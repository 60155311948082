import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';

import { Button } from '@rneui/themed';

import { globalState } from '../../../state/globalState';
import { set } from '../../../state/secureStore';
import { updateUserProfile } from '../../auth/api/updateUserProfile';
import { upgradeHashUserAccount } from '../../auth/api/upgradeHashUserAccount';
import { useAuth } from '../../auth/components/context/useAuth';
import { tripState } from '../../trip/state/tripState';
import { CardContainer } from '../components/CardContainer';
import { bookWizardState } from '../state/bookWizardState';
import { flightInfoCardState } from '../state/flightInfoCardState';
import { passengerInfoState } from '../state/passengerInfoState';
import {
  PassengerInfoForm,
  PassengerInfoFormFields,
} from './forms/PassengerInfoForm';

interface PassengerInfoCardProps {
  onPressNext: () => void;
}

export function PassengerInfoCard({ onPressNext }: PassengerInfoCardProps) {
  const { t } = useTranslation();
  const { setToken, isLoggedIn } = useAuth();

  const { currentTrip } = tripState.useValue();
  const flightInfoFormState = flightInfoCardState.useSelector(
    (state) => state.form,
  );

  // Hydrate store state from trip
  useEffect(() => {
    if (!currentTrip) {
      return;
    }

    // Assuming the primary passenger is the first in the list
    const primaryPassenger = currentTrip.passengers[0];

    passengerInfoState.set((state) => ({
      ...state,
      form: {
        ...state.form,
        title: flightInfoFormState.title,
        firstName: flightInfoFormState.firstName,
        lastName: flightInfoFormState.lastName,
        email: primaryPassenger.email,
        phone: primaryPassenger.phone_number,
      },
    }));
  }, [currentTrip, flightInfoFormState]);

  const storeState = passengerInfoState.useSelector((state) => state.form);

  // Initialize form control using store values as default
  const passengerForm = useForm<PassengerInfoFormFields>({
    defaultValues: {
      ...storeState,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // Hydrate form state from store (if its programmatically updated)
  useEffect(() => {
    Object.keys(storeState).forEach((field: keyof PassengerInfoFormFields) => {
      const value = storeState[field];
      passengerForm.setValue(field, value, {
        shouldValidate: true,
      });
    });
  }, [storeState]);

  // Update crumb state
  useEffect(() => {
    globalState.set((state) => ({
      ...state,
      showFooter: false,
    }));

    bookWizardState.set((state) => ({
      ...state,
      showBreadcrumbs: true,
    }));
  }, []);

  // Update store state after submit, proceed to next
  const updateStore = useCallback(async (fields: PassengerInfoFormFields) => {
    flightInfoCardState.set((state) => ({
      ...state,
      form: {
        ...fields,
      },
    }));

    try {
      if (fields.password) {
        const upgradeResult = await upgradeHashUserAccount({
          phone_number: fields.phone,
          password: fields.password,
        });

        console.log('upgradeResult', upgradeResult);

        setToken(upgradeResult);
        await Promise.all([
          set('accessToken', upgradeResult.access),
          set('refreshToken', upgradeResult.refresh),
        ]);
      }

      const updateResult = await updateUserProfile({
        username: fields.email,
        firstName: fields.firstName,
        lastName: fields.lastName,
        phoneNumber: fields.phone,
      });

      if (!updateResult) {
        Toast.show({
          text1: 'Failed to update profile',
          type: 'error',
        });
        return;
      }

      onPressNext();
    } catch (e) {
      Toast.show({
        text1: 'Failed to update profile',
        type: 'error',
      });
    }
  }, []);

  return (
    <CardContainer>
      <PassengerInfoForm formControl={passengerForm} hasAccount={isLoggedIn} />
      <Button
        title={t('screens.book.passengerInfoCard.actions.submit')}
        containerStyle={{
          width: 300,
          alignSelf: 'center',
          marginTop: 35,
          paddingBottom: 100,
        }}
        onPress={passengerForm.handleSubmit(updateStore)}
      />
    </CardContainer>
  );
}
