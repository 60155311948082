import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Input } from '@rneui/themed';

import { SecondaryButton } from '../../../../components/button/SecondaryButton';
import { CreateUserValidationErrors } from '../../api/createUser';
import { getErrors } from './getErrors';

interface AccountDetailsFormValues {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
}

interface AccountDetailsFormFields extends AccountDetailsFormValues {
  password?: string;
  verifyPassword?: string;
}

interface AccountDetailsFormProps {
  account?: AccountDetailsFormValues;
  serverErrors?: CreateUserValidationErrors;
  loading?: boolean;
  type?: 'create' | 'update';
  submitTitle?: string;
  onSubmit?: (values: AccountDetailsFormFields) => void;
}

export function AccountDetailsForm({
  account = {},
  serverErrors,
  loading,
  type,
  submitTitle,
  onSubmit,
}: AccountDetailsFormProps) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    watch,
  } = useForm<AccountDetailsFormFields>({
    mode: 'onChange',
    defaultValues: account,
  });

  const currentPassword = watch('password');

  const allErrors = getErrors(errors, serverErrors);

  return (
    <View style={{ width: '100%' }}>
      <Controller
        name="first_name"
        control={control}
        defaultValue={account.first_name}
        rules={{
          required: t('global.form.required'),
        }}
        render={({ field }) => (
          <Input
            {...field}
            textContentType="name"
            testID="first-name"
            label={t('screens.auth.firstName.label')}
            placeholder={t('screens.auth.firstName.label')}
            errorMessage={allErrors?.first_name}
            onChangeText={field.onChange}
          />
        )}
      />
      <Controller
        name="last_name"
        control={control}
        defaultValue={account.last_name}
        rules={{
          required: t('global.form.required'),
        }}
        render={({ field }) => (
          <Input
            {...field}
            testID="last-name"
            textContentType="familyName"
            label={t('screens.auth.lastName.label')}
            placeholder={t('screens.auth.lastName.label')}
            errorMessage={allErrors?.last_name}
            onChangeText={field.onChange}
          />
        )}
      />
      <Controller
        name="phone_number"
        control={control}
        defaultValue={account.phone_number}
        rules={{
          required: t('global.form.required'),
        }}
        render={({ field }) => (
          <Input
            {...field}
            testID="phone-number"
            textContentType="telephoneNumber"
            keyboardType="phone-pad"
            label={t('screens.auth.phoneNumber.label')}
            placeholder={t('screens.auth.phoneNumber.label')}
            errorMessage={allErrors?.phone_number}
            onChangeText={field.onChange}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        defaultValue={account.email}
        rules={{
          required: t('global.form.required'),
        }}
        render={({ field }) => (
          <Input
            {...field}
            testID="username"
            textContentType="emailAddress"
            label={t('screens.auth.username.label')}
            placeholder={t('screens.auth.username.label')}
            errorMessage={allErrors?.email}
            onChangeText={field.onChange}
          />
        )}
      />

      {type === 'create' ? (
        <>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: t('global.form.required'),
              minLength: {
                value: 6,
                message: t('screens.auth.createPassword.minLength'),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                testID="password"
                textContentType="password"
                secureTextEntry
                label={t('screens.auth.createPassword.label')}
                placeholder={t('screens.auth.createPassword.label')}
                errorMessage={allErrors?.password}
                onChangeText={field.onChange}
              />
            )}
          />
          <Controller
            name="verifyPassword"
            control={control}
            defaultValue=""
            rules={{
              required: t('global.form.required'),
              minLength: {
                value: 6,
                message: t('screens.auth.createPassword.minLength'),
              },
              validate: (value) =>
                value === currentPassword ||
                t('screens.auth.validation.passwordMismatch'),
            }}
            render={({ field }) => (
              <Input
                {...field}
                testID="verify-password"
                textContentType="password"
                secureTextEntry
                label={t('screens.auth.verifyPassword.label')}
                placeholder={t('screens.auth.verifyPassword.label')}
                errorMessage={allErrors?.verifyPassword}
                onChangeText={field.onChange}
              />
            )}
          />
        </>
      ) : null}
      <SecondaryButton
        testID="register"
        loading={loading}
        title={submitTitle ?? t('screens.auth.registerButton.label')}
        disabled={!isValid}
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
}
