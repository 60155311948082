import React from 'react';
import { View } from 'react-native';

import { spacing } from '../../../../components/theme/theme';

interface TripWizardCardContainerProps {
  children: JSX.Element;
  height?: number;
  width?: number;
}

export function TripWizardCardContainer({
  children,
  height = 300,
  width = 350,
}: TripWizardCardContainerProps) {
  return (
    <View
      style={{
        padding: spacing.lg,
        width,
        height,
      }}
    >
      {children}
    </View>
  );
}
