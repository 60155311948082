import Constants from 'expo-constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Text } from '@rneui/themed';

import { ExternalLink } from '../button/ExternalLink';
import { LandlineLogo } from '../logo/LandlineLogo';
import { colors, fontFamily } from '../theme/theme';

const { MARKETING_SITE_URL } = Constants.manifest.extra;

export function Footer() {
  const { t } = useTranslation();
  const darkLogoHeader = useMemo(
    () => (
      <View
        style={{
          backgroundColor: colors.primaryText,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LandlineLogo light />
      </View>
    ),
    [],
  );

  return (
    <View
      style={{
        paddingTop: 32,
        paddingRight: 45,
        paddingBottom: 30,
        paddingLeft: 45,
        backgroundColor: colors.primaryGray,
        height: 196,
      }}
    >
      <View
        style={{
          justifyContent: 'space-between',
          alignContent: 'center',
          flexDirection: 'row',
        }}
      >
        <View>
          {darkLogoHeader}
          <Text
            style={{
              color: colors.footerText,
              fontSize: 16,
              marginTop: 10,
            }}
          >
            Landline.com
          </Text>
        </View>
        <View>
          <Text
            style={{
              color: colors.footerText,
              fontFamily: fontFamily.semiBold600,
              fontSize: 18,
            }}
          >
            {t('global.landline.customerServicePhoneLabel')}
          </Text>
        </View>
      </View>
      <View
        style={{
          alignItems: 'center',
        }}
      >
        <View
          style={{
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              color: colors.footerText,
              fontSize: 16,
            }}
          >
            {t('global.landline.copyright')}
          </Text>
          <Text
            style={{
              color: colors.footerText,
              fontSize: 16,
            }}
          >
            {t('global.landline.rights')}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ExternalLink
            titleStyle={{
              color: colors.primary,
              fontSize: 16,
            }}
            buttonStyle={{
              paddingHorizontal: 5,
            }}
            title={t('screens.about.sections.legal.links.terms')}
            url={`${MARKETING_SITE_URL}/terms-and-conditions/`}
          />
          <Text
            style={{
              color: colors.footerText,
              fontSize: 16,
            }}
          >
            |
          </Text>
          <ExternalLink
            titleStyle={{
              color: colors.primary,
              fontSize: 16,
            }}
            buttonStyle={{
              paddingHorizontal: 5,
            }}
            title={t('screens.about.sections.legal.links.privacy')}
            url={`${MARKETING_SITE_URL}/privacy-policy/`}
          />
        </View>
      </View>
    </View>
  );
}
