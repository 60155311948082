import React, { forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import { Dimensions, Platform, TextInput, View, ViewStyle } from 'react-native';
import {
  AutocompleteDropdown,
  AutocompleteDropdownRef,
} from 'react-native-autocomplete-dropdown';

import { Text, useTheme } from '@rneui/themed';

import { fontFamily } from '../theme/theme';
import { TextInput as LLTextInput, TextInputProps } from './TextInput';

interface SelectProps<TData extends unknown> {
  data: Array<TData>;
  label: React.ReactNode | string;
  width?: number;
  containerStyle?: ViewStyle;
  suggestionsListContainerStyle?: ViewStyle;
  placeholder?: string;
  initialValue?: TData;
  errorMessage?: string;
  required?: boolean;
  onSelectItem: (value: TData) => void;
}

type Datum = {
  title: string;
  value: unknown;
};

export function Select<TData extends Datum>({
  data,
  label,
  width,
  containerStyle,
  suggestionsListContainerStyle,
  placeholder,
  initialValue,
  required,
  errorMessage,
  onSelectItem,
}: SelectProps<TData>) {
  const dropdownController = useRef<AutocompleteDropdownRef>(null);
  const { theme } = useTheme();

  const suggestions = useMemo(
    () =>
      data.map((datum) => ({
        id: datum.value as string,
        title: datum.title,
      })),
    [],
  );

  const parsedInitialValue = useMemo(() => {
    if (initialValue) {
      return {
        id: initialValue,
      };
    }
  }, [initialValue]);

  useEffect(() => {
    if (dropdownController.current) {
      dropdownController.current.setInputText(initialValue?.title ?? '');
    }
  }, [dropdownController, initialValue]);

  return (
    <View
      style={[
        { flex: 1, flexDirection: 'row', alignItems: 'center', zIndex: 1 },
        containerStyle,
        width
          ? {
              width,
              maxWidth: width,
            }
          : {},
      ]}
    >
      <AutocompleteDropdown
        controller={(controller) => {
          dropdownController.current = controller;
        }}
        direction="down"
        dataSet={suggestions}
        initialValue={parsedInitialValue}
        onSelectItem={(item) => {
          if (item) {
            const selectedValue = data.find((d) => d.value === item.id);
            onSelectItem(selectedValue);
            dropdownController.current.close();
          }
        }}
        suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
        loading={false}
        useFilter={false} // set false to prevent rerender twice
        textInputProps={
          {
            placeholder,
            autoCorrect: false,
            autoCapitalize: 'none',
            disabled: true,
            required,
            errorMessage,
            style: {
              height: 40,
              margin: 0,
              marginVertical: 0,
              ...(width
                ? {
                    width: width - 2,
                  }
                : {}),
            },
            containerStyle: {
              paddingHorizontal: 0,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              marginVertical: 0,
              ...containerStyle,
              ...(width
                ? {
                    width,
                    maxWidth: width,
                  }
                : {}),
              padding: 0,
              margin: 0,
            },
            inputStyle: {
              color: '#000',
              fontFamily: fontFamily.regular,
              marginVertical: 0,
              margin: 0,
              height: 40,
              ...containerStyle,
              ...(width
                ? {
                    width,
                    maxWidth: width,
                  }
                : {}),
            },
            disabledInputStyle: {
              opacity: 1,
            },
            inputContainerStyle: {
              backgroundColor: 'transparent',
              margin: 0,
              marginVertical: 0,
              ...containerStyle,
              ...(width
                ? {
                    width,
                    maxWidth: width,
                  }
                : {}),
            },
            testID: 'select-autocomplete',
            label,
            rightButtonsContainerStyle: {
              height: 'auto',
              alignSelf: 'center',
            },
          } as TextInputProps
        }
        suggestionsListContainerStyle={{
          ...Platform.select({
            web: {
              backgroundColor: theme.colors.white,
              borderColor: theme.colors.divider,
              borderWidth: 1,
              borderStyle: 'solid',
              width: '100%',
              zIndex: 10,
              ...suggestionsListContainerStyle,
              ...(width ? { width } : {}),
            },
          }),
        }}
        renderItem={(item) => (
          <Text style={{ color: theme.colors.black, padding: 15 }}>
            {item.title}
          </Text>
        )}
        inputContainerStyle={{
          backgroundColor: 'transparent',
        }}
        containerStyle={{
          flexGrow: 1,
          flexShrink: 1,
          padding: 0,
          marginVertical: 0,
        }}
        /**
         * Ignoring the prop type inconsistency... it's basically a TextInput,
         * and the props overlap enough
         * @ts-ignore-next */
        InputComponent={LLTextInput}
        showChevron={false}
        showClear={false}
        closeOnBlur={false}
      />
    </View>
  );
}
