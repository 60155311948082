import React from 'react';

import { colors, sizing } from '../theme/theme';
import { PlainButton, PlainButtonProps } from './PlainButton';

interface IconButtonProps {
  icon: 'edit' | 'plus' | 'remove';
  color?: string;
  onPress: PlainButtonProps['onPress'];
}

export function IconButton({ icon, color, onPress }: IconButtonProps) {
  return (
    <PlainButton
      hideBorder
      buttonStyle={{ padding: 0, paddingHorizontal: 0 }}
      icon={{
        name: icon,
        type: 'font-awesome',
        size: sizing.md,
        color: color ? color : colors.accent,
      }}
      iconRight
      onPress={onPress}
    />
  );
}
