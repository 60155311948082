import { useMemo } from 'react';
import { View } from 'react-native';

import { LandlineLogo } from '../logo/LandlineLogo';
import { colors } from '../theme/theme';

interface TopTabBarContainerProps {
  children?: JSX.Element | Array<JSX.Element>;
}

export function TopTabBarContainer({ children }: TopTabBarContainerProps) {
  const darkLogoHeader = useMemo(
    () => (
      <View
        style={{
          backgroundColor: colors.primaryText,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LandlineLogo light />
      </View>
    ),
    [],
  );

  return (
    <View
      style={{
        height: 85,
        paddingTop: 32,
        paddingRight: 45,
        paddingBottom: 30,
        paddingLeft: 45,
        backgroundColor: colors.primaryGray,
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      {darkLogoHeader}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {children}
      </View>
    </View>
  );
}
