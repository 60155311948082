import { api } from '../../../api';

export interface AuthenticateResult {
  access: string;
  refresh: string;
}

export async function authenticate(
  username: string,
  password: string,
): Promise<AuthenticateResult> {
  try {
    const { data } = await api.post('/sessions/', {
      email: username,
      password,
    });

    return {
      access: data.access,
      refresh: data.refresh,
    };
  } catch (e) {
    // Failed auth, failure reason doesn't matter that much here because the result is the same
    return null;
  }
}
