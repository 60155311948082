import { Leg, LegAddress } from '../../screens/trip/api/types';

export function checkIfMissingAddress(leg: Leg) {
  if (!leg) {
    return false;
  }

  if (leg.depart?.virtual) {
    return !validAddress(leg.pick_up_details?.pick_up_address);
  }

  return !validAddress(leg.pick_up_details?.drop_off_address);
}

export function validAddress(address: LegAddress) {
  const hasValidAddress =
    address?.first_address_line.length > 0 &&
    address?.first_address_line !== '/';

  return hasValidAddress;
}

const addressKeys = [
  'first_address_line',
  'second_address_line',
  'city',
  'state',
  'zip_code',
];

export function compareAddress(
  address1: LegAddress,
  address2: LegAddress,
): boolean {
  return addressKeys.every((key: keyof LegAddress) => {
    return address1[key] === address2[key];
  });
}

export function format(address: LegAddress): string {
  return `${address.first_address_line}${
    address.second_address_line ? ' ' + address.second_address_line : ''
  }, ${address.city}, ${address.state} ${address.zip_code ?? ''}`;
}
