import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import base64 from 'react-native-base64';

import { useRoute } from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingBus } from '../../components/loading/LoadingBus';
import { NotFound } from '../../components/notFound/NotFound';
import { MainRouteConfig } from '../../routes/types';
import { set } from '../../state/secureStore';
import { getTripFromReservationHash } from './api/getTripFromReservationHash';
import { tripState } from './state/tripState';

interface ReservationIngestScreenProps {
  navigation: NativeStackScreenProps<
    MainRouteConfig,
    'reservation'
  >['navigation'];
}

/**
 * This screen will ingest the reservation code from the URL, and route to the
 * trip.
 */
export function ReservationIngestScreen({
  navigation,
}: ReservationIngestScreenProps) {
  const { t } = useTranslation();
  const route = useRoute();
  const routeParams = (route.params ?? {}) as MainRouteConfig['reservation'];

  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (!routeParams.hash) {
      return;
    }

    getTripFromReservationHash(routeParams.hash)
      .then(async (result) => {
        // Do we have enough legs for the trip?
        if (
          !(result.segments?.length >= 1 && result.segments[0].legs?.length > 1)
        ) {
          setError(true);
          return;
        }

        tripState.set((current) => {
          return {
            ...current,
            currentTrip: result,
          };
        });

        await Promise.all([
          set('reservationHash', routeParams.hash),
          set('currentPnr', result.rloc),
          set('lastname', base64.encode(result.passengers[0].last_name)),
        ]);

        // Accessing data so it becomes available in the next screen immediately
        tripState.get();

        navigation.navigate('book', {
          pnr: result.rloc,
        });
      })
      .catch((e) => {
        console.log('Failed with', e);
        setError(true);
      });
  }, [routeParams]);

  if (!routeParams.hash || hasError) {
    return <NotFound title={t('screens.tripDetail.pnrNotFound')} />;
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingBus />
    </View>
  );
}
