import { api } from '../../../api';

export async function setPaymentMethod(
  reservationId: number,
  paymentMethodId: number,
): Promise<boolean> {
  const data = {
    reservation_id: reservationId,
    payment_method_id: paymentMethodId,
  };

  try {
    await api.post(`/reservations/set_payment_method/`, data);
    return true;
  } catch (e) {
    return false;
  }
}
