import { newRidgeState } from 'react-ridge-state';

import { TimeInterval } from '../../trip/components/tripWizard/utils/dates';

export type TimeCardFormState = {
  hasBaggage?: boolean;
  interval?: TimeInterval;
  flightTime?: string;
  intervals?: Array<TimeInterval>;
  segmentId?: number;
  legId?: number;
};
export interface TimeCardState {
  form: {
    pickup?: TimeCardFormState;
    dropoff?: TimeCardFormState;
  };
}

export const timeCardState = newRidgeState<TimeCardState>({
  form: {
    pickup: {
      hasBaggage: false,
    },
    dropoff: {
      hasBaggage: false,
    },
  },
});
