import { forwardRef, useCallback, useState } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';

import { TextInput, TextInputProps } from './TextInput';

export const PasswordRevealField = forwardRef<RNTextInput, TextInputProps>(
  (props, ref) => {
    const [revealed, setRevealed] = useState(false);
    const toggleRevealed = useCallback(() => {
      setRevealed(!revealed);
    }, [revealed]);

    return (
      <TextInput
        {...props}
        ref={ref}
        textContentType="password"
        secureTextEntry={!revealed}
        rightIcon={
          <Icon
            onPress={toggleRevealed}
            name={revealed ? 'eye' : 'eye-slash'}
            size={17}
          />
        }
        rightIconContainerStyle={{
          paddingRight: 8,
        }}
      />
    );
  },
);
