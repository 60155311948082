import { api } from '../../../api';

type ValidateAllowedDistanceResponse = {
  allowed_radius: number;
};

export async function validateAllowedAddressDistance(
  // Must contain the full address
  address: string,
  iata: string = 'MSX',
): Promise<boolean> {
  try {
    const { data } = await api.post<ValidateAllowedDistanceResponse>(
      `v2/booking/airports/${iata}/address_radius_validation/`,
      {
        first_address_line: address,
      },
    );
    return data.allowed_radius <= 0;
  } catch (e) {
    return null;
  }
}
