import React from 'react';
import { View } from 'react-native';

import { Text } from '@rneui/themed';

import { fontFamily } from '../../../../../components/theme/theme';

interface InstructionsLabelProps {
  title: string;
  instructions: string;
  containerStyle?: {
    marginTop: number;
  };
}

export function InstructionsLabel({
  title,
  instructions,
  containerStyle,
}: InstructionsLabelProps) {
  return (
    <View style={containerStyle}>
      <Text style={{ fontFamily: fontFamily.bold }}>{title}</Text>
      <Text>{instructions}</Text>
    </View>
  );
}
