import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, View } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { ListItem } from '@rneui/base';
import { Text } from '@rneui/themed';

import { LoadingBus } from '../../../../components/loading/LoadingBus';
import { EmptyState } from '../../../../components/notFound/EmptyState';
import { colors, sizing, spacing } from '../../../../components/theme/theme';
import { first } from '../../../../components/utils/array';
import {
  HOME_SERVICE_VIRTUAL_AIRPORT_IATA,
  PaginatedResult,
  Trip,
} from '../../api/types';

export function TripList({
  getList,
  onPressTrip,
}: {
  getList: () => Promise<PaginatedResult<Trip>>;
  onPressTrip: (t: Trip) => void;
}) {
  const { t } = useTranslation();
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);

  const getIata = useCallback((iata) => {
    return iata === HOME_SERVICE_VIRTUAL_AIRPORT_IATA
      ? t('screens.tripDetail.myTrips.homeIata')
      : iata;
  }, []);

  useEffect(() => {
    getList().then((response) => {
      if (!response) {
        setLoading(false);
        return;
      }

      const { results } = response;
      setTrips(results);
      setLoading(false);
    });
  }, [setTrips, setLoading]);

  const renderItem = useCallback(({ item }: { item: Trip }) => {
    const departureSegment = first(item.segments);

    const iataJourney = departureSegment.legs
      .map((l) => [l.depart.iata, l.arrive.iata])
      .reduce((buff, iataSet, index) => {
        return [
          ...buff,
          <React.Fragment key={`leg-${index}`}>
            <Text style={{ fontSize: sizing.lg }}>
              {getIata(iataSet[0])}
              {' - '}
            </Text>
            <Text style={{ fontSize: sizing.lg }}>{getIata(iataSet[1])}</Text>
            {index < departureSegment.legs.length - 1 ? (
              <Icon
                name="circle"
                size={sizing.sm}
                style={{
                  marginHorizontal: spacing.md,
                }}
              />
            ) : null}
          </React.Fragment>,
        ];
      }, []);

    return (
      <ListItem testID="trip-list-item" bottomDivider>
        <ListItem.Content>
          <ListItem.Title
            onPress={() => onPressTrip(item)}
            style={{
              width: '100%',
            }}
          >
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {iataJourney}
                <Text
                  testID="trip-list-item-rloc"
                  style={{
                    marginLeft: spacing.sm,
                    fontSize: sizing.md,
                    // Not enough room to include this in mobile
                    ...Platform.select({
                      android: {
                        display: 'none',
                      },
                      ios: {
                        display: 'none',
                      },
                    }),
                  }}
                >
                  ({item.rloc})
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text>
                  {format(
                    parseISO(departureSegment.legs[0].departure_time),
                    'eeee, MMMM co yyyy',
                  )}
                </Text>
              </View>
            </View>
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron type="font-awesome" name="chevron-right" />
      </ListItem>
    );
  }, []);

  const keyExtractor = useCallback((item: Trip) => item.rloc, []);

  return (
    <View
      style={{
        flex: 1,
        flexGrow: 1,
      }}
    >
      {loading ? (
        <LoadingBus />
      ) : trips.length === 0 ? (
        <EmptyState
          icon="ticket"
          title={t('screens.tripDetail.tripsNotFound')}
          testID="trip-list-empty-state"
        />
      ) : (
        <FlatList
          style={{
            flex: 1,
          }}
          data={trips}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      )}
    </View>
  );
}
