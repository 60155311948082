import { api } from '../../../api';

export async function deleteLegSpecialRequest(
  noteId: number,
): Promise<boolean> {
  try {
    await api.delete(`v2/booking/notes/${noteId}/delete/`);
    return true;
  } catch (e) {
    return false;
  }
}
