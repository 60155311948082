import { AxiosError } from 'axios';

import { api } from '../../../api';
import { CreateUserValidationErrorResult } from './createUser';

interface UpdateUserParams {
  username: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export async function updateUserProfile(
  userParams: UpdateUserParams,
): Promise<true | CreateUserValidationErrorResult> {
  try {
    await api.put('/profile/', {
      email: userParams.username,
      first_name: userParams.firstName,
      last_name: userParams.lastName,
      phone_number: userParams.phoneNumber,
    });

    return true;
  } catch (e) {
    const networkError = e as AxiosError;
    if (networkError.response && networkError.response.data) {
      return networkError.response.data as CreateUserValidationErrorResult;
    }

    return null;
  }
}
