import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Toast from 'react-native-toast-message';

import { Link, useRoute } from '@react-navigation/native';
import { Button, Input, Text } from '@rneui/themed';

import { useSubmitOnEnter } from '../../components/hooks/useSubmitOnEnter';
import { LandlineLogo } from '../../components/logo/LandlineLogo';
import { fontFamily, spacing } from '../../components/theme/theme';
import { MainRouteConfig } from '../../routes/types';
import { set } from '../../state/secureStore';
import { authenticate } from './api';
import { useAuth } from './components/context/useAuth';

export function LoginScreen({ navigation }) {
  const { t } = useTranslation();
  const { setToken } = useAuth();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const route = useRoute();
  const routeParams = route.params as MainRouteConfig['login'];

  const handleLogin = handleSubmit(
    useCallback(
      async ({ username, password }) => {
        setLoading(true);

        const result = await authenticate(username, password);

        if (!result) {
          Toast.show({
            type: 'error',
            text1: t('screens.auth.action.authenticate.error'),
          });
        } else {
          setToken(result);
          await Promise.all([
            set('accessToken', result.access),
            set('refreshToken', result.refresh),
          ]);

          const returnTo =
            routeParams.return && routeParams.return !== 'undefined'
              ? routeParams.return
              : 'my-trips';

          navigation.navigate(returnTo);
        }

        setLoading(false);
      },
      [setToken],
    ),
  );

  const submitOnEnter = useSubmitOnEnter(handleLogin);

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 25,
      }}
    >
      <View style={{ flex: 1 }} />
      <View style={{ width: '100%', paddingHorizontal: spacing.md }}>
        <Controller
          name="username"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <Input
              {...field}
              testID="login-username"
              label={t('screens.auth.username.label')}
              placeholder={t('screens.auth.username.label')}
              errorMessage={errors?.username?.message as unknown as string}
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect={false}
              onChangeText={field.onChange}
              onKeyPress={submitOnEnter}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <Input
              {...field}
              testID="login-password"
              textContentType="password"
              label={t('screens.auth.password.label')}
              placeholder={t('screens.auth.password.label')}
              errorMessage={errors?.password?.message as unknown as string}
              autoCapitalize="none"
              autoCorrect={false}
              secureTextEntry
              onChangeText={field.onChange}
              onKeyPress={submitOnEnter}
            />
          )}
        />
        <Button
          disabled={!isValid}
          testID="login"
          loading={loading}
          title={t('screens.auth.loginButton.label')}
          onPress={handleLogin}
        />
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text>{t('screens.auth.register.label')}</Text>
        <View style={{ marginLeft: spacing.sm }}>
          <Text
            style={{
              fontFamily: fontFamily.bold,
            }}
          >
            <Link to={{ screen: 'register' }}>
              {t('screens.auth.register.link')}
            </Link>
          </Text>
        </View>
      </View>
    </View>
  );
}
