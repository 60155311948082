import { useCallback, useEffect } from 'react';

import { getUserProfile } from '../../screens/auth/api';
import { useAuth } from '../../screens/auth/components/context/useAuth';
import { globalState } from '../../state/globalState';
import { UserProfile } from '../../state/types';

export function useUserProfile(): UserProfile {
  const { isLoggedIn, clearToken } = useAuth();
  const userProfile = globalState.useSelector((state) => state.userProfile);

  const handleGetUserProfile = useCallback(async () => {
    const profile = await getUserProfile();

    // If we are unable to retrieve the profile, then either we aren't actually
    // logged in or the token is invalid.
    if (!profile) {
      await clearToken();
      return;
    }

    globalState.set((state) => ({
      ...state,
      userProfile: {
        id: profile.id,
        firstName: profile.first_name,
        lastName: profile.last_name,
        avatar: profile.avatar,
      },
    }));
  }, [userProfile]);

  useEffect(() => {
    if (isLoggedIn) {
      handleGetUserProfile();
    }
  }, [isLoggedIn]);

  return userProfile;
}
