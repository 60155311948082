import { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import { Text } from '@rneui/themed';

import { FadeInView } from '../../components/animations/FadeInView';
import { Breadcrumbs } from '../../components/subnavs/Breadcrumbs';
import { colors, fontFamily } from '../../components/theme/theme';
import { get } from '../../state/secureStore';
import { refreshCurrentTrip } from '../trip/api/refreshCurrentTrip';
import { tripState } from '../trip/state/tripState';
import { BookWizard, StepController } from './BookWizard';
import { bookWizardState } from './state/bookWizardState';

export function BookScreen() {
  const stepController = useRef<StepController>(null);
  const [loading, setLoading] = useState(true);
  const { breadcrumbs, breadcrumbPrice, showBreadcrumbs } =
    bookWizardState.useSelector((state) => ({
      breadcrumbs: state.breadcrumbs,
      breadcrumbPrice: state.breadcrumbPrice,
      showBreadcrumbs: state.showBreadcrumbs,
    }));

  const { currentTrip } = tripState.useValue();

  // Determine what step to move to
  const path = window?.location.pathname ?? '';
  const [_firstSlash, rootPath, pnr, step] = path.split('/');
  const shouldSkipToComplete = step === 'complete';

  const handleFetchCurrentTrip = useCallback(async () => {
    const [currentPnr, lastName] = await Promise.all([
      get('currentPnr'),
      get('lastname'),
    ]);

    if (rootPath === 'book' && pnr && pnr !== currentPnr) {
      // TODO redirect edge case
      console.log('Should redirect here');
      return;
    }

    if (!currentTrip && currentPnr && lastName) {
      try {
        await refreshCurrentTrip();
      } catch (e) {
        // No current trip, and no active trip... should redirect
        console.log('Should redirect', e);
      }
    }
    setLoading(false);
  }, [currentTrip, setLoading]);

  const handleRefreshCurrentTrip = useCallback(async () => {
    await refreshCurrentTrip();
  }, []);

  useEffect(() => {
    handleFetchCurrentTrip();
  }, [handleFetchCurrentTrip]);

  return (
    <View>
      {showBreadcrumbs ? (
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          rightComponent={
            breadcrumbPrice ? (
              <FadeInView>
                <Text
                  style={{
                    color: colors.llYellow,
                    fontFamily: fontFamily.semiBold600,
                  }}
                >
                  Your Price from {breadcrumbPrice}
                </Text>
              </FadeInView>
            ) : null
          }
          onPressCrumb={(_crumb, index) => {
            stepController.current?.setActiveStep(index);
          }}
        />
      ) : null}
      <View
        style={{
          paddingTop: 30,
          paddingHorizontal: 100,
        }}
      >
        <BookWizard
          stepControllerRef={stepController}
          stepIndex={shouldSkipToComplete ? 5 : 0}
          onNextStep={handleRefreshCurrentTrip}
        />
      </View>
    </View>
  );
}
