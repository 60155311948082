import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Image, Text } from '@rneui/themed';

import { colors, fontFamily } from '../../../components/theme/theme';

interface SummaryRowProps {
  title: string;
  time: string;
  passengerCount: number;
  price: string;
  from: string;
  to: string;
  border?: boolean;
}

export function SummaryRow({
  title,
  time,
  passengerCount,
  price,
  from,
  to,
  border,
}: SummaryRowProps) {
  const { t } = useTranslation();
  return (
    <View
      style={{
        flexDirection: 'row',
        borderBottomWidth: border ? 1 : 0,
        borderBottomColor: colors.primaryGray,
        marginBottom: 25,
        paddingBottom: 25,
        justifyContent: 'space-between',
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        <Image
          source={{ uri: require('../../../../assets/icons/black-car.svg') }}
          style={{ width: 53, height: 25, marginRight: 25 }}
          containerStyle={{
            alignSelf: 'center',
          }}
        />
        <View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>{title}</Text>
            <Text> | {time}</Text>
          </View>
          <View>
            <Text>
              {t('screens.book.bookingSummaryCard.passenger', {
                count: passengerCount,
              })}
            </Text>
            <Text>
              {from}
              {' → '}
              {to}
            </Text>
          </View>
        </View>
      </View>
      <Text style={{ fontFamily: fontFamily.bold }}>{price}</Text>
    </View>
  );
}
