import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';

import { Button } from '@rneui/themed';

import { FormRow } from '../../../components/forms/FormRow';
import { setPassengerDetails } from '../../trip/api/setPassengerDetails';
import { tripState } from '../../trip/state/tripState';
import { CardContainer } from '../components/CardContainer';
import { bookWizardState } from '../state/bookWizardState';
import { flightInfoCardState } from '../state/flightInfoCardState';
import { FlightInfoForm, FlightInfoFormFields } from './forms/FlightInfoForm';

interface FlightInfoCardProps {
  onPressNext: () => void;
}

export function FlightInfoCard({ onPressNext }: FlightInfoCardProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { currentTrip } = tripState.useValue();

  // Hydrate store state from trip
  useEffect(() => {
    if (!currentTrip) {
      return;
    }

    // For now we assume that the first passenger is the primary
    const primaryPassenger = currentTrip.passengers?.[0];

    // Find the first non-l4 airline leg, which should be the flight leg
    const airlineLeg = currentTrip.segments[0]?.legs?.find(
      (n) => n.airline.iata !== 'L4',
    );

    flightInfoCardState.set((state) => ({
      ...state,
      form: {
        title: primaryPassenger.title,
        firstName: primaryPassenger.first_name,
        lastName: primaryPassenger.last_name,
        email: primaryPassenger.email,
        numPassengers: `${currentTrip?.passengers?.length ?? 1}`,
        flight: airlineLeg?.flight_number,
        airport: `${airlineLeg?.depart?.iata} - ${airlineLeg?.depart?.AirportName}`,
        departureTime: format(
          parseISO(airlineLeg?.departure_time),
          'MM/dd/yyyy hh:mm a',
        ),
        arrivalTime: format(
          parseISO(airlineLeg?.arrival_time),
          'MM/dd/yyyy hh:mm a',
        ),
      },
    }));
  }, [currentTrip]);

  const storeState = flightInfoCardState.useSelector((state) => state.form);

  // Initialize form control using store values as default
  const formControl = useForm<FlightInfoFormFields>({
    defaultValues: {
      ...storeState,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // Hydrate form state from store (if its programmatically updated)
  useEffect(() => {
    Object.keys(storeState).forEach((field: keyof FlightInfoFormFields) => {
      const value = storeState[field];
      formControl.setValue(field, value, {
        shouldValidate: true,
      });
    });
  }, [storeState]);

  // Update store state after submit, proceed to next
  const updateStore = useCallback(
    async (fields: FlightInfoFormFields) => {
      flightInfoCardState.set((state) => ({
        ...state,
        form: {
          ...fields,
        },
      }));

      setLoading(true);

      try {
        await setPassengerDetails(currentTrip.id, {
          title: fields.title,
          first_name: fields.firstName,
          last_name: fields.lastName,
          email: fields.email,
        });
        setLoading(false);
        onPressNext();
      } catch (_e) {
        Toast.show({
          text1: 'Failed to update passenger details',
          type: 'error',
        });
      }
    },
    [currentTrip, flightInfoCardState, setLoading],
  );

  // Set initial crumb state
  useEffect(() => {
    bookWizardState.set((state) => ({
      ...state,
      showBreadcrumbs: false,
    }));
  }, []);

  return (
    <CardContainer>
      <FlightInfoForm formControl={formControl} />
      <FormRow
        style={{
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            width: 370,
          }}
          title={t('screens.book.welcomeCard.actions.submit')}
          onPress={formControl.handleSubmit(updateStore)}
          disabled={!formControl.formState.isValid}
          loading={loading}
        />
      </FormRow>
    </CardContainer>
  );
}
