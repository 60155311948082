import { forwardRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { Input, InputProps } from '@rneui/themed';

import { RequiredLabel } from './RequiredLabel';

export interface TextInputProps extends InputProps {
  required?: boolean;
  label: React.ReactNode | string;
}

export const TextInput = forwardRef<RNTextInput, TextInputProps>(
  (args, ref) => {
    const { required, label, inputContainerStyle, errorMessage, ...props } =
      args;
    return (
      <Input
        {...props}
        ref={ref}
        label={required ? <RequiredLabel title={label as string} /> : label}
        inputContainerStyle={[
          inputContainerStyle,
          errorMessage
            ? {
                borderColor: 'red',
              }
            : {},
        ]}
        errorMessage={errorMessage}
      />
    );
  },
);
