import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg viewBox="0 0 45 30" fill="none" {...props}>
    <Path
      d="M39.53 18.516c-.204.792-1.159.86-1.868.833-.123-2.006-1.665-3.588-3.547-3.588-1.965 0-3.561 1.719-3.561 3.847H16.748v-.11c0-2.128-1.596-3.847-3.56-3.847-1.829 0-3.33 1.488-3.534 3.411-.642 0-3.33-.068-3.916-.546 0 0-.409-.477-.327-2.387 0 0 .477-.096.532-.205.054-.109 0-2.114 0-2.114s.15-1.91.45-2.388c.3-.477 1.487-2.865 1.487-2.865s-.505-.463-.177-.545c.327-.082 8.144-.45 9.263-.505.587-.041 6.426.109 6.903.232.478.123 1.883 0 3.043.914 1.16.9 3.915 3.015 3.915 3.015s4.093.273 4.597.396c.505.122 3.547.504 3.697 1.377.11.846.737 3.82.41 5.075Z"
      fill="#3C3835"
    />
    <Path
      d="M13.296 22.5c1.635 0 2.96-1.43 2.96-3.192 0-1.763-1.325-3.193-2.96-3.193s-2.96 1.43-2.96 3.193c0 1.763 1.325 3.192 2.96 3.192ZM34.074 22.5c1.635 0 2.96-1.43 2.96-3.192 0-1.763-1.325-3.193-2.96-3.193s-2.96 1.43-2.96 3.193c0 1.763 1.325 3.192 2.96 3.192Z"
      fill="#3C3835"
    />
    <Path
      d="M29.735 11.927H22.75l-.163-3.41c.013 0 .354 0 1.691.109 1.378.122 1.747.368 1.747.368s3.083 2.114 3.451 2.551c.123.136.205.26.26.382ZM21.768 11.927h-6.685c.137-.314 1.351-3.097 1.515-3.397l5.157-.027.013 3.424ZM15.588 8.53l-1.405 3.329-.014.027-3.097-.027-3.956-.055 1.474-3.11 6.998-.164ZM17.976 14.833h.245v.969h.56v.218h-.805v-1.187ZM19.558 15.529h.287l-.15-.396-.137.396Zm.26-.696.45 1.187h-.246l-.11-.287h-.45l-.109.287h-.245l.45-1.187h.26ZM21.659 16.02h-.191l-.56-.791v.79h-.245v-1.186h.232l.532.75v-.75h.232v1.187ZM22.546 15.802c.232 0 .355-.15.355-.369 0-.232-.123-.382-.355-.382h-.164v.75h.164Zm-.41-.97h.424c.354 0 .586.233.586.587 0 .355-.232.587-.587.587h-.422v-1.173ZM23.569 14.833h.245v.969h.546v.218h-.791v-1.187Z"
      fill="#fff"
    />
    <Path
      d="M25.042 14.533h-.245v1.773h.245v-1.773ZM25.452 14.533h-.246v1.773h.246v-1.773Z"
      fill="#FCB415"
    />
    <Path
      d="M26.925 16.02h-.191l-.56-.791v.79h-.245v-1.186h.232l.532.75v-.75h.232v1.187ZM28.207 14.833v.218h-.559v.26h.505v.204h-.505v.287h.56v.218h-.805v-1.187h.805Z"
      fill="#fff"
    />
  </Svg>
);

export default SvgComponent;
