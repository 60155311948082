import * as Device from 'expo-device';
import React, { useMemo } from 'react';
import { View, ViewStyle } from 'react-native';

import { LegAddress } from '../../screens/trip/api/types';

const GoogleMapWeb = React.lazy(() => import('./GoogleMapWeb'));
const GoogleMapNative = React.lazy(() => import('./GoogleMapNative'));

interface GoogleMapProps {
  pickUpAddress: LegAddress;
  dropOffAddress: LegAddress;
  containerProps?: {
    style: ViewStyle;
  };
}

export function GoogleMap(props: GoogleMapProps) {
  const MapComponent = !Device.deviceName ? GoogleMapWeb : GoogleMapNative;

  const pickUpCoords = {
    lng: parseFloat(props.pickUpAddress.longitude),
    lat: parseFloat(props.pickUpAddress.latitude),
  };

  const dropOffCoords = {
    lng: parseFloat(props.dropOffAddress.longitude),
    lat: parseFloat(props.dropOffAddress.latitude),
  };

  const samePickUpAndDropOff = useMemo(() => {
    return (
      pickUpCoords.lat === dropOffCoords.lat &&
      pickUpCoords.lng === dropOffCoords.lng
    );
  }, [pickUpCoords, dropOffCoords]);

  return (
    <React.Suspense fallback={<View />}>
      <MapComponent
        {...props}
        pickUpCoords={pickUpCoords}
        dropOffCoords={dropOffCoords}
        samePickUpAndDropOff={samePickUpAndDropOff}
      />
    </React.Suspense>
  );
}
