export const en = {
  welcomeCard: {
    title: 'Welcome to the Best Part of Your Travel Day',
    fields: {
      title: 'Title',
      firstName: 'First Name',
      lastName: 'Last Name',
      airport: 'Airport',
      flight: 'Flight #',
      numPassengers: 'Number of Passengers',
      departureTime: 'Departure Time',
      arrivalTime: 'Arrival Time',
      email: 'Email Address',
      emailValidation: 'Entered value does not match email format',
    },
    actions: {
      submit: 'Confirm Flight and Continue',
    },
  },
  locationCard: {
    title: 'Enter your Pick-up Location',
    dropoffTitle: 'Enter your Drop-off Location',
    pricingTitle: 'We’ve found great pricing for your trip!',
    pricingDescription:
      'Enjoy Door-to-Door service from this location and back for up to five passengers.',
    roundTripTitle: 'Roundtrip',
    oneWayTitle: 'One way',
    fields: {
      streetAddress1: 'Street address',
      streetAddress2: 'Street address 2',
      city: 'City',
      zipcode: 'Zipcode',
      state: 'State',
      tripDirectionTitle:
        'Select roundtrip, one way, or add a new drop off location',
      roundTrip: 'Roundtrip pick up & drop off from this location',
      oneWay: 'One way only from this location',
      dropoff: 'Enter a drop-off at a new location',
      pickupOnly: 'Book pick up only',
      dropoffOnly: 'Book drop off only',
    },
    actions: {
      submit: 'Select Time',
      submitExisting: 'Use Current Quote',
      getQuote: 'View Pricing',
    },
  },
  timeCard: {
    title: 'Pick-up Time',
    dropoffTitle: 'Drop-off Time',
    basedOnTime: 'Based on your flight time of ',
    weRecommend: ', we recommend a pick-up time of ',
    changesAllowed: ', but you can make changes below.',
    fields: {
      bags: 'Are you checking bags?',
      time: 'Time',
    },
    actions: {
      revealDropoff: 'Select Drop-off time',
      submit: 'Continue',
    },
  },
  passengerInfoCard: {
    title: 'Your info',
    saveTimeTitle: 'Save time the next time you travel',
    fields: {
      title: 'Title',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      phone: 'Phone',
      phoneInfo:
        'Used only to contact you in case of delays or itinerary changes',
      passwordInfo:
        'Create a password to access your bookings and save your payment method.',
      password: 'Create Password',
    },
    actions: {
      submit: 'Continue to Payment',
    },
  },
  bookingSummaryCard: {
    title: 'Your Booking Summary',
    departureTitle: 'Departure',
    returnTitle: 'Return',
    passenger_one: '1 Passenger',
    passenger_other: '{{count}} Passengers',
    paymentInfoTitle: 'Payment Info',
    subtotal: 'Subtotal',
    total: 'Total',
    paymentLegal:
      'Your email address will be used to send you the booking confirmation, boarding passes, and notifications, by making this purchase you agree to the Terms & Conditions of Carriage and Landline.com Terms of Use',
    promotionalEmailConfirmation:
      'I would like to receive promotional emails and tailored travel offers from Landline.com',
    actions: {
      showPaymentCapture: 'Reserve Now',
      addPaymentMethod: 'Add Payment Method',
      submit: 'Complete Payment',
    },
  },
  bookingConfirmedCard: {
    title: 'Your Booking is Confirmed',
  },
};
