import { DateTime } from 'luxon';

import { api } from '../../../api';
import { get } from '../../../state/secureStore';

type CardVendor = 'visa' | 'mastercard' | 'amex' | 'discover';

export type PaymentMethod = {
  id: number;
  number: string;
  vendor: CardVendor;
  expiration: string;
  isExpired: boolean;
};

type PaymentMethodResponse = {
  id: number;
  brand: CardVendor;
  exp_month: number;
  exp_year: number;
  last4: string;
  stripe_id: string;
};

export async function getPaymentMethods(): Promise<Array<PaymentMethod>> {
  try {
    const accessToken = await get('accessToken');
    // Don't attempt to fetch payment methods for guest accounts
    if (!accessToken) {
      return null;
    }

    const { data } = await api.get<Array<PaymentMethodResponse>>(
      `door_to_door_payments/payment_methods/`,
    );

    const now = DateTime.now();

    return data.map(({ id, brand, exp_month, exp_year, last4 }, i) => {
      const expiration = DateTime.now().set({
        year: exp_year,
        month: exp_month,
      });

      const month = exp_month < 10 ? `0${exp_month}` : exp_month;

      return {
        id,
        vendor: brand,
        number: last4,
        expiration: `${month}/${exp_year}`,
        isExpired: expiration < now,
      };
    });
  } catch (e) {
    return null;
  }
}
