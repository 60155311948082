export type RouteType =
  | 'roundTrip'
  | 'roundTripDropoffAlternate'
  | 'pickupOnly'
  | 'dropoffOnly';

export function getTripPrice(
  routeType: RouteType,
  departPrice: number,
  returnPrice: number,
) {
  switch (routeType) {
    case 'roundTrip':
      return departPrice * 2;
    case 'roundTripDropoffAlternate':
      return departPrice + returnPrice;
    case 'pickupOnly':
      return departPrice;
    case 'dropoffOnly':
      // Uses pickup form with the drop off title
      return departPrice;
  }
}
