import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Button, useTheme } from '@rneui/themed';

import { spacing } from '../../../../../components/theme/theme';
import { CardLayout } from './CardLayout';

interface BaggageCardProps {
  onPressNext: (hasBaggage: boolean) => void;
}

export function BaggageCard({ onPressNext }: BaggageCardProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <CardLayout
      icon="briefcase"
      title={t('screens.tripDetail.tripWizard.baggage.title')}
      testID="trip-wizard-baggage-card"
      showBackButton={false}
      showNextButton={false}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <Button
          containerStyle={{ marginRight: spacing.xxl, flex: 1 }}
          buttonStyle={{
            backgroundColor: theme.colors.grey5,
          }}
          onPress={() => onPressNext(false)}
          title={t('global.common.no')}
        />
        <Button
          containerStyle={{
            flex: 1,
          }}
          buttonStyle={{
            backgroundColor: theme.colors.grey5,
          }}
          onPress={() => onPressNext(true)}
          title={t('global.common.yes')}
        />
      </View>
    </CardLayout>
  );
}
