import { api } from '../../../api';
import { Trip } from './types';

export async function updateSegmentBaggage(
  segmentId: number,
  hasBaggage: boolean,
): Promise<Trip> {
  try {
    const { data } = await api.get(
      `v2/booking/segments/${segmentId}/${hasBaggage.toString()}/`,
    );
    return data;
  } catch (e) {
    return null;
  }
}
