import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg viewBox="0 0 52 30" fill="none" {...props}>
    <Path
      d="M36.538 18.113c-1.213 0-2.2.988-2.2 2.2 0 1.213.987 2.188 2.2 2.188 1.212 0 2.2-.988 2.2-2.2a2.193 2.193 0 0 0-2.2-2.188Z"
      fill="#3C3835"
    />
    <Path
      d="M41.425 8.45a1.646 1.646 0 0 0-1.5-.95H8.738S8 7.289 8 14.814v3.238a1.38 1.38 0 0 0 1.137 1.35l3.25.537h2.95c.188-1.437 1.413-2.562 2.9-2.562a2.93 2.93 0 0 1 2.9 2.562h12.488a2.93 2.93 0 0 1 2.9-2.562 2.93 2.93 0 0 1 2.9 2.562h2.863c.45 0 .825-.362.825-.825v-3.562c.012 0-.263-4.05-1.688-7.1Z"
      fill="#3C3835"
    />
    <Path
      d="M18.238 18.113c-1.213 0-2.2.988-2.2 2.2 0 1.213.987 2.188 2.2 2.188 1.212 0 2.2-.988 2.2-2.2a2.193 2.193 0 0 0-2.2-2.188Z"
      fill="#3C3835"
    />
    <Path
      d="M11.625 14.826h.325v1.3h.75v.287h-1.075v-1.587ZM13.738 15.75h.387l-.188-.524-.2.525Zm.362-.924.613 1.587h-.338l-.137-.375h-.6l-.138.375h-.338l.613-1.587h.325ZM16.575 16.413h-.262l-.75-1.062v1.062h-.325v-1.587h.312l.7 1v-1h.325v1.587ZM17.762 16.126c.3 0 .476-.2.476-.5 0-.313-.163-.5-.476-.5h-.212v1h.212Zm-.55-1.3h.563c.488 0 .788.312.788.8 0 .487-.3.787-.788.787h-.563v-1.587ZM19.125 14.826h.337v1.3h.738v.287h-1.075v-1.587Z"
      fill="#fff"
    />
    <Path
      d="M21.113 14.425h-.338v2.388h.338v-2.387ZM21.675 14.425h-.338v2.388h.338v-2.387Z"
      fill="#FAB500"
    />
    <Path
      d="M23.65 16.413h-.262l-.75-1.062v1.062h-.326v-1.587h.313l.7 1v-1h.325v1.587ZM25.363 14.826v.287h-.75v.35h.687v.275h-.688v.388h.75v.287h-1.074v-1.587h1.075Z"
      fill="#fff"
    />
    <Path
      d="M16.05 12.788a.56.56 0 0 1-.563.563h-5.462a.56.56 0 0 1-.562-.563V9.526a.56.56 0 0 1 .562-.563h5.463a.56.56 0 0 1 .562.563v3.262ZM25.563 12.788a.56.56 0 0 1-.563.563h-7.65a.56.56 0 0 1-.563-.563V9.526a.56.56 0 0 1 .563-.563H25a.56.56 0 0 1 .563.563v3.262ZM35.075 12.788a.56.56 0 0 1-.563.563h-7.65a.56.56 0 0 1-.562-.563V9.526a.56.56 0 0 1 .563-.563h7.65a.56.56 0 0 1 .562.563v3.262ZM40.2 12.788a.56.56 0 0 1-.563.563h-3.262a.56.56 0 0 1-.563-.563V9.526a.56.56 0 0 1 .563-.563h3.263a.56.56 0 0 1 .562.563v3.262ZM40.925 13.35V8.963c.638.15 1.463 5.125 1.463 5.125l-1.463-.737Z"
      fill="#CDCCC8"
    />
  </Svg>
);

export default SvgComponent;
