import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, View } from 'react-native';

import { Card, Text, useTheme } from '@rneui/themed';

import { IconButton } from '../../../../../components/button/IconButton';
import { colors, sizing, spacing } from '../../../../../components/theme/theme';
import { PaymentMethod } from '../../../api/getPaymentMethods';
import { CardLayout } from './CardLayout';
import { VendorCardImage } from './vendorImages/VendorCardImage';

interface ExistingPaymentMethodCardProps {
  initialSelectedMethodId?: number;
  paymentMethods: Array<PaymentMethod>;
  onPressBack?: () => void;
  onPressNext: (method: PaymentMethod) => void;
  onRemoveMethod: (method: PaymentMethod) => void;
}

function getCardNumberWithPlaceholders(method: PaymentMethod) {
  // Amex only has 15 numbers, which we could do work to represent if we wanted in
  // the future
  const placeholderLength = 12;
  let cardNumber = '';
  for (let i = 0; i < placeholderLength; i++) {
    cardNumber += '*';
  }

  return cardNumber + method.number;
}

export function ExistingPaymentMethodCard({
  initialSelectedMethodId,
  paymentMethods,
  onPressNext,
  onPressBack,
  onRemoveMethod,
}: ExistingPaymentMethodCardProps) {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState<number>();
  const { theme } = useTheme();

  const handleConfirm = useCallback(() => {
    const selectedMethod = paymentMethods.find(
      (method) => method.id === selectedId,
    );
    onPressNext(selectedMethod);
  }, [selectedId]);

  const handleSelectPaymentMethod = useCallback(
    (id: number) => {
      setSelectedId(id);
    },
    [setSelectedId],
  );

  useEffect(() => {
    setSelectedId(initialSelectedMethodId || paymentMethods[0].id);
  }, [setSelectedId]);

  const selectedMethodExists = useMemo(
    () => paymentMethods.find((method) => method.id === selectedId),
    [selectedId, paymentMethods],
  );

  return (
    <CardLayout
      icon="credit-card"
      testID="trip-wizard-payment-card"
      title={t('screens.tripDetail.tripWizard.existingPaymentMethod.title')}
      subTitle={
        <Text
          style={{
            color: colors.secondaryText,
          }}
        >
          {t('screens.tripDetail.tripWizard.existingPaymentMethod.subtitle')}
        </Text>
      }
      onPressNext={handleConfirm}
      onPressBack={onPressBack}
      nextButtonTitle={t('global.common.confirm')}
      nextButtonDisabled={!selectedMethodExists}
    >
      <ScrollView style={{ paddingBottom: spacing.md }}>
        {paymentMethods.map((method, i) => {
          const isSelected = method.id === selectedId;
          const cardBackground = isSelected
            ? colors.selected
            : method.isExpired
            ? theme.colors.disabled
            : null;
          return (
            <View
              key={`payment-method-item-${i}`}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Pressable
                style={{
                  flex: 1,
                }}
                disabled={method.isExpired}
                onPress={() => handleSelectPaymentMethod(method.id)}
              >
                <Card
                  containerStyle={{
                    backgroundColor: cardBackground,
                    borderColor: isSelected
                      ? colors.primaryHover
                      : theme.colors.grey5,
                  }}
                >
                  <View
                    style={{
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <View>
                      <VendorCardImage paymentMethod={method} />
                    </View>
                    <View>
                      <Text
                        style={{
                          fontSize: sizing.lg,
                        }}
                      >
                        {getCardNumberWithPlaceholders(method)}
                      </Text>
                      <View style={{ alignItems: 'flex-end' }}>
                        {method.isExpired ? (
                          <Text
                            style={{
                              color: theme.colors.error,
                            }}
                          >
                            {t('global.common.expired')}: {method.expiration}
                          </Text>
                        ) : (
                          <Text>
                            {t('global.common.expires')}: {method.expiration}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                </Card>
              </Pressable>
              <IconButton
                icon="remove"
                color={colors.secondaryText}
                onPress={() => onRemoveMethod(method)}
              />
            </View>
          );
        })}
      </ScrollView>
    </CardLayout>
  );
}
