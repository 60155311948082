import { api } from '../../../api';

interface MarketingImage {
  image: string;
  color: string;
  redirect_url: string;
}

interface MarketingImagesResult extends Array<MarketingImage> {}

export async function getMarketingImages(): Promise<MarketingImagesResult> {
  try {
    const { data } = await api.get('v2/web/carousel_images/');

    return data;
  } catch (e) {}

  return null;
}
