export enum CURRENCY {
  USD = 'USD',
  CAN = 'CAN',
}

export enum PASSENGER_TYPE {
  AD = 'AD',
}

export enum PASSENGER_GENDER {
  MALE = 1,
  FEMALE = 2,
  NON_BINARY = 3,
}

export enum PASSENGER_GENDER_LABEL {
  MALE = 'Male',
  FEMALE = 'Female',
  NON_BINARY = 'Non-Binary',
}

export type PassengerPNR = {
  pnr: string;
  // TODO define
  data: any;
  leg: number;
};

export type Passenger = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  passenger_type: PASSENGER_TYPE;
  title: string;
  date_of_birth: Date;
  gender: Array<PASSENGER_GENDER | PASSENGER_GENDER_LABEL>;
  // TODO define
  data: any;
  // seats: [];
  pnrs: Array<PassengerPNR>;
};

export type AddressDirection = 'pickup_address' | 'drop_off_address';
export type AirportDirection = 'pickup_airport' | 'drop_off_airport';

export enum CLASSBAND {
  STANDARD = 'Standard',
}

export enum CHECKIN_STATUS {
  NOT_READY = 1,
  READY = 2,
  PASSED = 3,
}

export type LegLocation = {
  ArrivalInstructions: string;
  city: string;
  iata: string;
  AirportName: string;
  time_zone: string;
  operator: string;
  virtual: boolean;
};

export const HOME_SERVICE_VIRTUAL_AIRPORT_IATA = 'MSX';

export type Airline = {
  iata: string;
  name: string;
  check_in_url: string;
};

export enum LEG_SERVICE_TYPE {
  AIRPLANE = 1,
  BUS = 2,
  SELECT = 3,
  EXPRESS = 4,
  EXPRESS_HOME_SERVICE = 5,
}

export enum LEG_STATUS {
  UNASSIGNED = 1,
  SUGGESTED = 2,
  PENDING = 3,
  APPROVED = 4,
  CANCELLED = 5,
  NEW = 6,
}

export type LegAddress = {
  id: number;
  created: Date;
  modified: Date;
  city: string;
  first_address_line: string;
  second_address_line: string;
  zip_code: string;
  state: string;
  // TODO LatLng types?
  latitude: string;
  longitude: string;
};

export type Driver = {
  first_name: string;
  last_name: string;
  avatar: string;
  phone_number: string;
};

export type Vehicle = {
  vehicle_image: string;
  vehicle_model: string;
  production_year: string;
  registration: string;
  vehicle_color: string;
};

export type PickUpDetails = {
  id: number;
  drop_off_address: LegAddress;
  pick_up_address: LegAddress;
  leg: {
    id: number;
    flight_number: string;
  };
  allowed_radius: number;
  travel_time: string;
  offset: number;
  offset_details: {
    from: string /*ISO Date*/;
    to: string /*ISO Date*/;
    increments: number;
  };
  created: Date;
  modified: Date;
  airline: string;
  flight_number: string;
  // TODO define
  // origin: null;
  // destination: null;
  // departure_datetime: null;
  // arrival_datetime: null;
};

export type LegNote = {
  created: string;
  deleted: boolean;
  description: string;
  id: number;
};

export type LegQuote = {
  id: number;
  distance_rounded: number;
  total_price_rounded: number;
};

export type Leg = {
  id: number;
  ticket: number;
  depart: LegLocation;
  arrive: LegLocation;
  departure_time: string;
  arrival_time: string;
  airline: Airline;
  flight_number: string;
  service_type: LEG_SERVICE_TYPE;
  driver: Driver;
  vehicle: Vehicle;
  status: LEG_STATUS;
  company: string;
  duration: string;
  is_editable: boolean;
  operator: string;
  pick_up_details: PickUpDetails;
  notes: Array<LegNote>;
  quote: LegQuote;
};

export type Segment = {
  id: number;
  checkin_status: CHECKIN_STATUS;
  all_checked_in: boolean;
  layover_times: Array<string>;
  legs: Array<Leg>;
  way: number;
  total_trip_time: string;
  checked_baggage: number;
};

export enum TICKET_STATE {
  PENDING = 1,
  CANCELLED = 2,
  PAID = 3,
  FAILED = 4,
}

export enum RESERVATION_TYPE {
  REGULAR = 1,
  LOYALTY = 20,
}

export type Ticket = {
  id: number;
  // TODO define
  // payment_summary: [];
  created: Date;
  modified: Date;
  booking_reference: string;
  state: TICKET_STATE;
  // TODO define
  data: any;
  operator: string;
  // TODO define
  reservation: number;
};

export type Trip = {
  id: number;
  rloc: string;
  // voucher: null;
  // tab_session: null;
  final_price: number;
  currency: CURRENCY;
  // payment_info: null;
  // user: null;
  // flight_report: null;
  // report: {};
  is_paid: boolean;
  passenger_count: number;
  one_way: boolean;
  type: number;
  classband: CLASSBAND;
  number_of_wheelchair_seats: number;
  is_paid_with_miles: boolean;
  created: Date;
  modified: Date;
  passengers: Array<Passenger>;
  segments: Array<Segment>;
  tickets: Array<Ticket>;
  is_editable: boolean;
  is_past_trip: boolean;
  // TODO define
  // cart: null;
  pick_up_details: Array<{
    id: number;
    leg__flight_number: string;
  }>;
  uuid: string;
  payment_method?: number;
};

export enum SEGMENT_TYPE {
  DEPARTURE = 0,
  RETURN = 1,
}

export type PaginatedResult<TItem extends unknown> = {
  count: number;
  previous: string;
  next: string;
  results: Array<TItem>;
};
