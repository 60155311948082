import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import { Text } from '@rneui/themed';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import { colors, spacing } from '../../../../../components/theme/theme';
import { CheckoutForm } from '../forms/CheckoutForm';
import { CardLayout } from './CardLayout';

interface CreatePaymentMethodCardProps {
  onPressBack?: () => void;
  onPressNext: (elements: StripeElements, stripe: Stripe) => void;
}

export function CreatePaymentMethodCard({
  onPressNext,
  onPressBack,
}: CreatePaymentMethodCardProps) {
  const { t } = useTranslation();
  const [canSubmit, setCanSubmit] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  const handleConfirm = useCallback(() => {
    onPressNext(elements, stripe);
  }, [elements, stripe]);

  return (
    <CardLayout
      icon="credit-card"
      testID="trip-wizard-payment-card"
      title={t('screens.tripDetail.tripWizard.payment.title')}
      subTitle={
        <Text
          style={{
            color: colors.secondaryText,
          }}
        >
          {t('screens.tripDetail.tripWizard.payment.subtitle')}
        </Text>
      }
      onPressNext={handleConfirm}
      onPressBack={onPressBack}
      nextButtonDisabled={!canSubmit}
      nextButtonTitle={t('global.common.confirm')}
    >
      <ScrollView style={{ marginTop: spacing.md }}>
        <CheckoutForm
          onInvalid={() => {
            setCanSubmit(false);
          }}
          onValid={() => {
            setCanSubmit(true);
          }}
        />
      </ScrollView>
    </CardLayout>
  );
}
