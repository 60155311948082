import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Toast from 'react-native-toast-message';

import { NavigationContainer } from '@react-navigation/native';
import { ThemeProvider } from '@rneui/themed';

import { ConfirmationContextProvider } from './src/components/contexts/ConfirmationProvider';
import { Footer } from './src/components/footers/Footer';
import { FontProvider } from './src/components/theme/FontProvider';
import {
  colors,
  landlineTheme,
  useMaxViewWidth,
} from './src/components/theme/theme';
import { i18n } from './src/i18n';
import { MainRoute } from './src/routes/Main';
import { AuthContextProvider } from './src/screens/auth/components/context/AuthProvider';
import { globalState } from './src/state/globalState';

export default function App() {
  const maxWidth = useMaxViewWidth();
  const showFooter = globalState.useSelector((state) => state.showFooter);

  return (
    <ThemeProvider theme={landlineTheme}>
      <NavigationContainer
        linking={{
          // TODO deep linking support here
          prefixes: [],
          config: {
            screens: {
              reservation: 'reservation/:hash',
              ['trip-details']: 'trip-details/:pnr',
              login: 'login/:return',
              register: 'register/:return',
            },
          },
        }}
      >
        <View
          style={{
            backgroundColor: colors.pageBackground,
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <SafeAreaProvider
            style={{
              width: maxWidth,
              alignSelf: 'center',
              backgroundColor: colors.screenBackground,
            }}
          >
            <FontProvider>
              <I18nextProvider i18n={i18n}>
                <ConfirmationContextProvider>
                  <AuthContextProvider>
                    <MainRoute />
                  </AuthContextProvider>
                  {showFooter ? <Footer /> : null}
                </ConfirmationContextProvider>
              </I18nextProvider>
            </FontProvider>
          </SafeAreaProvider>
        </View>
      </NavigationContainer>
      <Toast />
    </ThemeProvider>
  );
}
