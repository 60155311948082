import { View, ViewStyle } from 'react-native';

interface FormRowProps {
  children: JSX.Element | Array<JSX.Element>;
  style?: ViewStyle;
}

export function FormRow({ children, style }: FormRowProps) {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 12,
        ...style,
      }}
    >
      {children}
    </View>
  );
}
