import { useContext } from 'react';

import { AuthContext, AuthContextState } from './AuthProvider';

export const useAuth = function (): AuthContextState {
  const current = useContext(AuthContext);

  return {
    isLoggedIn: !!current.token?.access,
    token: current.token,
    setToken: current.setToken,
    clearToken: current.clearToken,
  };
};
