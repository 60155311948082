import * as Device from 'expo-device';
import * as SecureStore from 'expo-secure-store';

/**
 * SecureStore is a long term storage mechanism which persists data using a
 * native compatible medium. Generally this hook relies on `SecureStore` from
 * expo when available, and `localStorage` when not.
 */
export async function set(key: string, value: string) {
  try {
    if (!Device.deviceName) {
      localStorage.setItem(key, value);
    } else {
      await SecureStore.setItemAsync(key, value);
    }
    return true;
  } catch (e) {
    return false;
  }
}

export async function get(key: string): Promise<string> {
  try {
    if (!Device.deviceName) {
      return localStorage.getItem(key);
    }
    return await SecureStore.getItemAsync(key);
  } catch (e) {
    return null;
  }
}

export async function deleteItem(key: string): Promise<void> {
  try {
    if (!Device.deviceName) {
      return localStorage.removeItem(key);
    }
    return await SecureStore.deleteItemAsync(key);
  } catch (e) {
    return;
  }
}
