import { api } from '../../../api';

export async function updateLegQuote(
  legID: number,
  quoteID: number,
): Promise<boolean> {
  const data = {
    leg: legID,
    quote: quoteID,
  };

  try {
    await api.post(`/reservations/update_leg_quote/`, data);
    return true;
  } catch (e) {
    return false;
  }
}
