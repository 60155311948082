import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { Text, useTheme } from '@rneui/themed';

import { sizing, spacing } from '../../../../../components/theme/theme';

export enum LEG_FLIGHT_STATUS {
  ON_TIME = 1,
  DELAYED = 2,
  CANCELLED = 3,
  MISSING_ADDRESS = 4,
  ADDRESS_OUTSIDE_OF_RANGE = 5,
}

interface LegStatusProps {
  status: LEG_FLIGHT_STATUS;
}

export function LegStatus({ status }: LegStatusProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  let icon;
  let label;
  let color;

  switch (status) {
    // Disabling statuses that we're unable to determine
    // case LEG_FLIGHT_STATUS.ON_TIME:
    //   icon = 'check-circle';
    //   label = 'onTime';
    //   color = 'green';
    //   break;
    // case LEG_FLIGHT_STATUS.DELAYED:
    //   icon = 'warning';
    //   label = 'delayed';
    //   color = theme.colors.warning;
    //   break;
    // case LEG_FLIGHT_STATUS.CANCELLED:
    //   icon = 'times-circle';
    //   label = 'cancelled';
    //   color = theme.colors.error;
    //   break;
    case LEG_FLIGHT_STATUS.MISSING_ADDRESS:
      icon = 'warning';
      label = 'missingAddress';
      color = theme.colors.error;
      break;
    case LEG_FLIGHT_STATUS.ADDRESS_OUTSIDE_OF_RANGE:
      icon = 'warning';
      label = 'addressOutOfRange';
      color = theme.colors.error;
      break;
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <Text style={{ marginRight: spacing.sm }}>
        {label && t(`screens.tripDetail.legCard.status.${label}`)}
      </Text>
      <Icon name={icon} color={color} size={sizing.lg} />
    </View>
  );
}
