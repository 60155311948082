import * as parseAddress from 'parse-google-autocomplete';
import { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, ViewStyle } from 'react-native';

import { Input, Text } from '@rneui/themed';

import { FormRow } from '../../../../components/forms/FormRow';
import { Select } from '../../../../components/forms/Select';
import { TextInput } from '../../../../components/forms/TextInput';
import {
  AddressAutocomplete,
  ParsedAddress,
} from '../../../trip/components/tripWizard/cards/autocompletes/AddressAutocomplete';
import { getStates } from '../../api/getStates';
import { locationCardState } from '../../state/locationCardState';

export type LocationFormFields = {
  predictionAddress?: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  zipcode?: string;
  state: string;
};

interface LocationFormProps {
  type: 'pickup' | 'dropoff';
  formControl: UseFormReturn<LocationFormFields>;
  containerStyle?: ViewStyle;
}

export function LocationForm({
  type,
  formControl,
  containerStyle,
}: LocationFormProps) {
  const { t } = useTranslation();

  const {
    control,
    formState: { isValid, errors },
    setValue,
    getValues,
  } = formControl;

  const values = useMemo(() => getValues(), [getValues]);

  useEffect(() => {
    locationCardState.set((state) => {
      return {
        ...state,
        form: {
          ...state.form,
          [type === 'pickup' ? 'pickupLocation' : 'dropoffLocation']: {
            isValid,
            ...values,
          },
        },
      };
    });
  }, [values]);

  return (
    <View style={{ ...containerStyle }}>
      <Text h1>
        {type === 'dropoff'
          ? t('screens.book.locationCard.dropoffTitle')
          : t('screens.book.locationCard.title')}
      </Text>

      <FormRow
        style={{
          marginTop: 0,
          zIndex: 10,
        }}
      >
        <Controller
          name="streetAddress1"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => {
            return (
              <AddressAutocomplete
                iata="MSX"
                inputValue={field.value}
                label={t('screens.book.locationCard.fields.streetAddress1')}
                required
                onSelectAddress={(prediction) => {
                  if (prediction) {
                    const [parsedAddress]: Array<ParsedAddress> = parseAddress([
                      prediction,
                    ]);
                    setValue('predictionAddress', prediction?.description, {
                      shouldValidate: true,
                    });
                    setValue('streetAddress1', parsedAddress.street, {
                      shouldValidate: true,
                    });
                    setValue('city', parsedAddress.city, {
                      shouldValidate: true,
                    });
                    setValue('state', parsedAddress.state, {
                      shouldValidate: true,
                    });
                    // No street address2, or zip provied by api
                  }
                }}
              />
            );
          }}
        />
      </FormRow>
      <FormRow>
        <Input label={t('screens.book.locationCard.fields.streetAddress2')} />
      </FormRow>
      <FormRow>
        <Controller
          name="city"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          defaultValue=""
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.locationCard.fields.city')}
              required
              errorMessage={errors?.city?.message as unknown as string}
            />
          )}
        />
      </FormRow>
      <FormRow style={{ zIndex: 1, justifyContent: 'flex-start' }}>
        {/* disabling until we are providing zipcode from api <Controller
          name="zipcode"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          defaultValue=""
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.locationCard.fields.zipcode')}
              required
              errorMessage={errors?.zipcode?.message as unknown as string}
              containerStyle={{
                width: 100,
                marginRight: 20,
              }}
              inputStyle={{
                width: 98,
              }}
            />
          )}
        /> */}
        <Controller
          name="state"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          defaultValue=""
          render={({ field }) => (
            <Select
              label={t('screens.book.locationCard.fields.state')}
              data={getStates()}
              width={400}
              required
              initialValue={getStates().find((st) => st.value === field.value)}
              errorMessage={errors?.state?.message as unknown as string}
              onSelectItem={(state) => {
                field.onChange(state);
              }}
            />
          )}
        />
      </FormRow>
    </View>
  );
}
