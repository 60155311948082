import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Button, Overlay, Text } from '@rneui/themed';

import { SecondaryButton } from '../button/SecondaryButton';
import { colors, fontFamily } from '../theme/theme';

type ConfirmConfiguration = {
  title: string;
  yesTitle?: string;
  noTitle?: string;
};

type OpenState = {
  open: boolean;
};

type Resolver = () => void;

export interface ConfirmationState {
  confirm: (props: ConfirmConfiguration) => Promise<void>;
}

export const ConfirmationContext = React.createContext<ConfirmationState>({
  confirm: () => Promise.resolve(),
});

export function ConfirmationContextProvider({ children }) {
  const { t } = useTranslation();

  const [confirmState, setConfirmState] = useState<
    ConfirmConfiguration & OpenState
  >({
    title: '',
    yesTitle: t('global.common.yes'),
    noTitle: t('global.common.no'),
    open: false,
  });
  const [confirmResolver, confirmRejector] = [
    useRef<Resolver>(),
    useRef<Resolver>(),
  ];

  const showConfirm = useCallback(
    (props: ConfirmConfiguration): Promise<void> => {
      setConfirmState({
        ...props,
        yesTitle: props.yesTitle ?? t('global.common.yes'),
        noTitle: props.noTitle ?? t('global.common.no'),
        open: true,
      });

      return new Promise((resolve, reject) => {
        // ...
        confirmResolver.current = resolve;
        confirmRejector.current = reject;
      });
    },
    [confirmState, setConfirmState, confirmResolver, confirmRejector],
  );

  const resolveConfirm = useCallback(
    (value: boolean) => {
      setConfirmState({
        ...confirmState,
        open: false,
      });

      if (value) {
        confirmResolver.current();
      } else {
        confirmRejector.current();
      }
    },
    [confirmState, setConfirmState, confirmResolver, confirmRejector],
  );

  return (
    <ConfirmationContext.Provider
      value={{
        confirm: showConfirm,
      }}
    >
      {children}
      <Overlay isVisible={confirmState.open}>
        <View
          style={{
            width: 300,
            height: 200,
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{
              fontFamily: fontFamily.semiBold600,
              fontSize: 18,
              textAlign: 'center',
              marginTop: 50,
            }}
          >
            {confirmState.title}
          </Text>
          <View
            style={{
              alignSelf: 'center',
              flexDirection: 'row',
              marginBottom: 50,
            }}
          >
            <Button
              title={confirmState.yesTitle}
              buttonStyle={{ width: 90 }}
              containerStyle={{ marginRight: 25 }}
              onPress={() => resolveConfirm(true)}
            />
            <SecondaryButton
              title={confirmState.noTitle}
              buttonStyle={{
                width: 90,
                backgroundColor: colors.llAppBackground,
              }}
              titleStyle={{
                color: colors.primaryText,
              }}
              onPress={() => resolveConfirm(false)}
            />
          </View>
        </View>
      </Overlay>
    </ConfirmationContext.Provider>
  );
}

interface UseConfirmationResult {
  confirm: (props: ConfirmConfiguration) => Promise<void>;
}

export function useConfirmation(): UseConfirmationResult {
  const confirmationContext = useContext(ConfirmationContext);

  return {
    confirm: confirmationContext.confirm,
  };
}
