import { newRidgeState } from 'react-ridge-state';

export interface PassengerInfoState {
  form?: {
    title?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    password?: string;
  };
}

export const passengerInfoState = newRidgeState<PassengerInfoState>({
  form: {},
});
