import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Dimensions, Platform, View } from 'react-native';
import {
  AutocompleteDropdown,
  AutocompleteDropdownRef,
} from 'react-native-autocomplete-dropdown';

import { Text, useTheme } from '@rneui/themed';

import {
  TextInput,
  TextInputProps,
} from '../../../../../../components/forms/TextInput';
import { sizing, spacing } from '../../../../../../components/theme/theme';
import {
  Prediction,
  getAutocompleteAddress,
} from '../../../../api/getAutocompleteAddress';
import { LegAddress } from '../../../../api/types';
import { validateAllowedAddressDistance } from '../../../../api/validateAllowedAddressDistance';

export type ParsedAddress = {
  business: Boolean;
  city: string;
  description: string;
  id: string;
  state: string;
  street: string;
  title: string;
  zip: string;
};

interface AddressAutocompleteProps {
  /**
   * We don't currently support IATA's other than MSX
   */
  iata: string;
  label: React.ReactNode | string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  initialValue?: LegAddress;
  inputValue?: string;
  onSelectAddress: (value: Prediction) => void;
}

export function AddressAutocomplete({
  iata,
  label,
  required,
  placeholder,
  disabled,
  initialValue,
  inputValue,
  onSelectAddress,
}: AddressAutocompleteProps) {
  // The autocomplete has a quirk where it won't select the passed in value
  // unless you pass an id, and a mock list
  const initialValueAsSuggestion = {
    id: 'initial-value',
    title: initialValue?.first_address_line,
  };
  const [loading, setLoading] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState(
    initialValue ? [initialValueAsSuggestion] : null,
  );
  const [predictions, setPredictions] = useState<Array<Prediction>>([]);
  const dropdownController = useRef<AutocompleteDropdownRef>(null);
  const { theme } = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();

  const getSuggestions = useCallback(async (q) => {
    const addressPart = q.toLowerCase();

    if (typeof q !== 'string' || q.length < 3) {
      setSuggestionsList(null);
      return;
    }

    setErrorMessage(null);
    setLoading(true);

    // We will eventually support more, but MSX is the only airport currently supported for dropoff
    const result = await getAutocompleteAddress(addressPart, /*iata*/ 'MSX');
    setPredictions(result?.predictions ?? []);

    const suggestions = (result?.predictions ?? []).map((item) => ({
      id: item.place_id,
      title: item.description,
    }));
    setSuggestionsList(suggestions);
    setLoading(false);
  }, []);

  const onClearPress = useCallback(() => {
    setSuggestionsList(null);
    setErrorMessage(null);
    onSelectAddress(null);
  }, []);

  const handleSelectItem = useCallback(
    async (item) => {
      if (item) {
        const prediction = predictions.find((p) => p.place_id === item.id);
        const isValidDistance = await validateAllowedAddressDistance(
          item.title,
        );
        if (!isValidDistance) {
          setErrorMessage(
            t('screens.tripDetail.tripWizard.address.addressOutOfBounds'),
          );
          onSelectAddress(null);
          return;
        }
        dropdownController.current.close();
        onSelectAddress(prediction);
      }
    },
    [predictions, setErrorMessage],
  );

  useEffect(() => {
    if (dropdownController.current) {
      dropdownController.current.setInputText(inputValue ?? '');
    }
  }, [dropdownController, inputValue]);

  return (
    <View
      style={[
        { flex: 1, flexDirection: 'row', alignItems: 'center', zIndex: 1 },
      ]}
    >
      <AutocompleteDropdown
        controller={(controller) => {
          dropdownController.current = controller;
        }}
        initialValue={initialValue}
        direction="down"
        dataSet={suggestionsList}
        onChangeText={getSuggestions}
        onSelectItem={handleSelectItem}
        debounce={600}
        suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
        onClear={onClearPress}
        loading={false}
        useFilter={false} // set false to prevent rerender twice
        textInputProps={
          {
            required,
            errorMessage,
            placeholder,
            autoCorrect: false,
            autoCapitalize: 'none',
            autoComplete: 'street-address',
            label,
            disabled,
            testID: 'address-autocomplete',
            style: {
              flex: 1,
              height: 40,
              margin: 0,
            },
            containerStyle: {
              paddingHorizontal: 0,
              ...Platform.select({
                ios: {
                  marginTop: spacing.xxl,
                },
              }),
              borderBottomWidth: 0,
            },
            inputStyle: {
              paddingHorizontal: spacing.md,
              height: 40,
              margin: 0,
            },
            inputContainerStyle: {
              backgroundColor: 'transparent',
            },
            errorStyle: {
              margin: 0,
            },
            rightIcon: {
              type: 'font-awesome',
              name: 'times-circle',
              size: sizing.lg,
              color: theme.colors.grey1,
              style: {
                marginLeft: spacing.md,
                display: initialValue ? 'flex' : 'none',
              },
              onPress: () => {
                dropdownController.current.clear();
              },
            },
          } as TextInputProps
        }
        rightButtonsContainerStyle={{
          height: 'auto',
          alignSelf: 'center',
        }}
        suggestionsListContainerStyle={Platform.select({
          web: {
            backgroundColor: theme.colors.white,
            borderColor: theme.colors.divider,
            borderWidth: 1,
            borderStyle: 'solid',
          },
        })}
        inputContainerStyle={{
          backgroundColor: 'transparent',
        }}
        containerStyle={{
          flexGrow: 1,
          flexShrink: 1,
          padding: 0,
        }}
        renderItem={(item) => (
          <Text style={{ color: theme.colors.black, padding: 15 }}>
            {item.title}
          </Text>
        )}
        /**
         * Ignoring the prop type inconsistency... it's basically a TextInput,
         * and the props overlap enough
         * @ts-ignore-next */
        InputComponent={TextInput}
        showChevron={false}
        showClear={false}
        closeOnBlur={false}
      />
      {loading ? (
        <View
          style={{
            position: 'absolute',
            left: -5,
            width: '100%',
            alignItems: 'flex-end',
            zIndex: 11,
            ...Platform.select({
              ios: {
                top: 8,
              },
            }),
          }}
        >
          <ActivityIndicator
            color={theme.colors.primary}
            style={{
              backgroundColor: theme.colors.white,
            }}
          />
        </View>
      ) : null}
    </View>
  );
}
