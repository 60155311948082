import Constants from 'expo-constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';

import { Elements } from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeElements,
  StripeElementsOptions,
  loadStripe,
} from '@stripe/stripe-js';

import { LoadingBus } from '../../../../../components/loading/LoadingBus';
import { createPaymentMethodIntent } from '../../../api/createPaymentMethodIntent';
import { deletePaymentMethod } from '../../../api/deletePaymentMethod';
import {
  PaymentMethod,
  getPaymentMethods,
} from '../../../api/getPaymentMethods';
import { CreatePaymentMethodCard } from './CreatePaymentMethodCard';
import { ExistingPaymentMethodCard } from './ExistingPaymentMethodCard';

interface PaymentMethodCardProps {
  paymentMethodId?: number;
  onPressBack?: () => void;
  onPressNext: (
    elements?: StripeElements,
    stripe?: Stripe,
    method?: PaymentMethod,
  ) => void;
}
const { STRIPE_PUBLISHABLE_KEY } = Constants.manifest.extra;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

// Wrap with the Elements container to get into the correct context
export function PaymentMethodCard(props: PaymentMethodCardProps) {
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState<string>(null);
  const [paymentMethods, setPaymentMethods] =
    useState<Array<PaymentMethod>>(null);
  const { t } = useTranslation();

  const handleMount = useCallback(async () => {
    const paymentMethods = await getPaymentMethods();
    if (paymentMethods) {
      setPaymentMethods(paymentMethods);
    } else {
      const { secret } = await createPaymentMethodIntent();
      setClientSecret(secret);
    }
    setLoading(false);
  }, [setLoading, setClientSecret]);

  const handleRemovePaymentMethod = useCallback(
    async (method: PaymentMethod) => {
      const result = await deletePaymentMethod(method.id);
      if (!result) {
        Toast.show({
          type: 'error',
          text1: t(
            'screens.tripDetail.tripWizard.actions.paymentMethod.deleteFailed',
          ),
        });
        return;
      }

      const remainingPaymentMethods = paymentMethods.filter(
        (pm) => pm !== method,
      );

      if (!remainingPaymentMethods.length) {
        const { secret } = await createPaymentMethodIntent();
        setClientSecret(secret);
      }

      setPaymentMethods(paymentMethods.filter((pm) => pm !== method));
    },
    [paymentMethods, setPaymentMethods, setClientSecret],
  );

  useEffect(() => {
    handleMount();
  }, [handleMount]);

  const options: StripeElementsOptions = useMemo<StripeElementsOptions>(
    () => ({
      clientSecret,
    }),
    [clientSecret],
  );

  return (
    <>
      {loading ? (
        <LoadingBus />
      ) : paymentMethods?.length ? (
        <ExistingPaymentMethodCard
          initialSelectedMethodId={props.paymentMethodId}
          paymentMethods={paymentMethods}
          onPressNext={(method) => {
            props.onPressNext(null, null, method);
          }}
          onPressBack={props.onPressBack}
          onRemoveMethod={handleRemovePaymentMethod}
        />
      ) : (
        <Elements stripe={stripePromise} options={options}>
          <CreatePaymentMethodCard {...props} />
        </Elements>
      )}
    </>
  );
}
