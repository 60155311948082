import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Toast from 'react-native-toast-message';

import { Button } from '@rneui/themed';

import { globalState } from '../../../state/globalState';
import { refreshCurrentTrip } from '../../trip/api/refreshCurrentTrip';
import { updatePickupTime } from '../../trip/api/updatePickupTime';
import { updateSegmentBaggage } from '../../trip/api/updateSegmentBaggage';
import { getTimeIntervals } from '../../trip/components/tripWizard/utils/dates';
import { tripState } from '../../trip/state/tripState';
import { CardContainer } from '../components/CardContainer';
import { bookWizardState } from '../state/bookWizardState';
import { timeCardState } from '../state/timeCardState';
import { TimeForm, TimeFormFields } from './forms/TimeForm';

interface TimeCardProps {
  onPressNext: () => void;
}

export function TimeCard({ onPressNext }: TimeCardProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { currentTrip } = tripState.useValue();
  const hasReturnD2D = currentTrip?.segments?.length > 1;

  const departureSegment = currentTrip?.segments?.[0];
  const departureD2DLeg = departureSegment?.legs.find(
    (l) => l.airline.iata === 'L4',
  );

  const returnSegment = currentTrip?.segments?.[1];
  const returnD2DLeg = returnSegment?.legs.find((l) => l.airline.iata === 'L4');

  // Hydrate store state from trip
  useEffect(() => {
    if (!currentTrip) {
      return;
    }

    const departureIntervals = getTimeIntervals(
      departureD2DLeg.pick_up_details.offset_details.from,
      departureD2DLeg.pick_up_details.offset_details.to,
      departureD2DLeg.pick_up_details.offset_details.increments,
    );

    const returnIntervals = getTimeIntervals(
      returnD2DLeg?.pick_up_details.offset_details.from,
      returnD2DLeg?.pick_up_details.offset_details.to,
      returnD2DLeg?.pick_up_details.offset_details.increments,
    );

    const initialDepartureInterval = departureIntervals.find(
      (interval) => interval.offset === departureD2DLeg.pick_up_details.offset,
    );
    const initialReturnInterval = returnIntervals.find(
      (interval) => interval.offset === returnD2DLeg.pick_up_details.offset,
    );

    const departureFlightLeg = departureSegment.legs.find(
      (l) => l.airline.iata !== 'L4',
    );
    const returnFlightLeg = returnSegment?.legs.find(
      (l) => l.airline.iata !== 'L4',
    );

    timeCardState.set((state) => ({
      ...state,
      form: {
        ...state.form,
        pickup: {
          hasBaggage: departureSegment.checked_baggage > 0,
          interval: initialDepartureInterval,
          flightTime: departureFlightLeg.departure_time,
          intervals: departureIntervals,
          segmentId: departureSegment.id,
          legId: departureD2DLeg.id,
        },
        dropoff: {
          hasBaggage: returnSegment?.checked_baggage > 0 ?? false,
          interval: initialReturnInterval,
          flightTime: returnFlightLeg.departure_time,
          intervals: returnIntervals,
          segmentId: returnSegment.id,
          legId: returnD2DLeg.id,
        },
      },
    }));
  }, [currentTrip]);

  const pickupFormState = timeCardState.useSelector(
    (state) => state.form.pickup,
  );

  const dropoffFormState = timeCardState.useSelector(
    (state) => state.form.dropoff,
  );
  const [showDropoffPicker, setShowDropoffPicker] = useState(false);

  // Initialize form control using store values as default
  const pickupForm = useForm<TimeFormFields>({
    defaultValues: {
      ...pickupFormState,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const dropoffForm = useForm<TimeFormFields>({
    defaultValues: {
      ...dropoffFormState,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // Hydrate form state from store (if its programmatically updated)
  useEffect(() => {
    Object.keys(pickupFormState).forEach((field: keyof TimeFormFields) => {
      const value = pickupFormState[field];
      pickupForm.setValue(field, value, {
        shouldValidate: true,
      });
    });

    Object.keys(dropoffFormState).forEach((field: keyof TimeFormFields) => {
      const value = dropoffFormState[field];
      dropoffForm.setValue(field, value, {
        shouldValidate: true,
      });
    });
  }, [pickupFormState, dropoffFormState]);

  // Update store state after submit, proceed to next
  const updateStore = useCallback(
    (fields: TimeFormFields, type: 'pickup' | 'dropoff') => {
      timeCardState.set((state) => ({
        ...state,
        form: {
          ...state.form,
          [type]: {
            ...fields,
          },
        },
      }));
    },
    [],
  );

  // Changes in baggage will cause the pickup times intervals to change. Persist changes, and refresh the trip.
  const pickupBaggage = pickupForm.watch('hasBaggage');
  const dropoffBaggage = dropoffForm.watch('hasBaggage');

  const handleUpdateSegmentBaggage = useCallback(
    async (segmentId: number, hasBaggage: boolean) => {
      await updateSegmentBaggage(segmentId, hasBaggage);
      await refreshCurrentTrip();
    },
    [],
  );

  useEffect(() => {
    if (
      !pickupFormState.segmentId ||
      pickupBaggage === !!departureSegment.checked_baggage
    ) {
      return;
    }

    handleUpdateSegmentBaggage(pickupFormState.segmentId, pickupBaggage);
  }, [pickupBaggage]);

  useEffect(() => {
    if (
      !dropoffFormState.segmentId ||
      dropoffBaggage === !!returnSegment.checked_baggage
    ) {
      return;
    }

    handleUpdateSegmentBaggage(dropoffFormState.segmentId, dropoffBaggage);
  }, [dropoffBaggage]);

  const handleCardSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await pickupForm.handleSubmit(async (fields) => {
        updateStore(fields, 'pickup');

        await updatePickupTime(pickupFormState.legId, fields.interval.offset);
      })();

      await dropoffForm.handleSubmit(async (fields) => {
        updateStore(fields, 'dropoff');

        if (dropoffFormState?.legId) {
          await updatePickupTime(
            dropoffFormState.legId,
            fields.interval.offset,
          );
        }
      })();

      onPressNext();
    } catch (_e) {
      Toast.show({
        text1: 'Unable to update time offset',
        type: 'error',
      });
    }

    setLoading(false);
  }, [updateStore, dropoffFormState, pickupFormState]);

  // Update crumb state
  useEffect(() => {
    globalState.set((state) => ({
      ...state,
      showFooter: false,
    }));

    bookWizardState.set((state) => ({
      ...state,
      showBreadcrumbs: true,
    }));
  }, []);

  return (
    <CardContainer>
      <TimeForm
        type="pickup"
        formControl={pickupForm}
        containerStyle={{ zIndex: 1 }}
      />
      {hasReturnD2D ? (
        !showDropoffPicker ? (
          <Button
            title={t('screens.book.timeCard.actions.revealDropoff')}
            containerStyle={{
              width: 240,
              marginTop: 35,
              alignSelf: 'center',
            }}
            onPress={() => setShowDropoffPicker(true)}
          />
        ) : (
          <View style={{ marginTop: 25 }}>
            <TimeForm type="dropoff" formControl={dropoffForm} />
            <Button
              title={t('screens.book.timeCard.actions.submit')}
              containerStyle={{
                width: 240,
                alignSelf: 'center',
                marginTop: 35,
              }}
              loading={loading}
              onPress={handleCardSubmit}
            />
          </View>
        )
      ) : (
        <View style={{ marginTop: 25 }}>
          <Button
            title={t('screens.book.timeCard.actions.submit')}
            containerStyle={{
              width: 240,
              alignSelf: 'center',
              marginTop: 35,
            }}
            loading={loading}
            onPress={handleCardSubmit}
          />
        </View>
      )}
    </CardContainer>
  );
}
