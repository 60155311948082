import { format, parseISO } from 'date-fns';
import { DateTime } from 'luxon';
import React, { forwardRef, memo, useMemo, useRef } from 'react';
import { Dimensions, Platform, TextInput, View } from 'react-native';
import {
  AutocompleteDropdown,
  AutocompleteDropdownRef,
} from 'react-native-autocomplete-dropdown';

import { Input, Text, useTheme } from '@rneui/themed';

import { spacing } from '../../../../../../components/theme/theme';
import { TimeInterval } from '../../utils/dates';

interface TimeIntervalAutocompleteProps {
  placeholder: string;
  intervals: Array<TimeInterval>;
  initialInterval?: TimeInterval;
  onSelectInterval: (value: TimeInterval) => void;
}

export function TimeIntervalAutocomplete({
  placeholder,
  intervals,
  initialInterval,
  onSelectInterval,
}: TimeIntervalAutocompleteProps) {
  const dropdownController = useRef<AutocompleteDropdownRef>(null);
  const { theme } = useTheme();

  const suggestions = useMemo(
    () =>
      intervals.map((interval) => ({
        id: interval.value,
        title: DateTime.fromISO(interval.value, {
          setZone: true,
        }).toFormat('hh:mm a'),
      })),
    [],
  );

  const initialValue = useMemo(() => {
    if (initialInterval) {
      return {
        id: initialInterval.value,
      };
    }
  }, [initialInterval]);

  const searchInput = memo(
    forwardRef<TextInput>((props, ref) => (
      <Input
        {...props}
        ref={ref}
        testID="time-interval-autocomplete"
        containerStyle={{
          paddingHorizontal: 0,
          ...Platform.select({
            ios: {
              marginTop: spacing.xxl,
            },
          }),
          borderBottomWidth: 0,
        }}
        inputStyle={{
          paddingHorizontal: spacing.md,
        }}
        inputContainerStyle={{
          borderBottomWidth: 0,
        }}
        errorStyle={{
          margin: 0,
        }}
      />
    )),
  );

  return (
    <>
      <View
        style={[
          { flex: 1, flexDirection: 'row', alignItems: 'center', zIndex: 1 },
        ]}
      >
        <AutocompleteDropdown
          controller={(controller) => {
            dropdownController.current = controller;
          }}
          direction="down"
          dataSet={suggestions}
          initialValue={initialValue}
          onSelectItem={(item) => {
            if (item) {
              const interval = intervals.find((i) => i.value === item.id);

              onSelectInterval(interval);
            }
          }}
          suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
          loading={false}
          useFilter={false} // set false to prevent rerender twice
          textInputProps={{
            placeholder,
            autoCorrect: false,
            autoCapitalize: 'none',
            style: {
              backgroundColor: theme.colors.searchBg,
              flex: 1,
              color: theme.colors.white,
            },
          }}
          rightButtonsContainerStyle={{
            height: 'auto',
            alignSelf: 'center',
          }}
          suggestionsListContainerStyle={Platform.select({
            web: {
              backgroundColor: theme.colors.white,
              borderColor: theme.colors.divider,
              borderWidth: 1,
              borderStyle: 'solid',
              width: '100%',
            },
          })}
          inputContainerStyle={{
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }}
          containerStyle={{
            flexGrow: 1,
            flexShrink: 1,
            padding: 0,
          }}
          renderItem={(item) => (
            <Text style={{ color: theme.colors.black, padding: 15 }}>
              {item.title}
            </Text>
          )}
          /**
           * Ignoring the prop type inconsistency... it's basically a TextInput,
           * and the props overlap enough
           * @ts-ignore-next */
          InputComponent={searchInput}
          showChevron={false}
          showClear={false}
          closeOnBlur={false}
        />
      </View>
    </>
  );
}
