export type PassengerTitleOption =
  | 'Mrs'
  | 'Mr'
  | 'Miss'
  | 'Mstr'
  | 'Dr'
  | 'Prof'
  | 'Rev';

export type PassengerTitle = {
  title: string;
  value: string;
};

export function getPassengerTitles(): Array<PassengerTitle> {
  return ['Mrs', 'Mr', 'Miss', 'Mstr', 'Dr', 'Prof', 'Rev'].map((d) => ({
    value: d,
    title: `${d}.`,
  }));
}
