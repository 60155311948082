export const en = {
  sections: {
    landline: {
      title: 'Landline',
      links: {
        aboutUs: 'About Us',
        news: 'News',
        howItWorks: 'How It Works',
        faqs: 'FAQs',
      },
    },
    contact: {
      title: 'Contact',
    },
    partnerships: {
      title: 'Partnerships',
    },
    schedulesAndFares: {
      title: 'Schedule & Fares',
    },
    locations: {
      title: 'Locations',
    },
    legal: {
      title: 'Legal',
      links: {
        terms: 'Terms and Conditions',
        privacy: 'Privacy Policy',
      },
    },
  },
};
