import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Card, Text, useTheme } from '@rneui/themed';

import { sizing, spacing } from '../../../../components/theme/theme';
import { format } from '../tripWizard/utils/money';

export type PriceItem = {
  price: number;
  label: string;
};

interface PriceCardProps {
  items: Array<PriceItem>;
}

export function PriceCard({ items }: PriceCardProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const totalPrice = useMemo(
    () => items.reduce((acc, item) => acc + item.price, 0),
    [],
  );

  if (!items.length) {
    return null;
  }

  return (
    <Card
      containerStyle={{
        paddingTop: spacing.sm,
        paddingBottom: spacing.sm,
      }}
    >
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: 'rgb(225, 232, 238)',
        }}
      >
        <Text
          style={{
            fontSize: sizing.lg,
            paddingBottom: 4,
          }}
        >
          Trip Price
        </Text>
      </View>
      <View style={{ alignItems: 'center', marginTop: spacing.md }}>
        <View style={{ alignItems: 'flex-end' }}>
          {items.map((item) => (
            <Text key={item.label + item.price} style={{ fontSize: sizing.lg }}>
              {item.label}: {format(item.price)}
            </Text>
          ))}
          {items.length === 1 ? null : (
            <>
              <View
                style={{
                  borderBottomWidth: 2,
                  borderBottomColor: theme.colors.divider,
                  height: 2,
                  width: '100%',
                  flex: 1,
                }}
              />
              <Text style={{ fontSize: sizing.lg }}>
                {t('screens.tripDetail.priceCard.totalLabel', {
                  value: format(totalPrice),
                })}
              </Text>
            </>
          )}
        </View>
      </View>
    </Card>
  );
}
