import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@rneui/themed';

import { LoadingBus } from '../../../../../components/loading/LoadingBus';
import { fetchCurrentTrip } from '../../../api/fetchCurrentTrip';
import { tripWizardState } from '../../../state/tripWizardState';
import { getServiceLabel } from '../../legCard/components/AirportLabel';
import { PriceCard, PriceItem } from '../../priceCard/PriceCard';
import { findLeg } from '../utils/legs';
import { CardLayout } from './CardLayout';

export interface PriceConfirmationCardProps {
  onPressBack?: () => void;
  onPressNext: () => void;
}

export function PriceConfirmationCard({
  onPressBack,
  onPressNext,
}: PriceConfirmationCardProps) {
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = useState(true);
  const [priceQuotes, setPriceQuotes] = useState<Array<PriceItem>>([]);
  const [confirming, setConfirming] = useState(false);
  const { quote } = tripWizardState.get();

  // Refreshing trip to calculate the latest trip cost
  const handleRefreshTrip = useCallback(async () => {
    const trip = await fetchCurrentTrip();
    const items: Array<PriceItem> = quote.map((q) => {
      const leg = findLeg(q.legId, trip);

      const item: PriceItem = {
        label: `${getServiceLabel(
          leg.depart.iata,
          leg.depart.AirportName,
        )} -> ${getServiceLabel(leg.arrive.iata, leg.arrive.AirportName)}`,
        price: quote.find((q) => q.legId === leg.id).quote.priceTotal,
      };

      return item;
    });

    setPriceQuotes(items);
    setRefreshing(false);
  }, [setRefreshing, setPriceQuotes, quote]);

  const handleOnPressNext = useCallback(() => {
    setConfirming(true);
    onPressNext();
  }, []);

  useEffect(() => {
    handleRefreshTrip();
  }, []);

  return (
    <CardLayout
      icon="shopping-cart"
      testID="trip-wizard-price-confirmation-card"
      title={t('screens.tripDetail.tripWizard.priceConfirmation.title')}
      subTitle={
        <Text>
          {t('screens.tripDetail.tripWizard.priceConfirmation.subtitle')}
        </Text>
      }
      showBackButton={!!onPressBack}
      onPressBack={onPressBack}
      onPressNext={handleOnPressNext}
    >
      {refreshing || confirming ? (
        <LoadingBus />
      ) : (
        <PriceCard items={priceQuotes} />
      )}
    </CardLayout>
  );
}
