import { api } from '../../../api';
import {AddressDirection, AirportDirection} from './types';

export type QuoteResponse = {
  quoteId: number;
  distance: number;
  markup: number;
  pointA: object;
  pointB: object;
  priceDistance: number;
  pricePerMile: number;
  priceRoundTrip: number;
  priceTotal: number;
};

export async function createPriceQuote(
  address: string,
  direction: AddressDirection,
  airportDirection: AirportDirection = null,
): Promise<QuoteResponse> {
  if (airportDirection === null) {
    airportDirection = direction === "pickup_address" ? "drop_off_airport" : "pickup_airport"
  }

  try {
    const { data } = await api.post<QuoteResponse>(`quoting/quote/`, {
      [direction]: address,
      [airportDirection] : 'MSX'
    });
    return data;
  } catch (e) {
    return null;
  }
}
