import { newRidgeState } from 'react-ridge-state';

import { QuoteResponse } from '../../trip/api/createPriceQuote';

export type LocationFormState = {
  isValid?: boolean;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  zipcode?: string;
  state?: string;
  legId?: number;
};

export type LocationQuoteState = QuoteResponse & {
  legId?: number;
};

export type TripDirectionType =
  | 'roundTrip'
  | 'roundTripDropoffAlternate'
  | 'pickupOnly'
  | 'dropoffOnly';

export interface LocationCardState {
  form: {
    pickupLocation?: LocationFormState;
    dropoffLocation?: LocationFormState;
    type?: TripDirectionType;
  };
  quote: {
    pickup?: LocationQuoteState;
    dropoff?: LocationQuoteState;
  };
}

export const locationCardState = newRidgeState<LocationCardState>({
  form: {
    type: 'roundTrip',
  },
  quote: {},
});
