import * as Localization from 'expo-localization';
import i18next from 'i18next';

// Components
import * as ComponentsI18n from './components/i18n';
// Routes
import * as RoutesI18n from './routes/i18n';
// Screens
import * as ScreensI18n from './screens/i18n';

const i18n = i18next.createInstance();

i18n.init({
  lng: Localization.locale,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        global: {
          landline: {
            logo: 'Landline Logo',
            customerServicePhone: '1 888 428 1149',
            customerServicePhoneLabel: '+1 (888) 428-1149',
            copyright: '©2019 The Landline Company.',
            rights: 'All Rights Reserved.',
          },
          common: {
            departs: 'Departs',
            arrives: 'Arrives',
            layover: 'Layover',
            returns: 'Returns',
            contactUs: 'Contact Us',
            contactDriver: 'Contact Driver',
            call: 'Call',
            close: 'Close',
            empty: 'No items found',
            yes: 'Yes',
            no: 'No',
            confirm: 'Confirm',
            okay: 'Okay',
            next: 'Next',
            back: 'Back',
            homeService: 'Home Service',
            expires: 'Expires',
            expired: 'Expired',
          },
          form: {
            required: 'This field is required',
          },
        },
        components: ComponentsI18n.en,
        routes: RoutesI18n.en,
        screens: ScreensI18n.en,
      },
    },
  },
});

export { i18n };
