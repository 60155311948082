import * as Device from 'expo-device';
import { StyleProp, ViewStyle, useWindowDimensions } from 'react-native';

import { createTheme } from '@rneui/themed';

import { globalState } from '../../state/globalState';

export const colors = {
  primary: '#fab500',
  primaryHover: '#ff9800',
  primaryText: '#3c3835',
  secondaryText: 'rgb(144, 144, 144)',
  transparentOverlay: 'rgba(0, 0, 0, 0.2)',
  accent: '#3F77F0',
  selected: 'rgba(255, 152, 0, .2)',

  // Design colors
  primaryGray: '#3C3835',
  headerLink: '#ffffff',
  pageBackground: '#f2f2f2',
  screenBackground: '#ffffff',
  footerText: '#ffffff',
  /**
   * #FAB500
   */
  llYellow: '#FAB500',
  /**
   * #FF9800
   */
  llOrange: '#FF9800',
  /**
   * #CDCCC8
   */
  outlineGray: '#CDCCC8',
  /**
   * #28221E
   */
  llSubnavDarkGray: '#28221E',
  /**
   * #696D6F
   */
  llSubnavDarkLink: '#696D6F',
  /**
   * #C4C4C4
   */
  llSubnavGrayLink: '#C4C4C4',
  /**
   * #FFFFFF
   */
  llSubnavActiveLink: '#FFFFFF',
  llLightGray: '#E5E5E5',
  llAppBackground: '#D9D9D9',
};

// Temporary values based on 4x progression
export const spacing = {
  sm: 4,
  md: 8,
  lg: 12,
  xxl: 16,
  '2xl': 20,
  '3xl': 24,
};

export const cardMargin = 15;

// Temporary values (depending on design approval) based on 6x progression
export const sizing = {
  sm: 6,
  md: 12,
  sz: 16,
  lg: 18,
  xl: 24,
  xxl: 30,
  '3xl': 34,
  '4xl': 38,
};

// Max width for non-device displays
export const maxViewWidth = 800;

export function useMaxViewWidth() {
  const windowWidth = useWindowDimensions().width;
  const maxWidth = windowWidth < maxViewWidth ? windowWidth : maxViewWidth;

  return !Device.deviceName ? maxWidth : windowWidth;
}

// Returns the maximum safe view height accounting for header/footer heights
export function useMaxViewHeight() {
  const windowHeight = useWindowDimensions().height;
  const footerVisible = globalState.useSelector((state) => state.showFooter);
  const headerPadding = 30;
  const headerHeight = 85 + headerPadding;
  const footerHeight = footerVisible ? 196 : 0;

  return windowHeight - (headerHeight + footerHeight);
}

export const fontFamily = {
  regular: 'SourceSansPro_400Regular',
  semiBold600: 'SourceSansPro_600SemiBold',
  bold: 'SourceSansPro_700Bold',
};

export const layoutRegions = {
  centered: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  } as StyleProp<ViewStyle>,
  bottomCentered: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  } as StyleProp<ViewStyle>,
};

export const landlineTheme = createTheme({
  lightColors: {
    primary: colors.primary,
  },
  Button: {
    titleStyle: {
      color: colors.primaryText,
      fontFamily: fontFamily.regular,
      fontSize: 14,
    },
    containerStyle: {
      borderRadius: 6,
    },
  },
  Text: {
    style: {
      fontFamily: fontFamily.regular,
      fontSize: 16,
    },
    h1Style: {
      fontFamily: fontFamily.semiBold600,
      fontSize: 24,
      lineHeight: 24,
      borderBottomWidth: 1,
      paddingBottom: 10,
      marginBottom: 25,
    },
  },
  Input: {
    inputStyle: {
      paddingHorizontal: 0,
      paddingLeft: 12,
      fontSize: 16,
    },
    containerStyle: {
      paddingHorizontal: 0,
    },
    inputContainerStyle: {
      borderWidth: 1,
      borderRadius: 6,
      paddingHorizontal: 0,
      paddingVertical: 8,
      height: 40,
    },
    labelStyle: {
      textTransform: 'uppercase',
      fontSize: 12,
      color: colors.primaryText,
      marginBottom: -8,
      marginLeft: 10,
      backgroundColor: colors.screenBackground,
      maxWidth: 'fit-content',
      zIndex: 1,
      paddingHorizontal: 4,
      fontWeight: 'normal',
      fontFamily: fontFamily.semiBold600,
    },
  },
  CheckBox: {
    // Match card margins
    containerStyle: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      padding: 0,
    },
    textStyle: {
      fontFamily: fontFamily.regular,
      fontSize: 16,
      lineHeight: 30,
      fontWeight: 'normal',
    },
  },
  Overlay: {
    overlayStyle: {
      padding: 0,
    },
  },
});
