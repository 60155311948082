import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@rneui/themed';

import { CardLayout } from './CardLayout';

export interface SpecialRequestsCardProps {
  subTitle: JSX.Element;
  note?: string;
  nextButtonTitle?: string;
  onPressBack?: () => void;
  onPressNext: (note: string) => void;
}

export function SpecialRequestsCard({
  subTitle,
  note,
  nextButtonTitle,
  onPressBack,
  onPressNext,
}: SpecialRequestsCardProps) {
  const { t } = useTranslation();
  const [currentNote, setNote] = useState(note ?? '');

  const handleUpdateNote = useCallback(
    (updatedNote) => setNote(updatedNote),
    [setNote],
  );
  const handlePressNext = useCallback(
    () => onPressNext(currentNote),
    [currentNote],
  );

  return (
    <CardLayout
      icon="edit"
      testID="trip-wizard-special-requests-card"
      title={t('screens.tripDetail.tripWizard.specialRequests.title')}
      subTitle={subTitle}
      nextButtonTitle={nextButtonTitle}
      showBackButton={!!onPressBack}
      onPressBack={onPressBack}
      onPressNext={handlePressNext}
    >
      <Input
        multiline
        placeholder={t(
          'screens.tripDetail.tripWizard.specialRequests.placeholder',
        )}
        inputStyle={{
          height: 50,
        }}
        value={currentNote}
        onChangeText={handleUpdateNote}
      />
    </CardLayout>
  );
}
