import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import Toast from 'react-native-toast-message';

import { Text } from '@rneui/themed';

import { LoadingBus } from '../../components/loading/LoadingBus';
import { spacing } from '../../components/theme/theme';
import { LoginPrompt } from '../auth/LoginPrompt';
import { CreateUserValidationErrorResult } from '../auth/api/createUser';
import { FullUserResult, getFullUser } from '../auth/api/getUserProfile';
import { updateUserProfile } from '../auth/api/updateUserProfile';
import { useAuth } from '../auth/components/context/useAuth';
import { AccountDetailsForm } from '../auth/components/forms/AccountDetailsForm';

export function AccountScreen() {
  const { isLoggedIn, clearToken } = useAuth();
  const { t } = useTranslation();

  const [serverErrors, setServerErrors] =
    useState<CreateUserValidationErrorResult>();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<FullUserResult>();

  const handleLoadProfile = useCallback(async () => {
    setLoading(true);
    const result = await getFullUser();

    if (!result) {
      await clearToken();
      setLoading(false);
      return;
    }

    setProfile(result);
    setLoading(false);
  }, [setProfile, setSaving, isLoggedIn]);

  const handleUpdateAccount = useCallback(
    async ({ email, first_name, last_name, phone_number }) => {
      setSaving(true);

      const result = await updateUserProfile({
        username: email,
        firstName: first_name,
        lastName: last_name,
        phoneNumber: phone_number,
      });

      if (!result) {
        Toast.show({
          type: 'error',
          text1: t('screens.account.action.updateUser.error'),
        });
      } else if ((result as CreateUserValidationErrorResult).validation_error) {
        Toast.show({
          type: 'error',
          text1: t('screens.account.action.updateUser.error'),
        });

        setServerErrors(result as CreateUserValidationErrorResult);
      } else {
        Toast.show({
          type: 'success',
          text1: t('screens.account.action.updateUser.success'),
        });
        setServerErrors(null);
        handleLoadProfile();
      }

      setSaving(false);
    },
    [setSaving, setServerErrors],
  );

  useEffect(() => {
    handleLoadProfile();
  }, [handleLoadProfile, isLoggedIn]);

  if (!isLoggedIn) {
    return <LoginPrompt returnScreen="account" />;
  }

  return (
    <ScrollView
      contentContainerStyle={{
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 100,
      }}
      style={{
        flex: 1,
      }}
    >
      <View style={{ width: '100%', paddingHorizontal: spacing.md }}>
        <Text
          h4
          h4Style={{
            marginBottom: spacing.md,
            textAlign: 'center',
          }}
        >
          {t('screens.account.updateUser.title')}
        </Text>
        {loading ? (
          <LoadingBus />
        ) : (
          <AccountDetailsForm
            serverErrors={serverErrors?.validation_error ?? null}
            loading={saving}
            type="update"
            submitTitle={t('screens.account.action.updateUser.buttonTitle')}
            account={profile}
            onSubmit={handleUpdateAccount}
          />
        )}
      </View>
    </ScrollView>
  );
}
