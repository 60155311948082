import { api } from '../../../api';
import { AddressDirection } from './types';

export interface PickupDetailsUpdate {
  city: string;
  first_address_line: string;
  state: string;
}

export async function updatePickupDetails(
  legID: number,
  direction: AddressDirection,
  data: PickupDetailsUpdate,
): Promise<boolean> {
  const directionPath =
    direction === 'pickup_address' ? 'pick_up_address' : 'drop_off_address';

  try {
    await api.post(
      `v2/booking/pick_up_details/${legID}/${directionPath}/`,
      data,
    );
    return true;
  } catch (e) {
    return false;
  }
}
