import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Toast from 'react-native-toast-message';

import { Button, Text } from '@rneui/themed';

import { FadeInView } from '../../../components/animations/FadeInView';
import { FormRow } from '../../../components/forms/FormRow';
import { RadioGroup } from '../../../components/forms/RadioGroup';
import { colors, fontFamily } from '../../../components/theme/theme';
import {
  compareAddress,
  validAddress,
} from '../../../components/utils/address';
import { last } from '../../../components/utils/array';
import { globalState } from '../../../state/globalState';
import { createPriceQuote } from '../../trip/api/createPriceQuote';
import { updateLegQuote } from '../../trip/api/updateLegQuote';
import { updatePickupDetails } from '../../trip/api/updatePickupDetails';
import { tripState } from '../../trip/state/tripState';
import { CardContainer } from '../components/CardContainer';
import { bookWizardState } from '../state/bookWizardState';
import {
  TripDirectionType,
  locationCardState,
} from '../state/locationCardState';
import { getTripPrice } from '../utils/getTripPrice';
import { LocationForm, LocationFormFields } from './forms/LocationForm';

interface LocationCardProps {
  onPressNext: () => void;
}

export function LocationCard({ onPressNext }: LocationCardProps) {
  const { t } = useTranslation();

  const { currentTrip } = tripState.useValue();
  const departureLeg = currentTrip?.segments?.[0].legs?.find(
    (leg) => leg.airline.iata === 'L4',
  );
  const returnLeg = last(currentTrip?.segments ?? [])?.legs?.find(
    (l) => l.airline.iata === 'L4',
  );

  const [showPrice, setShowPrice] = useState(false);
  const [loading, setLoading] = useState(false);

  // Hydrate store state from trip
  useEffect(() => {
    if (!currentTrip) {
      return;
    }

    const hasDepartureD2D = !!departureLeg;
    const hasReturnD2D = !!returnLeg;

    // Determine the initial trip type
    let tripType: TripDirectionType = 'roundTrip';
    if (hasDepartureD2D && !hasReturnD2D) {
      tripType = 'dropoffOnly';
    } else if (!hasDepartureD2D && hasReturnD2D) {
      tripType = 'pickupOnly';
    } else if (
      hasDepartureD2D &&
      hasReturnD2D &&
      !compareAddress(
        departureLeg.pick_up_details.pick_up_address,
        returnLeg.pick_up_details.drop_off_address,
      )
    ) {
      tripType = 'roundTripDropoffAlternate';
    }

    // Something unexpected, reset
    if (!departureLeg.quote || !returnLeg.quote) {
      tripType = 'roundTrip';
    } else {
      setShowPrice(true);
    }

    locationCardState.set((state) => ({
      ...state,
      form: {
        pickupLocation:
          validAddress(departureLeg.pick_up_details.pick_up_address) &&
          departureLeg.quote
            ? {
                streetAddress1:
                  departureLeg.pick_up_details.pick_up_address
                    .first_address_line,
                streetAddress2:
                  departureLeg.pick_up_details.pick_up_address
                    .second_address_line,
                city: departureLeg.pick_up_details.pick_up_address.city,
                state: departureLeg.pick_up_details.pick_up_address.state,
                // zipcode: departureLeg.pick_up_details.pick_up_address.zip_code,
                legId: departureLeg.id,
              }
            : {},
        dropoffLocation:
          validAddress(returnLeg.pick_up_details.drop_off_address) &&
          returnLeg.quote
            ? {
                streetAddress1:
                  returnLeg.pick_up_details.drop_off_address.first_address_line,
                streetAddress2:
                  returnLeg.pick_up_details.drop_off_address
                    .second_address_line,
                city: returnLeg.pick_up_details.drop_off_address.city,
                state: returnLeg.pick_up_details.drop_off_address.state,
                // zipcode: returnLeg.pick_up_details.drop_off_address.zip_code,
                legId: returnLeg.id,
              }
            : {},
        type: tripType,
      },
    }));
  }, [currentTrip, departureLeg, returnLeg]);

  const formState = locationCardState.useSelector((state) => state.form);
  const quoteState = locationCardState.useSelector((state) => state.quote);

  // Initialize form control using store values as default
  const pickupForm = useForm<LocationFormFields>({
    defaultValues: {
      ...formState.pickupLocation,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // Initialize form control using store values as default
  const dropoffForm = useForm<LocationFormFields>({
    defaultValues: {
      ...formState.dropoffLocation,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // Hydrate form state from store (if its programmatically updated)
  useEffect(() => {
    Object.keys(formState?.pickupLocation ?? {}).forEach(
      (field: keyof LocationFormFields) => {
        const value = formState.pickupLocation[field];
        pickupForm.setValue(field, value, {
          shouldValidate: true,
        });
      },
    );

    Object.keys(formState?.dropoffLocation ?? {}).forEach(
      (field: keyof LocationFormFields) => {
        const value = formState.dropoffLocation[field];
        dropoffForm.setValue(field, value, {
          shouldValidate: true,
        });
      },
    );
  }, [formState, pickupForm, dropoffForm]);

  // Watch the prediction address to generate a quote
  const pickupPrediction = useWatch<LocationFormFields>({
    control: pickupForm.control,
    name: 'predictionAddress',
  });

  const dropoffPrediction = useWatch<LocationFormFields>({
    control: dropoffForm.control,
    name: 'predictionAddress',
  });

  // When a prediction occurs, store it in state
  useEffect(() => {
    if (pickupPrediction) {
      createPriceQuote(pickupPrediction, 'pickup_address').then((response) => {
        locationCardState.set((state) => ({
          ...state,
          quote: {
            ...state.quote,
            pickup: {
              ...response,
              legId: departureLeg.id,
            },
          },
        }));
      });
    }

    if (dropoffPrediction) {
      createPriceQuote(dropoffPrediction, 'drop_off_address').then(
        (response) => {
          locationCardState.set((state) => ({
            ...state,
            quote: {
              ...state.quote,
              dropoff: {
                ...response,
                legId: returnLeg.id,
              },
            },
          }));
        },
      );
    }
  }, [pickupPrediction, dropoffPrediction]);

  const formType = locationCardState.useSelector((state) => state.form.type);

  const setTripType = useCallback(
    (type: TripDirectionType) => {
      locationCardState.set((state) => {
        return {
          ...state,
          form: {
            ...state.form,
            type,
          },
        };
      });

      if (type === 'roundTripDropoffAlternate') {
        setShowPrice(false);
      } else {
        // Clear the drop off quote
        locationCardState.set((state) => {
          return {
            ...state,
            quote: {
              ...state.quote,
              dropoff: null,
            },
          };
        });
      }
    },
    [setShowPrice],
  );

  useEffect(() => {
    globalState.set((state) => ({
      ...state,
      showFooter: false,
    }));

    bookWizardState.set((state) => ({
      ...state,
      showBreadcrumbs: true,
    }));
  }, []);

  // Update store state after submit, proceed to next
  const updateStore = useCallback(
    (
      fields: LocationFormFields,
      type: 'pickupLocation' | 'dropoffLocation',
    ) => {
      locationCardState.set((state) => ({
        ...state,
        form: {
          ...state.form,
          [type]: {
            ...fields,
          },
        },
      }));
    },
    [locationCardState],
  );

  const handleRevealPrice = useCallback(async () => {
    try {
      await pickupForm.handleSubmit((fields) => {
        updateStore(fields, 'pickupLocation');
      })();

      await dropoffForm.handleSubmit((fields) => {
        updateStore(fields, 'dropoffLocation');
      })();

      setShowPrice(true);
    } catch (_e) {
      // Ignore errors, they will be presented in the form field
    }
  }, [setShowPrice, updateStore, pickupForm, dropoffForm]);

  const hasExistingQuote = departureLeg.quote || returnLeg.quote;
  const hasNewQuote = quoteState.pickup || quoteState.dropoff;

  // Determine trip price to show by form type
  const tripPrice = useMemo(() => {
    if (hasExistingQuote && !hasNewQuote) {
      return getTripPrice(
        formType,
        departureLeg.quote.total_price_rounded,
        returnLeg.quote?.total_price_rounded,
      );
    }

    return getTripPrice(
      formType,
      quoteState.pickup?.priceTotal ??
        departureLeg.quote?.total_price_rounded ??
        0,
      quoteState.dropoff?.priceTotal ?? 0,
    );
  }, [quoteState, formType]);

  const disableRevealPriceAction = useMemo(
    () => !pickupForm.formState.isValid || !dropoffForm.formState.isValid,
    [pickupForm.formState.isValid, dropoffForm.formState.isValid],
  );

  const handleCardSubmit = useCallback(async () => {
    bookWizardState.set((state) => ({
      ...state,
      breadcrumbPrice: `$${tripPrice.toFixed(2)}`,
    }));

    try {
      setLoading(false);

      const locationUpdates = [
        {
          form: locationCardState.get().form.pickupLocation,
          quote: quoteState.pickup,
          legId: departureLeg.id,
        },
      ];

      if (formType === 'roundTrip') {
        locationUpdates.push({
          form: locationCardState.get().form.pickupLocation,
          quote: quoteState.pickup,
          legId: returnLeg.id,
        });
      } else if (formType !== 'pickupOnly') {
        locationUpdates.push({
          form: locationCardState.get().form.dropoffLocation,
          quote: quoteState.dropoff,
          legId: returnLeg.id,
        });
      }

      await Promise.all(
        locationUpdates.map((addressUpdate, i) => {
          return Promise.all([
            updatePickupDetails(
              addressUpdate.legId,
              i === 0 ? 'pickup_address' : 'drop_off_address',
              {
                city: addressUpdate.form.city,
                state: addressUpdate.form.state,
                first_address_line: addressUpdate.form.streetAddress1,
              },
            ),
            hasNewQuote
              ? updateLegQuote(addressUpdate.legId, addressUpdate.quote.quoteId)
              : Promise.resolve(),
          ]);
        }),
      );

      setLoading(false);

      onPressNext();
    } catch (e) {
      Toast.show({
        text1: 'Failed to update location',
        type: 'error',
      });
    }
  }, [
    quoteState,
    tripPrice,
    locationCardState,
    setLoading,
    departureLeg,
    returnLeg,
    formType,
  ]);

  return (
    <CardContainer>
      <LocationForm
        formControl={pickupForm}
        type={formType !== 'dropoffOnly' ? 'pickup' : 'dropoff'}
        containerStyle={{ zIndex: 1 }}
      />
      <Text
        style={{
          fontSize: 20,
          fontFamily: fontFamily.regular,
          lineHeight: 25,
          marginTop: 25,
        }}
      >
        {t('screens.book.locationCard.fields.tripDirectionTitle')}
      </Text>
      <FormRow>
        <View>
          <RadioGroup
            value={formType}
            computeValueIndex={(_items, value) => {
              if (value === 'roundTrip') {
                return 0;
              }
              if (value === 'roundTripDropoffAlternate') {
                return 2;
              }

              return 1;
            }}
            items={[
              {
                id: 'roundTrip',
                title: t('screens.book.locationCard.fields.roundTrip'),
              },
              {
                id: 'oneway',
                title: t('screens.book.locationCard.fields.oneWay'),
                children: (
                  <View
                    style={{
                      marginLeft: 30,
                    }}
                  >
                    <RadioGroup
                      value={formType}
                      items={[
                        {
                          id: 'pickupOnly',
                          title: t(
                            'screens.book.locationCard.fields.pickupOnly',
                          ),
                        },
                        {
                          id: 'dropoffOnly',
                          title: t(
                            'screens.book.locationCard.fields.dropoffOnly',
                          ),
                        },
                      ]}
                      onChange={(item) => {
                        if (item.id) {
                          setTripType(item.id);
                        }
                      }}
                    />
                  </View>
                ),
              },
              {
                id: 'roundTripDropoffAlternate',
                title: t('screens.book.locationCard.fields.dropoff'),
              },
            ]}
            onChange={(item) => {
              if (item.id === 'oneway') {
                setTripType('pickupOnly');
              } else {
                setTripType(item.id);
              }
            }}
          />
        </View>
      </FormRow>
      {formType === 'roundTripDropoffAlternate' ? (
        <LocationForm
          formControl={dropoffForm}
          type="dropoff"
          containerStyle={{
            marginTop: 25,
            zIndex: 1,
          }}
        />
      ) : null}
      {showPrice ? null : (
        <FormRow
          style={{
            justifyContent: 'center',
          }}
        >
          <Button
            style={{
              width: 260,
              marginTop: 25,
            }}
            title={t('screens.book.locationCard.actions.getQuote')}
            disabled={disableRevealPriceAction}
            onPress={handleRevealPrice}
          />
        </FormRow>
      )}
      {showPrice ? (
        <FadeInView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 20,
              fontFamily: fontFamily.semiBold600,
              lineHeight: 25,
              marginTop: 25,
              textAlign: 'center',
              marginBottom: 25,
            }}
          >
            {t('screens.book.locationCard.pricingTitle')}
          </Text>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 1,
              borderRadius: 10,
              borderColor: colors.outlineGray,
              width: 260,
              padding: 16,
            }}
          >
            <Text
              style={{
                fontFamily: fontFamily.semiBold600,
                fontSize: 20,
                lineHeight: 25,
              }}
            >
              {t(
                `screens.book.locationCard.${
                  formType === 'dropoffOnly' || formType === 'pickupOnly'
                    ? 'oneWayTitle'
                    : 'roundTripTitle'
                }`,
              )}
            </Text>
            <Text
              style={{
                fontFamily: fontFamily.semiBold600,
                fontSize: 36,
                color: colors.llOrange,
              }}
            >
              {tripPrice ? `$${tripPrice.toFixed(2)}` : null}
            </Text>
            <Text
              style={{
                fontFamily: fontFamily.regular,
                fontSize: 16,
                lineHeight: 22,
                textAlign: 'center',
              }}
            >
              {t('screens.book.locationCard.pricingDescription')}
            </Text>
            <Button
              title={
                hasNewQuote
                  ? t('screens.book.locationCard.actions.submit')
                  : t('screens.book.locationCard.actions.submitExisting')
              }
              containerStyle={{
                width: 133,
                marginTop: 12,
              }}
              onPress={handleCardSubmit}
              loading={loading}
            />
          </View>
        </FadeInView>
      ) : null}
    </CardContainer>
  );
}
