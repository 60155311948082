import React from 'react';
import { View } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { Text } from '@rneui/themed';

import { sizing } from '../theme/theme';

interface EmptyStateProps {
  title: string;
  icon: 'search' | 'ticket' | 'car';
  height?: number;
  testID?: string;
}

export function EmptyState({
  title,
  icon,
  height = 400,
  testID = 'empty-state',
}: EmptyStateProps) {
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height,
      }}
      testID={testID}
    >
      <Icon name={icon} size={sizing.xxl} />
      <Text
        style={{
          fontSize: sizing.lg,
        }}
      >
        {title}
      </Text>
    </View>
  );
}
