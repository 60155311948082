import React, { useCallback, useEffect, useState } from 'react';

import { deleteItem, get } from '../../../../state/secureStore';

export interface AuthenticationTokenState {
  access: string;
  refresh: string;
}

export interface AuthContextState {
  token: AuthenticationTokenState;
  isLoggedIn: boolean;
  setToken: (tokens: AuthenticationTokenState) => void;
  clearToken: () => void;
}

export const AuthContext = React.createContext<AuthContextState>({
  token: null,
  isLoggedIn: false,
  setToken: (_tokens) => {},
  clearToken: () => {},
});

export function AuthContextProvider({ children }) {
  const [token, setToken] = useState<AuthenticationTokenState>({
    access: null,
    refresh: null,
  });

  // Consume existing tokens from storage
  const handleHydrateTokens = useCallback(async () => {
    const tokens = await Promise.all([get('accessToken'), get('refreshToken')]);

    setToken({
      access: tokens[0],
      refresh: tokens[1],
    });
  }, []);

  // Clear auth tokens
  const clearToken = useCallback(async () => {
    await Promise.all([deleteItem('accessToken'), deleteItem('refreshToken')]);
    setToken(null);
  }, []);

  // Consume existing tokens from storage
  useEffect(() => {
    handleHydrateTokens();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        isLoggedIn: false,
        setToken,
        clearToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
