export const en = {
  tripWizard: {
    next: 'Next',
    prev: 'Previous',
    intro: {
      title: 'Welcome, {{name}}!',
      body: 'Just a few questions before we complete your booking',
    },
    baggage: {
      title: 'Are you travelling with a checked bag?',
    },
    address: {
      pickupTitle: 'Please enter your pick up address below',
      pickupLabel: 'Pick up address',
      pickupPlaceholder: 'Enter pick up address',
      dropoffTitle: 'Please enter your drop off address below',
      dropoffLabel: 'Drop off address',
      dropoffPlaceholder: 'Enter drop off address',
      pickupPriceLabel: 'Estimated pick up price: {{price}}',
      dropoffPriceLabel: 'Estimated drop off price: {{price}}',
      sameAsPickupTitle: 'Same as pickup address',
      addressOutOfBounds:
        'Service cannot be provided if the distance is beyond a 200 mile radius from MSP',
    },
    specialRequests: {
      title: 'Do you have any special requests for this leg?',
      placeholder: 'I need help with my luggage',
      label: 'Special Request',
    },
    pickupTime: {
      title: 'Here is our suggested pick up time, but you able to change it.',
      placeholder: 'Pick up time',
    },
    priceConfirmation: {
      title: 'Please confirm your trip charges',
      subtitle: 'You wont be charged until you complete the trip',
    },
    payment: {
      title: 'Payment method',
      subtitle: 'Enter your payment method below',
    },
    existingPaymentMethod: {
      title: 'Payment method',
      subtitle: 'Choose from an existing payment method below',
    },
    actions: {
      paymentMethod: {
        deleteFailed: 'Failed to remove payment method',
        updateFailed: 'Failed to update payment method',
        updateSuccess: 'Updated payment method',
      },
    },
  },
};
