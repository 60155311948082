import React from 'react';
import { useTranslation } from 'react-i18next';

import { Overlay } from '@rneui/themed';

import { LegAddress } from '../../../api/types';
import { TripWizardCardContainer } from '../../tripWizard/TripWizardCardContainer';
import {
  AddressCard,
  AddressCardProps,
} from '../../tripWizard/cards/AddressCard';

interface EditAddressModalProps {
  open: boolean;
  iata: AddressCardProps['iata'];
  type: AddressCardProps['type'];
  addressValue?: LegAddress;
  onRequestClose: () => void;
  onConfirm: AddressCardProps['onPressNext'];
}

export function EditAddressModal({
  open,
  iata,
  type,
  addressValue,
  onRequestClose,
  onConfirm,
}: EditAddressModalProps) {
  const { t } = useTranslation();
  return (
    <Overlay
      isVisible={open}
      onRequestClose={onRequestClose}
      onBackdropPress={onRequestClose}
    >
      <TripWizardCardContainer>
        <AddressCard
          iata={iata}
          type={type}
          addressValue={addressValue}
          subtitle={<></>}
          nextButtonTitle={t('global.common.confirm')}
          onPressNext={onConfirm}
        />
      </TripWizardCardContainer>
    </Overlay>
  );
}
