import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import ConfettiCannon from 'react-native-confedi-cannon';

import { Text } from '@rneui/themed';

import { globalState } from '../../../state/globalState';
import { InstructionsLabel } from '../../trip/components/legCard/components/InstructionsLabel';
import { tripState } from '../../trip/state/tripState';
import { CardContainer } from '../components/CardContainer';
import { bookWizardState } from '../state/bookWizardState';

export function BookingConfirmedCard() {
  const { t } = useTranslation();
  const { currentTrip } = tripState.useValue();

  useEffect(() => {
    globalState.set((state) => ({
      ...state,
      showFooter: false,
    }));

    bookWizardState.set((state) => ({
      ...state,
      showBreadcrumbs: false,
    }));
  }, []);

  const departureD2DLeg = currentTrip?.segments[0]?.legs?.find(
    (n) => n.airline.iata === 'L4',
  );

  const returnD2DLeg = currentTrip?.segments[1]?.legs?.find(
    (n) => n.airline.iata === 'L4',
  );

  console.log('departureD2DLeg', departureD2DLeg);
  console.log('returnD2DLeg', returnD2DLeg);

  const departureInstructions = departureD2DLeg?.arrive.ArrivalInstructions;
  const arrivalInstructions = returnD2DLeg?.depart.ArrivalInstructions;

  return (
    <CardContainer>
      <Text h1>{t('screens.book.bookingConfirmedCard.title')}</Text>
      {departureInstructions ? (
        <InstructionsLabel
          title={t('screens.tripDetail.legCard.instructions.departureTitle')}
          instructions={departureInstructions}
        />
      ) : null}
      {arrivalInstructions ? (
        <InstructionsLabel
          title={t('screens.tripDetail.legCard.instructions.arrivalTitle')}
          instructions={arrivalInstructions}
          containerStyle={{
            marginTop: 25,
          }}
        />
      ) : null}

      <View style={{ position: 'relative', left: -250, height: 300 }}>
        <ConfettiCannon count={200} origin={{ x: -100, y: -100 }} fadeOut />
      </View>
    </CardContainer>
  );
}
