import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PickUpDetails } from '../../../api/types';
import { TimeInterval, getTimeIntervals } from '../utils/dates';
import { CardLayout } from './CardLayout';
import { TimeIntervalAutocomplete } from './autocompletes/TimeIntervalAutocomplete';

export interface PickupTimeCardProps {
  details: PickUpDetails;
  nextButtonTitle?: string;
  onPressBack?: () => void;
  onPressNext: (interval: TimeInterval) => void;
}

export function PickupTimeCard({
  details,
  nextButtonTitle,
  onPressBack,
  onPressNext,
}: PickupTimeCardProps) {
  const { t } = useTranslation();

  const [selectedInterval, setSelectedInterval] = useState<TimeInterval>();

  const handlePressNext = useCallback(
    () => onPressNext(selectedInterval),
    [selectedInterval],
  );

  const { from, to, increments } = details.offset_details;

  const intervals = useMemo(
    () => getTimeIntervals(from, to, increments),
    [from, to, increments],
  );

  const initialInterval = useMemo(
    () => intervals.find((interval) => interval.offset === details.offset),
    [intervals, details.offset],
  );

  return (
    <CardLayout
      icon="clock-o"
      testID="trip-wizard-pickup-time-card"
      title={t('screens.tripDetail.tripWizard.pickupTime.title')}
      showBackButton={!!onPressBack}
      nextButtonTitle={nextButtonTitle}
      onPressBack={onPressBack}
      onPressNext={handlePressNext}
    >
      <TimeIntervalAutocomplete
        placeholder={t('screens.tripDetail.tripWizard.pickupTime.placeholder')}
        intervals={intervals}
        initialInterval={initialInterval}
        onSelectInterval={(interval) => setSelectedInterval(interval)}
      />
    </CardLayout>
  );
}
