import React from 'react';
import { useTranslation } from 'react-i18next';

import { Overlay } from '@rneui/themed';

import { TripWizardCardContainer } from '../../tripWizard/TripWizardCardContainer';
import {
  SpecialRequestsCard,
  SpecialRequestsCardProps,
} from '../../tripWizard/cards/SpecialRequestsCard';

interface EditSpecialRequestsModalProps {
  open: boolean;
  note: SpecialRequestsCardProps['note'];
  onRequestClose: () => void;
  onConfirm: SpecialRequestsCardProps['onPressNext'];
}

export function EditSpecialRequestsModal({
  open,
  note,
  onRequestClose,
  onConfirm,
}: EditSpecialRequestsModalProps) {
  const { t } = useTranslation();
  return (
    <Overlay
      isVisible={open}
      onRequestClose={onRequestClose}
      onBackdropPress={onRequestClose}
    >
      <TripWizardCardContainer>
        <SpecialRequestsCard
          subTitle={null}
          note={note}
          nextButtonTitle={t('global.common.confirm')}
          onPressNext={onConfirm}
        />
      </TripWizardCardContainer>
    </Overlay>
  );
}
