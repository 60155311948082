import * as WelcomeCardI18n from './components/welcomeCard/i18n';

export const en = {
  // Login Screen
  username: {
    label: 'Email',
    placeholder: 'Enter Email',
  },
  password: {
    label: 'Password',
    placeholder: 'Enter Password',
  },
  loginButton: {
    label: 'Login',
  },
  register: {
    label: "Don't have an account?",
    link: 'Create Account',
  },
  // Register Screen
  login: {
    label: 'Already have an account?',
    link: 'Login',
  },
  firstName: {
    label: 'First Name',
    placeholder: 'Enter First Name',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Enter Last Name',
  },
  phoneNumber: {
    label: 'Phone Number',
    placeholder: 'Enter Phone Number',
  },
  createPassword: {
    label: 'Create Password',
    placeholder: 'Enter Password',
    minLength: 'Passwords must be at least 6 characters',
  },
  verifyPassword: {
    label: 'Retype Password',
    placeholder: 'Enter Password Again',
  },
  registerButton: {
    label: 'Create Account',
  },
  action: {
    authenticate: {
      error: 'Failed to authenticate',
    },
    register: {
      error: 'Failed to create account',
    },
  },
  validation: {
    passwordMismatch: 'Passwords must match',
  },
  ...WelcomeCardI18n.en,
};
