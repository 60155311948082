import { newRidgeState } from 'react-ridge-state';

import { QuoteResponse } from '../api/getPriceQuote';
import { AddressDirection } from '../api/types';
import { PickupDetailsUpdate } from '../api/updatePickupDetails';

export type SegmentBaggageUpdate = {
  segmentId: number;
  hasBaggage: boolean; // Don't we all?
};

export type LegIdentifiedState = {
  legId: number;
};

export type LegAddressUpdate = LegIdentifiedState & {
  direction: AddressDirection;
  update: PickupDetailsUpdate;
};

export type LegQuoteUpdate = LegIdentifiedState & {
  quote: QuoteResponse;
};

export type LegSpecialRequestUpdate = LegIdentifiedState & {
  note: string;
};

export type LegPickupTimeUpdate = LegIdentifiedState & {
  offset: number;
};

export interface TripWizardState {
  segmentBaggage: Array<SegmentBaggageUpdate>;
  address: Array<LegAddressUpdate>;
  quote: Array<LegQuoteUpdate>;
  specialRequest: Array<LegSpecialRequestUpdate>;
  pickupTime: Array<LegPickupTimeUpdate>;
}

/**
 * Tracks the intermediate state of trip updates as the user flows through the
 * TripWizard. We're not updating the API immediately to give the user a chance
 * to confirm or cancel without repercussions.
 */
export const tripWizardState = newRidgeState<TripWizardState>({
  segmentBaggage: [],
  address: [],
  quote: [],
  specialRequest: [],
  pickupTime: [],
});

export function updateLegState(
  legId: number,
  key: keyof Omit<TripWizardState, 'segmentBaggage'>,
  performUpdate: (current: TripWizardState) => TripWizardState,
): void {
  tripWizardState.set((current) => {
    const state: Array<LegIdentifiedState> = current[key];
    const filteredItems = state.filter((st) => st.legId !== legId);

    return performUpdate({
      ...current,
      [key]: filteredItems,
    });
  });
}
