import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { Text } from '@rneui/themed';

import { FormRow } from '../../../../components/forms/FormRow';
import { PasswordRevealField } from '../../../../components/forms/PasswordRevealField';
import { Select } from '../../../../components/forms/Select';
import { TextInput } from '../../../../components/forms/TextInput';
import { getPassengerTitles } from '../../api/getPassengerTitles';

export type PassengerInfoFormFields = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password?: string;
};

interface PassengerInfoFormProps {
  formControl: UseFormReturn<PassengerInfoFormFields>;
  hasAccount: boolean;
}

export function PassengerInfoForm({
  formControl,
  hasAccount,
}: PassengerInfoFormProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = formControl;

  return (
    <View>
      <Text h1>{t('screens.book.passengerInfoCard.title')}</Text>
      <FormRow
        style={{
          marginTop: 25,
          zIndex: 10,
        }}
      >
        <Controller
          name="title"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => {
            return (
              <Select
                label={t('screens.book.passengerInfoCard.fields.title')}
                data={getPassengerTitles()}
                required
                errorMessage={errors.title?.message}
                initialValue={getPassengerTitles().find(
                  (n) => n.value === field.value,
                )}
                onSelectItem={(item) => {
                  field.onChange(item.value);
                }}
              />
            );
          }}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => {
            return (
              <TextInput
                {...field}
                errorMessage={errors.firstName?.message}
                required
                label={t('screens.book.passengerInfoCard.fields.firstName')}
              />
            );
          }}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => {
            return (
              <TextInput
                {...field}
                errorMessage={errors.lastName?.message}
                required
                label={t('screens.book.passengerInfoCard.fields.lastName')}
              />
            );
          }}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="email"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => {
            return (
              <TextInput
                {...field}
                errorMessage={errors.email?.message}
                textContentType="emailAddress"
                required
                label={t('screens.book.passengerInfoCard.fields.email')}
              />
            );
          }}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => {
            return (
              <TextInput
                {...field}
                textContentType="telephoneNumber"
                required
                errorMessage={errors.phone?.message}
                label={t('screens.book.passengerInfoCard.fields.phone')}
              />
            );
          }}
        />
      </FormRow>
      <Text>{t('screens.book.passengerInfoCard.fields.phoneInfo')}</Text>
      {hasAccount ? null : (
        <>
          <Text
            style={{
              fontSize: 20,
              lineHeight: 25,
              marginTop: 25,
            }}
          >
            {t('screens.book.passengerInfoCard.saveTimeTitle')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              lineHeight: 17.6,
            }}
          >
            {t('screens.book.passengerInfoCard.fields.passwordInfo')}
          </Text>
          <FormRow>
            <Controller
              name="password"
              control={control}
              render={({ field }) => {
                return (
                  <PasswordRevealField
                    {...field}
                    errorMessage={errors.password?.message}
                    label={t('screens.book.passengerInfoCard.fields.password')}
                  />
                );
              }}
            />
          </FormRow>
        </>
      )}
    </View>
  );
}
