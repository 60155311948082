import Constants from 'expo-constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import Toast from 'react-native-toast-message';

import Icon from '@expo/vector-icons/FontAwesome';
import { Card, Text } from '@rneui/themed';
import { Elements } from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeElements,
  StripeElementsOptions,
  loadStripe,
} from '@stripe/stripe-js';

import { PlainButton } from '../../../../components/button/PlainButton';
import { useConfirmation } from '../../../../components/contexts/ConfirmationProvider';
import {
  CreatePaymentMethodForm,
  CreatePaymentMethodFormFields,
} from '../../../../components/forms/CreatePaymentMethodForm';
import {
  ExistingPaymentMethodFields,
  ExistingPaymentMethodForm,
} from '../../../../components/forms/ExistingPaymentMethodForm';
import { LoadingBus } from '../../../../components/loading/LoadingBus';
import { cardMargin, colors } from '../../../../components/theme/theme';
import { createPaymentMethodIntent } from '../../../trip/api/createPaymentMethodIntent';
import { deletePaymentMethod } from '../../../trip/api/deletePaymentMethod';
import {
  PaymentMethod,
  getPaymentMethods,
} from '../../../trip/api/getPaymentMethods';
import { setPaymentMethod } from '../../../trip/api/setPaymentMethod';

interface PaymentMethodFormProps {
  createFormControl: UseFormReturn<CreatePaymentMethodFormFields>;
  updateFormControl: UseFormReturn<ExistingPaymentMethodFields>;
  /**
   * Fires just after a successful delete
   */
  onDeletePayment?: (method: PaymentMethod) => void;
}

const { STRIPE_PUBLISHABLE_KEY } = Constants.manifest.extra;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

// Wrap with the Elements container to get into the correct context
export function PaymentMethodForm({
  createFormControl,
  updateFormControl,
  onDeletePayment,
}: PaymentMethodFormProps) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState<string>(null);
  const [paymentMethods, setPaymentMethods] =
    useState<Array<PaymentMethod>>(null);
  const [currentForm, setCurrentForm] = useState<'create' | 'set'>('create');

  // On mount determine if we have methods, or need to create a new method
  const handleMount = useCallback(async () => {
    const paymentMethods = await getPaymentMethods();
    if (paymentMethods && paymentMethods.length) {
      setPaymentMethods(paymentMethods);
      setCurrentForm('set');
    } else {
      const { secret } = await createPaymentMethodIntent();
      setClientSecret(secret);
    }
    setLoading(false);
  }, [setLoading, setClientSecret]);

  const handleSwitchToCreatePaymentMethod = useCallback(async () => {
    setLoading(true);
    const { secret } = await createPaymentMethodIntent();
    setClientSecret(secret);
    setCurrentForm('create');
    setLoading(false);
  }, [setClientSecret, setLoading, setCurrentForm]);

  const { confirm } = useConfirmation();

  // This is kind of a weird place for this, but it optimizes removal of payment methods in local state
  const handleRemovePaymentMethod = useCallback(
    async (method: PaymentMethod) => {
      try {
        await confirm({
          title: 'Delete this payment method?',
        });
      } catch (_e) {
        return;
      }

      const result = await deletePaymentMethod(method.id);
      if (!result) {
        Toast.show({
          type: 'error',
          text1: t(
            'screens.tripDetail.tripWizard.actions.paymentMethod.deleteFailed',
          ),
        });
      }

      onDeletePayment?.(method);

      const remainingPaymentMethods = paymentMethods.filter(
        (pm) => pm !== method,
      );

      if (!remainingPaymentMethods.length) {
        const { secret } = await createPaymentMethodIntent();
        setClientSecret(secret);
      }

      setPaymentMethods(paymentMethods.filter((pm) => pm !== method));
    },
    [paymentMethods, setPaymentMethods, setClientSecret],
  );

  useEffect(() => {
    handleMount();
  }, [handleMount]);

  const options: StripeElementsOptions = useMemo<StripeElementsOptions>(
    () => ({
      clientSecret,
    }),
    [clientSecret],
  );

  return (
    <>
      {loading ? (
        <LoadingBus />
      ) : currentForm === 'set' ? (
        <View>
          <ExistingPaymentMethodForm
            formControl={updateFormControl}
            onRemoveMethod={handleRemovePaymentMethod}
            paymentMethods={paymentMethods}
          />
          <Pressable
            style={{ marginRight: 20 }}
            onPress={handleSwitchToCreatePaymentMethod}
          >
            <Card
              containerStyle={{
                height: 70,
                alignItems: 'flex-start',
                justifyContent: 'center',
                borderColor: colors.llSubnavGrayLink,
                backgroundColor: colors.llLightGray,
                borderRadius: 4,
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <Icon
                  name="plus-circle"
                  size={22}
                  style={{ marginRight: 25, paddingLeft: 10 }}
                />
                <Text>
                  {t(
                    'screens.book.bookingSummaryCard.actions.addPaymentMethod',
                  )}
                </Text>
              </View>
            </Card>
          </Pressable>
        </View>
      ) : (
        <Elements stripe={stripePromise} options={options}>
          <CreatePaymentMethodForm formControl={createFormControl} />
        </Elements>
      )}
    </>
  );
}
