import React, { useCallback } from 'react';

import { PaymentElement } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

interface CheckoutFormProps {
  /**
   * Fires when every input in the form is valid and completed
   */
  onValid?: () => void;
  /**
   * Fires when any field is invalid
   */
  onInvalid?: () => void;
}

export function CheckoutForm({ onValid, onInvalid }: CheckoutFormProps) {
  const handleFieldChange = useCallback(
    (e: StripePaymentElementChangeEvent) => {
      if (e.complete) {
        return onValid();
      }
      onInvalid();
    },
    [],
  );

  return <PaymentElement onChange={handleFieldChange} />;
}
