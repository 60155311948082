import { newRidgeState } from 'react-ridge-state';

export interface FlightInfoCardState {
  form?: {
    title?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    airport?: string;
    flight?: string;
    numPassengers?: string;
    departureTime?: string;
    arrivalTime?: string;
  };
}

export const flightInfoCardState = newRidgeState<FlightInfoCardState>({
  form: {},
});
