import React, { useMemo } from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@rneui/themed';

import { LegAddress } from '../../../api/types';

interface AddressLabelProps {
  address: LegAddress;
  align?: 'left' | 'right';
}

export function AddressLabel({ address, align = 'left' }: AddressLabelProps) {
  const { theme } = useTheme();

  const addressTypeStyle = useMemo(
    () => ({
      color: theme.colors.grey2,
      textAlign: align,
    }),
    [],
  );

  return (
    <View
      style={{
        alignItems: align === 'left' ? 'flex-start' : 'flex-end',
      }}
    >
      <Text style={addressTypeStyle}>{address?.first_address_line}</Text>
      <Text style={addressTypeStyle}>{address?.second_address_line}</Text>
      <Text style={addressTypeStyle}>
        {address?.city}, {address?.state} {address?.zip_code}
      </Text>
    </View>
  );
}
