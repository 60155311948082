import React from 'react';
import { useTranslation } from 'react-i18next';

import { Overlay } from '@rneui/themed';

import { TripWizardCardContainer } from '../../tripWizard/TripWizardCardContainer';
import {
  PickupTimeCard,
  PickupTimeCardProps,
} from '../../tripWizard/cards/PickupTimeCard';

interface EditPickupTimeModalProps {
  open: boolean;
  details: PickupTimeCardProps['details'];
  onRequestClose: () => void;
  onConfirm: PickupTimeCardProps['onPressNext'];
}

export function EditPickupTimeModal({
  open,
  details,
  onRequestClose,
  onConfirm,
}: EditPickupTimeModalProps) {
  const { t } = useTranslation();
  return (
    <Overlay
      isVisible={open}
      onRequestClose={onRequestClose}
      onBackdropPress={onRequestClose}
    >
      <TripWizardCardContainer>
        <PickupTimeCard
          details={details}
          nextButtonTitle={t('global.common.confirm')}
          onPressNext={onConfirm}
        />
      </TripWizardCardContainer>
    </Overlay>
  );
}
