import { api } from '../../../api';

export interface Prediction {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

interface AutocompleteAddressResult {
  predictions: Array<Prediction>;
  status: string;
}

export async function getAutocompleteAddress(
  search: string,
  iata: string = 'MSX',
): Promise<AutocompleteAddressResult> {
  try {
    const { data } = await api.get(
      `v2/booking/autocomplete_addresses/${search}/${iata}/`,
    );
    return data;
  } catch (e) {
    return null;
  }
}
