import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { SwiperFlatList } from 'react-native-swiper-flatlist';

import { Image } from '@rneui/themed';

import { useMaxViewWidth } from '../../components/theme/theme';
import { getMarketingImages } from './api/getMarketingImages';
import { WelcomeCard } from './components/welcomeCard/WelcomeCard';

interface LoginPromptProps {
  returnScreen: string;
}

export function LoginPrompt({ returnScreen }: LoginPromptProps) {
  const containerRef = useRef(null);
  const SLIDER_WIDTH = useMaxViewWidth();
  const ITEM_WIDTH = Math.round(SLIDER_WIDTH * 0.9);
  const ITEM_HEIGHT = Math.round((ITEM_WIDTH * 3) / 4);

  const [images, setImages] = useState([]);

  useEffect(() => {
    getMarketingImages().then((marketingImages) => {
      if (!containerRef.current) {
        return;
      }
      setImages(marketingImages);
    });
  }, [containerRef]);

  const renderItem = useCallback(({ item }) => {
    return (
      <View style={{ width: SLIDER_WIDTH, alignItems: 'center' }}>
        <Image
          source={{
            uri: item.image,
          }}
          style={{ width: ITEM_WIDTH, height: ITEM_HEIGHT }}
          resizeMode="contain"
        />
      </View>
    );
  }, []);

  return (
    <View ref={containerRef}>
      <WelcomeCard returnScreen={returnScreen} />
      {images && images.length ? (
        <SwiperFlatList
          autoplay
          autoplayDelay={5}
          autoplayLoop
          autoplayLoopKeepAnimation
          showPagination={false}
          data={images}
          renderItem={renderItem}
        />
      ) : null}
    </View>
  );
}
