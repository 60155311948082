import parsePrediction from 'parse-google-autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, View } from 'react-native';

import { CheckBox, Text } from '@rneui/themed';

import { spacing } from '../../../../../components/theme/theme';
import { validAddress } from '../../../../../components/utils/address';
import { createPriceQuote } from '../../../api/createPriceQuote';
import { Prediction } from '../../../api/getAutocompleteAddress';
import { QuoteResponse } from '../../../api/getPriceQuote';
import { LegAddress } from '../../../api/types';
import { PickupDetailsUpdate } from '../../../api/updatePickupDetails';
import { tripState } from '../../../state/tripState';
import { format } from '../utils/money';
import { AirportFromToLabel } from './AirportFromToLabel';
import { CardLayout } from './CardLayout';
import { AddressAutocomplete } from './autocompletes/AddressAutocomplete';

export interface AddressCardProps {
  onPressNext: (value: PickupDetailsUpdate, quote: QuoteResponse) => void;
  onPressBack?: () => void;
  /**
   * For address lookup radius
   */
  iata: string;
  type: 'pickup' | 'dropoff';
  nextButtonTitle?: string;
  addressValue?: LegAddress;
  subtitle?: JSX.Element;
}

interface ParsedAddress {
  id: boolean;
  business: boolean;
  title: string;
  description: boolean;
  street: string;
  city: string;
  state: string;
  zip: string;
}

export function AddressCard({
  iata,
  type,
  nextButtonTitle,
  addressValue,
  subtitle,
  onPressNext,
  onPressBack,
}: AddressCardProps) {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<Prediction>();
  const [distancePrice, setDistancePrice] = useState<QuoteResponse>();
  const [loadingDistancePrice, setLoadingDistancePrice] = useState(false);
  const [sameAsPickupAddress, setSameAsPickupAddress] = useState(true);
  const isDropoff = type === 'dropoff';
  const { lastSelectedAddress, lastSelectedQuote } = tripState.get();

  const handleOnPressConfirm = useCallback(() => {
    if (isDropoff && sameAsPickupAddress) {
      onPressNext(lastSelectedAddress, lastSelectedQuote);
      return;
    }

    const data = parsePrediction([selectedItem])[0] as ParsedAddress;
    const update = {
      // TODO the API currently looks up and validates the address using the
      // full address as 'first_address_line'
      first_address_line: selectedItem.description,
      city: data.city,
      state: data.state,
    };

    tripState.set((current) => ({
      ...current,
      lastSelectedAddress: update,
      lastSelectedQuote: distancePrice,
    }));

    onPressNext(update, distancePrice);
  }, [selectedItem, distancePrice, lastSelectedAddress, lastSelectedQuote]);

  // Fetch distance price
  useEffect(() => {
    if (!selectedItem) {
      setDistancePrice(null);
      return;
    }

    setLoadingDistancePrice(true);
    createPriceQuote(
      selectedItem.description,
      type === 'pickup' ? 'pickup_address' : 'drop_off_address',
    ).then((quote) => {
      setDistancePrice(quote);
      setLoadingDistancePrice(false);
    });
  }, [selectedItem, setDistancePrice, setLoadingDistancePrice]);

  let nextButtonDisabled = !selectedItem || loadingDistancePrice;
  if (isDropoff && sameAsPickupAddress) {
    nextButtonDisabled = false;
  }

  return (
    <CardLayout
      icon="map"
      testID="trip-wizard-address-card"
      title={t(`screens.tripDetail.tripWizard.address.${type}Title`)}
      subTitle={
        subtitle || (
          <AirportFromToLabel
            style={{
              width: '50%',
              alignSelf: 'center',
              opacity: 0.5,
            }}
            iata={iata}
            type={type}
          />
        )
      }
      nextButtonDisabled={nextButtonDisabled}
      nextButtonTitle={nextButtonTitle}
      showBackButton={!!onPressBack}
      onPressBack={onPressBack}
      onPressNext={handleOnPressConfirm}
    >
      <View style={{ marginTop: spacing.md, width: '100%', zIndex: 1 }}>
        {isDropoff && lastSelectedAddress ? (
          <CheckBox
            title={t('screens.tripDetail.tripWizard.address.sameAsPickupTitle')}
            onPress={() => setSameAsPickupAddress(!sameAsPickupAddress)}
            checked={sameAsPickupAddress}
            containerStyle={{
              marginStart: 0,
              marginEnd: 0,
              marginTop: 0,
              marginBottom: 0,
              padding: 0,
            }}
          />
        ) : null}
        <AddressAutocomplete
          iata={iata}
          placeholder={t(
            `screens.tripDetail.tripWizard.address.${type}Placeholder`,
          )}
          initialValue={validAddress(addressValue) ? addressValue : null}
          disabled={isDropoff && sameAsPickupAddress}
          onSelectAddress={(value) => setSelectedItem(value)}
        />
        {loadingDistancePrice ? <ActivityIndicator /> : null}
        <Text>
          {!loadingDistancePrice && distancePrice?.priceTotal > 0
            ? t(`screens.tripDetail.tripWizard.address.${type}PriceLabel`, {
                price: format(distancePrice?.priceTotal),
              })
            : null}
        </Text>
      </View>
    </CardLayout>
  );
}
