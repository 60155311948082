import Constants from 'expo-constants';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Toast from 'react-native-toast-message';
import Wizard from 'react-native-wizard';

import { useRoute } from '@react-navigation/native';
import { Overlay } from '@rneui/themed';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import { LoadingBus } from '../../../../components/loading/LoadingBus';
import { useAuth } from '../../../auth/components/context/useAuth';
import { createLegSpecialRequest } from '../../api/createLegSpecialRequest';
import { PaymentMethod } from '../../api/getPaymentMethods';
import { QuoteResponse } from '../../api/getPriceQuote';
import { setPaymentMethod } from '../../api/setPaymentMethod';
import { AddressDirection, Trip } from '../../api/types';
import { updateLegQuote } from '../../api/updateLegQuote';
import {
  PickupDetailsUpdate,
  updatePickupDetails,
} from '../../api/updatePickupDetails';
import { updatePickupTime } from '../../api/updatePickupTime';
import { updateSegmentBaggage } from '../../api/updateSegmentBaggage';
import {
  SegmentBaggageUpdate,
  tripWizardState,
  updateLegState,
} from '../../state/tripWizardState';
import { TripWizardCardContainer } from './TripWizardCardContainer';
import { AddressCard } from './cards/AddressCard';
import { AirportFromToLabel } from './cards/AirportFromToLabel';
import { BaggageCard } from './cards/BaggageCard';
import { FinalCard } from './cards/FinalCard';
import { IntroCard } from './cards/IntroCard';
import { PaymentMethodCard } from './cards/PaymentMethodCard';
import { PickupTimeCard } from './cards/PickupTimeCard';
import { PriceConfirmationCard } from './cards/PriceConfirmationCard';
import { SpecialRequestsCard } from './cards/SpecialRequestsCard';
import { getHomeServiceLegs } from './utils/legs';

const { PUBLIC_URL, API_BASE_URL } = Constants.manifest.extra;

interface TripWizardProps {
  open: boolean;
  trip: Trip;
  filterCards?: (card: { id: string }) => boolean;
  onRequestClose: () => void;
  onRequestRefreshTrip: () => Promise<void>;
}

function allowAll(_card) {
  return true;
}

export function TripWizard({
  open,
  trip,
  filterCards = allowAll,
  onRequestClose,
  onRequestRefreshTrip,
}: TripWizardProps) {
  const wizard = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const { token } = useAuth();
  const { path } = useRoute();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // We're following the assumption that segments have a single home service per
  // leg, and that there are 2 segments.

  const departureSegmentHomeServiceLegs = getHomeServiceLegs(trip.segments[0]);
  const returnSegmentHomeServiceLegs = getHomeServiceLegs(trip.segments[1]);
  const homeServiceLegs = [
    ...departureSegmentHomeServiceLegs,
    ...returnSegmentHomeServiceLegs,
  ];

  const handleUpdateBaggage = useCallback(
    async (hasBaggage: boolean) => {
      const baggageUpdate: Array<SegmentBaggageUpdate> = [
        {
          segmentId: trip.segments[0].id,
          hasBaggage,
        },
      ];
      if (trip.segments[1]) {
        baggageUpdate.push({
          segmentId: trip.segments[1].id,
          hasBaggage,
        });
      }

      tripWizardState.set((current) => ({
        ...current,
        segmentBaggage: baggageUpdate,
      }));
      wizard.current.next();
    },
    [trip, wizard.current],
  );

  const handleUpdateAddress = useCallback(
    async (
      legId: number,
      direction: AddressDirection,
      update: PickupDetailsUpdate,
      quote: QuoteResponse,
    ) => {
      updateLegState(legId, 'address', (current) => {
        const { address } = current;

        return {
          ...current,
          address: [
            ...address,
            {
              legId,
              direction,
              update,
            },
          ],
        };
      });
      updateLegState(legId, 'quote', (current) => {
        const quoteState = current.quote;

        return {
          ...current,
          quote: [
            ...quoteState,
            {
              legId,
              quote,
            },
          ],
        };
      });
      wizard.current.next();
    },
    [],
  );

  const handleCreateSpecialRequest = useCallback(
    async (legId: number, note: string) => {
      updateLegState(legId, 'specialRequest', (current) => {
        const { specialRequest } = current;

        return {
          ...current,
          specialRequest: [
            ...specialRequest,
            {
              legId,
              note,
            },
          ],
        };
      });
      wizard.current.next();
    },
    [],
  );

  const handleUpdatePickupTime = useCallback(
    async (legId: number, offset: number) => {
      updateLegState(legId, 'pickupTime', (current) => {
        const { pickupTime } = current;

        return {
          ...current,
          pickupTime: [
            ...pickupTime,
            {
              legId,
              offset,
            },
          ],
        };
      });
      wizard.current.next();
    },
    [],
  );

  const handleCreatePaymentMethod = useCallback(
    async (elements: StripeElements, stripe: Stripe) => {
      setLoading(true);
      const returnPath = `${PUBLIC_URL}${path}`;
      const { error } = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${API_BASE_URL}/door_to_door_payments/resolve_setup_intent/?jwt=${token.access}&return=${returnPath}&reservation_id=${trip.id}`,
        },
      });
      setLoading(false);

      if (error) {
        Toast.show({
          type: 'error',
          text1: error.setup_intent?.last_setup_error?.message ?? error.message,
        });

        return false;
      }

      return true;
    },
    [setLoading],
  );

  const handleSetPaymentMethod = useCallback(
    async (method: PaymentMethod) => {
      setLoading(true);
      const result = await setPaymentMethod(trip.id, method.id);
      if (!result) {
        Toast.show({
          type: 'error',
          text1: t(
            'screens.tripDetail.tripWizard.actions.paymentMethod.updateFailed',
          ),
        });
        return false;
      }

      Toast.show({
        type: 'success',
        text1: t(
          'screens.tripDetail.tripWizard.actions.paymentMethod.updateSuccess',
        ),
      });

      setLoading(false);
      return true;
    },
    [setLoading],
  );

  const handleUpdatePaymentMethod = useCallback(
    async (
      elements?: StripeElements,
      stripe?: Stripe,
      method?: PaymentMethod,
    ) => {
      if (method) {
        return await handleSetPaymentMethod(method);
      } else {
        return await handleCreatePaymentMethod(elements, stripe);
      }
    },
    [handleSetPaymentMethod, handleCreatePaymentMethod],
  );

  const handleUpdateTrip = useCallback(async () => {
    const state = tripWizardState.get();
    // Checked baggage
    await Promise.all(
      state.segmentBaggage.map((baggageUpdate) =>
        updateSegmentBaggage(baggageUpdate.segmentId, baggageUpdate.hasBaggage),
      ),
    );
    // Address
    await Promise.all(
      state.address.map((addressUpdate) =>
        updatePickupDetails(
          addressUpdate.legId,
          addressUpdate.direction,
          addressUpdate.update,
        ),
      ),
    );
    // Special requests
    await Promise.all(
      state.specialRequest.map((specialRequestUpdate) =>
        createLegSpecialRequest(
          specialRequestUpdate.legId,
          specialRequestUpdate.note,
        ),
      ),
    );
    // Quote on leg
    await Promise.all(
      state.quote.map((quoteUpdate) =>
        updateLegQuote(quoteUpdate.legId, quoteUpdate.quote.quoteId),
      ),
    );
    // Pick up time
    await Promise.all(
      state.pickupTime.map((pickupTimeUpdate) =>
        updatePickupTime(pickupTimeUpdate.legId, pickupTimeUpdate.offset),
      ),
    );
  }, []);

  // Updates the trip and payment method
  const handleComplete = useCallback(
    async (
      elements?: StripeElements,
      stripe?: Stripe,
      method?: PaymentMethod,
    ) => {
      // Updating trip details first because the payment setup flow will cause
      // the user to redirect through the API
      await handleUpdateTrip();

      const updatedPaymentMethod = await handleUpdatePaymentMethod(
        elements,
        stripe,
        method,
      );
      // If updating fails we will toast, and keep the user on the current screen
      if (!updatedPaymentMethod) {
        return;
      }

      wizard.current.next();
    },
    [handleUpdateTrip, handleUpdatePaymentMethod],
  );

  const steps = useMemo(
    () =>
      [
        {
          id: 'intro',
          content: (
            <TripWizardCardContainer>
              <IntroCard
                onPressNext={() => wizard.current.next()}
                trip={trip}
              />
            </TripWizardCardContainer>
          ),
        },
        {
          id: 'baggage',
          content: (
            <TripWizardCardContainer>
              <BaggageCard onPressNext={handleUpdateBaggage} />
            </TripWizardCardContainer>
          ),
        },
        ...homeServiceLegs.map((leg) => [
          {
            id: `address-${leg.leg.id}`,
            content: (
              <TripWizardCardContainer key={leg.leg.id}>
                <AddressCard
                  iata={leg.airportIata}
                  type={leg.type}
                  addressValue={
                    leg.type === 'pickup'
                      ? leg.leg.pick_up_details.pick_up_address
                      : leg.leg.pick_up_details.drop_off_address
                  }
                  onPressBack={() => wizard.current.prev()}
                  onPressNext={(update, quote) => {
                    handleUpdateAddress(
                      leg.leg.id,
                      leg.type === 'pickup'
                        ? 'pickup_address'
                        : 'drop_off_address',
                      update,
                      quote,
                    );
                  }}
                />
              </TripWizardCardContainer>
            ),
          },
          {
            id: `time-interval-${leg.leg.id}`,
            content: (
              <TripWizardCardContainer key={leg.leg.id}>
                <PickupTimeCard
                  details={leg.leg.pick_up_details}
                  onPressBack={() => wizard.current.prev()}
                  onPressNext={(interval) =>
                    handleUpdatePickupTime(leg.leg.id, interval.offset)
                  }
                />
              </TripWizardCardContainer>
            ),
          },
          {
            id: `special-requests-${leg.leg.id}`,
            content: (
              <TripWizardCardContainer key={leg.leg.id}>
                <SpecialRequestsCard
                  subTitle={
                    <AirportFromToLabel
                      iata={leg.airportIata}
                      type={leg.type}
                      style={{
                        width: '50%',
                        alignSelf: 'center',
                        opacity: 0.5,
                      }}
                    />
                  }
                  onPressBack={() => wizard.current.prev()}
                  onPressNext={(note) => {
                    handleCreateSpecialRequest(leg.leg.id, note ?? '');
                  }}
                />
              </TripWizardCardContainer>
            ),
          },
        ]),
        {
          id: 'priceConfirmation',
          content: (
            <TripWizardCardContainer height={450}>
              <PriceConfirmationCard
                onPressBack={() => wizard.current.prev()}
                onPressNext={() => wizard.current.next()}
              />
            </TripWizardCardContainer>
          ),
        },
        {
          id: 'payment',
          content: (
            <TripWizardCardContainer height={470} width={600}>
              <PaymentMethodCard
                paymentMethodId={trip.payment_method}
                onPressBack={() => wizard.current.prev()}
                onPressNext={handleComplete}
              />
            </TripWizardCardContainer>
          ),
        },
        {
          id: 'final',
          content: (
            <TripWizardCardContainer>
              <FinalCard onDone={() => onRequestClose()} />
            </TripWizardCardContainer>
          ),
        },
      ]
        .flat()
        .filter(filterCards),
    [filterCards],
  );

  return (
    <Overlay isVisible={open} onBackdropPress={() => onRequestClose()}>
      <View testID="trip-wizard-overlay" style={{ position: 'relative' }}>
        <Wizard
          steps={steps}
          ref={wizard}
          activeStep={activeStep}
          currentStep={({ currentStep }) => setActiveStep(currentStep)}
          duration={850}
        />
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            display: loading ? 'flex' : 'none',
            flex: 1,
            flexGrow: 1,
            flexBasis: '100%',
            backgroundColor: 'white',
          }}
        >
          <LoadingBus />
        </View>
      </View>
      <Toast />
    </Overlay>
  );
}
