import * as AboutI18n from './about/i18n';
import * as AccountI18n from './account/i18n';
import * as AuthI18n from './auth/i18n';
import * as BookI18n from './book/i18n';
import * as TripDetailI18n from './trip/i18n';

export const en = {
  about: AboutI18n.en,
  account: AccountI18n.en,
  auth: AuthI18n.en,
  book: BookI18n.en,
  tripDetail: TripDetailI18n.en,
};
