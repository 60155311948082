import { api } from '../../../api';
import { Trip } from './types';

export async function getTripFromReservationHash(hash: string): Promise<Trip> {
  try {
    const { data } = await api.get(`v2/booking/tickets/c/${hash}/`);
    return data;
  } catch (e) {
    return null;
  }
}
