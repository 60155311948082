import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Divider, Text } from '@rneui/themed';

import { EmptyState } from '../../../../components/notFound/EmptyState';
import { cardMargin, spacing } from '../../../../components/theme/theme';
import { Driver, Vehicle } from '../../api/types';
import { DriverCard } from './DriverCard';

interface DriverSectionProps {
  driver?: Driver;
  vehicle?: Vehicle;
  testID?: string;
}

export function DriverSection({
  driver,
  vehicle,
  testID = 'driver-section',
}: DriverSectionProps) {
  const { t } = useTranslation();
  return (
    <View
      style={{
        marginTop: spacing.md,
      }}
      testID={testID}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginHorizontal: cardMargin,
        }}
      >
        <Divider style={{ flex: 1, marginRight: spacing.sm }} />
        <Text>
          {t('screens.tripDetail.driverSection.driverInformationLabel')}
        </Text>
        <Divider style={{ flex: 1, marginLeft: spacing.sm }} />
      </View>
      {driver ? (
        <DriverCard driver={driver} vehicle={vehicle} />
      ) : (
        <View style={{ marginHorizontal: cardMargin }}>
          <EmptyState
            icon="car"
            title={t('screens.tripDetail.driverSection.noDriver')}
            height={100}
            testID="driver-empty-state"
          />
        </View>
      )}
    </View>
  );
}
