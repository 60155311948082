import { api } from '../../../api';
import { Trip } from './types';

export async function getTripByPNR(
  pnr: string,
  lastName: string,
): Promise<Trip> {
  try {
    const { data } = await api.get(`v2/booking/tickets/${pnr}/?refresh=true`, {
      headers: {
        lastname: lastName,
      },
    });
    return data;
  } catch (e) {
    return null;
  }
}
