import Constants from 'expo-constants';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScrollView,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { ListItem } from '@rneui/base';

import { ExternalLink } from '../../components/button/ExternalLink';
import { colors, sizing, spacing } from '../../components/theme/theme';

const { MARKETING_SITE_URL } = Constants.manifest.extra;

const containerTheme: StyleProp<ViewStyle> = {
  backgroundColor: colors.primaryText,
  width: '100%',
};

const titleContainerTheme: StyleProp<ViewStyle> = {
  flex: 1,
  borderBottomWidth: 2,
  borderBottomColor: colors.primary,
};

const textTheme: StyleProp<TextStyle> = {
  color: colors.primary,
  width: '100%',
  paddingBottom: spacing.md,
  fontSize: sizing.lg,
  textAlign: 'left',
};

const linkTheme: StyleProp<TextStyle> = {
  color: '#fff',
  fontSize: sizing.lg,
};

const sectionLinkTheme: StyleProp<ViewStyle> = {
  paddingHorizontal: 0,
  paddingVertical: 0,
};

export function AboutScreen() {
  const { t } = useTranslation();
  const [openItemIndex, setOpenItemIndex] = useState(-1);
  const handleToggleOpenItem = useCallback(
    (index: number) => {
      setOpenItemIndex(openItemIndex === index ? -1 : index);
    },
    [setOpenItemIndex, openItemIndex],
  );

  return (
    <ScrollView
      style={[
        containerTheme,
        {
          flex: 1,
          height: '100%',
          width: '100%',
        },
      ]}
    >
      <ListItem.Accordion
        isExpanded={openItemIndex === 0}
        onPress={() => handleToggleOpenItem(0)}
        content={
          <View style={titleContainerTheme}>
            <ListItem.Title style={[textTheme]}>
              {t('screens.about.sections.landline.title')}
            </ListItem.Title>
          </View>
        }
        icon={<Icon name="chevron-down" color="#fff" size={sizing.md} />}
        containerStyle={containerTheme}
      >
        <ListItem containerStyle={containerTheme}>
          <ListItem.Content>
            <ExternalLink
              title={t('screens.about.sections.landline.links.aboutUs')}
              url={`${MARKETING_SITE_URL}/about-us/`}
              titleStyle={[linkTheme]}
            />
            <ExternalLink
              title={t('screens.about.sections.landline.links.news')}
              url={`${MARKETING_SITE_URL}/news/`}
              titleStyle={[linkTheme]}
            />
            <ExternalLink
              title={t('screens.about.sections.landline.links.howItWorks')}
              url={`${MARKETING_SITE_URL}/how-it-works/`}
              titleStyle={[linkTheme]}
            />
            <ExternalLink
              title={t('screens.about.sections.landline.links.faqs')}
              url={`${MARKETING_SITE_URL}/faq/`}
              titleStyle={[linkTheme]}
            />
            <ExternalLink
              title={t('screens.about.sections.contact.title')}
              url={`${MARKETING_SITE_URL}/contact/`}
              titleStyle={[linkTheme]}
            />
          </ListItem.Content>
        </ListItem>
      </ListItem.Accordion>
      <ListItem containerStyle={containerTheme}>
        <View style={titleContainerTheme}>
          <ListItem.Title style={{ width: '100%' }}>
            <ExternalLink
              title={t('screens.about.sections.partnerships.title')}
              url={`${MARKETING_SITE_URL}/partnerships/`}
              titleStyle={textTheme}
              buttonStyle={[sectionLinkTheme]}
              containerStyle={containerTheme}
            />
          </ListItem.Title>
        </View>
      </ListItem>
      <ListItem containerStyle={containerTheme}>
        <View style={titleContainerTheme}>
          <ListItem.Title style={{ width: '100%' }}>
            <ExternalLink
              title={t('screens.about.sections.schedulesAndFares.title')}
              url={`${MARKETING_SITE_URL}/schedules&fares/`}
              titleStyle={textTheme}
              buttonStyle={[sectionLinkTheme]}
              containerStyle={containerTheme}
            />
          </ListItem.Title>
        </View>
      </ListItem>
      <ListItem containerStyle={containerTheme}>
        <View style={titleContainerTheme}>
          <ListItem.Title style={{ width: '100%' }}>
            <ExternalLink
              title={t('screens.about.sections.locations.title')}
              url={`${MARKETING_SITE_URL}/locations/`}
              titleStyle={textTheme}
              buttonStyle={[sectionLinkTheme]}
              containerStyle={containerTheme}
            />
          </ListItem.Title>
        </View>
      </ListItem>
      <ListItem.Accordion
        isExpanded={openItemIndex === 1}
        onPress={() => handleToggleOpenItem(1)}
        content={
          <View style={titleContainerTheme}>
            <ListItem.Title style={[textTheme]}>
              {t('screens.about.sections.legal.title')}
            </ListItem.Title>
          </View>
        }
        icon={<Icon name="chevron-down" color="#fff" size={sizing.md} />}
        containerStyle={containerTheme}
      >
        <ListItem containerStyle={containerTheme}>
          <ListItem.Content>
            <ExternalLink
              title={t('screens.about.sections.legal.links.terms')}
              url={`${MARKETING_SITE_URL}/terms&conditions/`}
              titleStyle={[linkTheme]}
            />
            <ExternalLink
              title={t('screens.about.sections.legal.links.privacy')}
              url={`${MARKETING_SITE_URL}/privacy-policy/`}
              titleStyle={[linkTheme]}
            />
          </ListItem.Content>
        </ListItem>
      </ListItem.Accordion>
      <ListItem containerStyle={containerTheme}>
        <View style={titleContainerTheme}>
          <ListItem.Title style={{ width: '100%' }}>
            <ExternalLink
              title={t('screens.about.sections.contact.title')}
              url={`${MARKETING_SITE_URL}/contact/`}
              titleStyle={textTheme}
              buttonStyle={[sectionLinkTheme]}
              containerStyle={containerTheme}
            />
          </ListItem.Title>
        </View>
      </ListItem>
    </ScrollView>
  );
}
