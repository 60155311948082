import base64 from 'react-native-base64';

import { get } from '../../../state/secureStore';
import { getTripByPNR } from './getTripByPNR';
import { Trip } from './types';

/**
 * Fetches the current trip by pnr/last name in state
 */
export async function fetchCurrentTrip(): Promise<Trip> {
  try {
    const [currentPnr, lastName] = await Promise.all([
      get('currentPnr'),
      get('lastname'),
    ]);

    const trip = await getTripByPNR(currentPnr, base64.decode(lastName));
    return trip;
  } catch (e) {
    return null;
  }
}
