import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeElements,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';

export type CreatePaymentMethodFormFields = {
  stripeInput?: any;
  stripeElements?: StripeElements;
  stripeControl?: Stripe;
};

interface CreatePaymentMethodFormProps {
  formControl: UseFormReturn<CreatePaymentMethodFormFields>;
}

export function CreatePaymentMethodForm({
  formControl,
}: CreatePaymentMethodFormProps) {
  const elements = useElements();
  const stripe = useStripe();

  const handleFieldChange = useCallback(
    (e: StripePaymentElementChangeEvent) => {
      if (e.complete) {
        formControl.setValue('stripeElements', elements);
        formControl.setValue('stripeControl', stripe);

        return formControl.clearErrors();
      }
      formControl.setError('stripeInput', new Error('Stripe invalid'));
    },
    [elements, stripe],
  );

  return <PaymentElement onChange={handleFieldChange} />;
}
