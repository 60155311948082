import { uglify } from 'phone-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Modal, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import {
  colors,
  layoutRegions,
  sizing,
  spacing,
  useMaxViewWidth,
} from '../theme/theme';
import { PlainButton, PlainButtonProps } from './PlainButton';
import { SecondaryButton } from './SecondaryButton';

interface ContactButtonProps {
  phone: string;
  title: string;
  triggerButtonProps?: PlainButtonProps;
}

export function ContactButton({
  phone,
  title,
  triggerButtonProps,
}: ContactButtonProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const maxWidth = useMaxViewWidth();
  const { t } = useTranslation();

  return (
    <View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <SafeAreaView
          style={[
            layoutRegions.bottomCentered,
            {
              width: '100%',
              height: '100%',
              backgroundColor: colors.transparentOverlay,
            },
          ]}
        >
          <View
            style={{
              backgroundColor: 'white',
              width: maxWidth,
              padding: spacing.md,
              borderRadius: sizing.sm,
              flexDirection: 'row',
            }}
          >
            <PlainButton
              hideBorder
              icon={{
                name: 'phone',
                type: 'font-awesome',
                size: sizing.md,
              }}
              iconContainerStyle={{
                marginTop: spacing.sm,
              }}
              onPress={() => Linking.openURL(`tel://${uglify(phone)}`)}
              title={`${t('global.common.call')} ${phone}`}
            />
          </View>
          <View
            style={{
              width: maxWidth,
              padding: spacing.md,
              borderRadius: sizing.sm,
            }}
          >
            <SecondaryButton
              title={t('global.common.close')}
              onPress={() => setModalVisible(false)}
            />
          </View>
        </SafeAreaView>
      </Modal>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <PlainButton
          title={title}
          onPress={() => setModalVisible(true)}
          icon={{
            name: 'phone',
            type: 'font-awesome',
            size: sizing.md,
          }}
          iconContainerStyle={{
            marginTop: spacing.sm,
          }}
          {...triggerButtonProps}
        />
      </View>
    </View>
  );
}
