import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg viewBox="0 0 22 22" fill="#000" {...props}>
    <Path
      fill="#000"
      d="M8.525 16.225h1.1l2.75-4.4H15.4a.824.824 0 1 0 0-1.65h-3.025l-2.75-4.4h-1.1l1.375 4.4H6.875l-.825-1.1h-.825L5.775 11l-.55 1.925h.825l.825-1.1H9.9l-1.375 4.4Z"
    />
  </Svg>
);

export default SvgComponent;
