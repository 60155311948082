import React from 'react';

import { PaymentMethod } from '../../../../api/getPaymentMethods';
import { AmexCardImage } from './AmexCardImage';
import { DiscoverCardImage } from './DiscoverCardImage';
import { MasterCardImage } from './MasterCardImage';
import { VisaCardImage } from './VisaCardImage';

export function VendorCardImage({
  paymentMethod,
}: {
  paymentMethod: PaymentMethod;
}) {
  const scale = 3;
  switch (paymentMethod.vendor) {
    case 'amex':
      return <AmexCardImage scale={scale} />;
    case 'discover':
      return <DiscoverCardImage scale={scale} />;
    case 'mastercard':
      return <MasterCardImage scale={scale} />;
    case 'visa':
      return <VisaCardImage scale={scale} />;
  }

  return null;
}
