import { AxiosError } from 'axios';

import { api } from '../../../api';

interface CreateUserParams {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface CreateUserResult {
  access: string;
  refresh: string;
}

export interface CreateUserValidationErrors {
  email?: Array<string>;
  password?: Array<string>;
  first_name?: Array<string>;
  last_name?: Array<string>;
  phone_number?: Array<string>;
}

export interface CreateUserValidationErrorResult {
  validation_error: CreateUserValidationErrors;
}

export async function createUser(
  userParams: CreateUserParams,
): Promise<CreateUserResult | CreateUserValidationErrorResult> {
  try {
    const { data } = await api.post('/users/', {
      email: userParams.username,
      password: userParams.password,
      first_name: userParams.firstName,
      last_name: userParams.lastName,
      phone_number: userParams.phoneNumber,
      subscribe_to_newsletter: false,
    });

    return {
      access: data.access,
      refresh: data.refresh,
    };
  } catch (e) {
    const networkError = e as AxiosError;
    if (networkError.response && networkError.response.data) {
      return networkError.response.data as CreateUserValidationErrorResult;
    }

    return null;
  }
}
