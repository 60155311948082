import { Trip } from '../../screens/trip/api/types';
import { getServiceLabel } from '../../screens/trip/components/legCard/components/AirportLabel';
import { PriceItem } from '../../screens/trip/components/priceCard/PriceCard';

export function getPriceItems(trip: Trip): Array<PriceItem> {
  return trip?.segments
    ?.map((segment) => {
      return segment.legs.map((leg) => {
        if (!leg.quote) {
          return null;
        }

        const item: PriceItem = {
          label: `${getServiceLabel(
            leg.depart.iata,
            leg.depart.AirportName,
          )} → ${getServiceLabel(leg.arrive.iata, leg.arrive.AirportName)}`,
          price: leg.quote.total_price_rounded,
        };

        return item;
      });
    })
    .flat()
    .filter((n) => !!n);
}
