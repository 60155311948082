import React from 'react';
import { View } from 'react-native';

import { GoogleMap } from '../../../../components/googleMap/GoogleMap';
import { cardMargin, spacing } from '../../../../components/theme/theme';
import { checkIfMissingAddress } from '../../../../components/utils/address';
import { refreshCurrentTrip } from '../../api/refreshCurrentTrip';
import { LEG_SERVICE_TYPE, SEGMENT_TYPE, Segment } from '../../api/types';
import { CheckedBagInput } from '../checkedBagInput/CheckedBagInput';
import { DriverSection } from '../driverSection/DriverSection';
import { LegCard } from '../legCard/LegCard';
import { LEG_FLIGHT_STATUS } from '../legCard/components/LegStatus';
import { SegmentSectionHeader } from './SegmentSectionHeader';

interface SegmentSectionProps {
  segment: Segment;
  pnr: string;
  type: SEGMENT_TYPE;
}

export function SegmentSection({ segment, pnr, type }: SegmentSectionProps) {
  const door2doorPickupLeg = segment.legs.find(
    (leg) =>
      leg.pick_up_details && leg.service_type === LEG_SERVICE_TYPE.SELECT,
  );

  return (
    <View>
      <View
        style={{
          paddingHorizontal: cardMargin,
          marginTop: spacing.xxl,
        }}
      >
        <SegmentSectionHeader pnr={pnr} type={type} segment={segment} />
      </View>
      <CheckedBagInput
        segment={segment}
        onUpdateBaggage={() => refreshCurrentTrip()}
      />
      {segment.legs.map((leg, i) => {
        let status = LEG_FLIGHT_STATUS.ON_TIME;

        if (
          checkIfMissingAddress(leg) &&
          leg.service_type === LEG_SERVICE_TYPE.SELECT
        ) {
          status = LEG_FLIGHT_STATUS.MISSING_ADDRESS;
        }

        return (
          <LegCard
            key={leg.id}
            leg={leg}
            status={status}
            layoverTime={segment.layover_times[i]}
          />
        );
      })}
      <GoogleMap
        pickUpAddress={door2doorPickupLeg?.pick_up_details.pick_up_address}
        dropOffAddress={door2doorPickupLeg?.pick_up_details.drop_off_address}
        containerProps={{
          style: {
            height: 270,
            width: '100%',
            paddingLeft: cardMargin,
            paddingRight: cardMargin,
            marginTop: cardMargin,
          },
        }}
      />
      <DriverSection
        driver={door2doorPickupLeg?.driver}
        vehicle={door2doorPickupLeg?.vehicle}
      />
      {/* <PriceCard
        items={[
          {
            label: 'Leg price',
            price: 19.99,
          },
        ]}
      /> */}
    </View>
  );
}
