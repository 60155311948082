import { Text } from '@rneui/themed';

import { colors, landlineTheme } from '../theme/theme';

interface RequiredLabelProps {
  title: string;
}

export function RequiredLabel({ title }: RequiredLabelProps) {
  return (
    <Text
      style={{
        // @ts-ignore - `labelStyle` definitely exists
        ...landlineTheme.Input.labelStyle,
        height: 15,
        position: 'relative',
        top: -4,
      }}
    >
      {title}
      <Text style={{ color: colors.llYellow }}>*</Text>
    </Text>
  );
}
