import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';

type NativeKeyPressHandler = (
  e: NativeSyntheticEvent<TextInputKeyPressEventData>,
) => NativeSyntheticEvent<TextInputKeyPressEventData>;

export function useSubmitOnEnter(
  onEnter: CallableFunction,
): NativeKeyPressHandler {
  return (e) => {
    if (e.nativeEvent.key !== 'Enter') {
      return true;
    }

    return onEnter(e);
  };
}
