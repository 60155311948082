import { format as formatDate, parseISO } from 'date-fns';
import Constants from 'expo-constants';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Toast from 'react-native-toast-message';

import Icon from '@expo/vector-icons/FontAwesome';
import { Button, CheckBox, Text } from '@rneui/themed';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import { CreatePaymentMethodFormFields } from '../../../components/forms/CreatePaymentMethodForm';
import { ExistingPaymentMethodFields } from '../../../components/forms/ExistingPaymentMethodForm';
import { LoadingBus } from '../../../components/loading/LoadingBus';
import { colors, fontFamily } from '../../../components/theme/theme';
import { format } from '../../../components/utils/address';
import { globalState } from '../../../state/globalState';
import { useAuth } from '../../auth/components/context/useAuth';
import { PaymentMethod } from '../../trip/api/getPaymentMethods';
import { setPaymentMethod } from '../../trip/api/setPaymentMethod';
import { tripState } from '../../trip/state/tripState';
import { CardContainer } from '../components/CardContainer';
import { SummaryRow } from '../components/SummaryRow';
import { bookWizardState } from '../state/bookWizardState';
import { PaymentMethodForm } from './forms/PaymentMethodForm';

interface BookingSummaryCardProps {
  onPressNext: () => void;
}

const { PUBLIC_URL, API_BASE_URL } = Constants.manifest.extra;

export function BookingSummaryCard({ onPressNext }: BookingSummaryCardProps) {
  const { t } = useTranslation();
  const { token } = useAuth();

  const [loading, setLoading] = useState(false);
  const createPaymentFormControl = useForm<CreatePaymentMethodFormFields>();
  const updatePaymentFormControl = useForm<ExistingPaymentMethodFields>();

  const [showPaymentCapture, setShowPaymentCapture] = useState(true);
  const [acceptPromotionalEmail, setAcceptPromotionalEmail] = useState(true);
  const { currentTrip } = tripState.useValue();

  const path = `/book/${currentTrip?.rloc}/complete`;

  const departureD2DLeg = currentTrip?.segments[0]?.legs?.find(
    (n) => n.airline.iata === 'L4',
  );
  const airlineDepartLeg = currentTrip?.segments[0]?.legs?.find(
    (n) => n.airline.iata !== 'L4',
  );

  const returnD2DLeg = currentTrip?.segments[1]?.legs?.find(
    (n) => n.airline.iata === 'L4',
  );
  const airlineReturnLeg = currentTrip?.segments[1]?.legs?.find(
    (n) => n.airline.iata !== 'L4',
  );

  // Update footer/breadcrumbs
  useEffect(() => {
    globalState.set((state) => ({
      ...state,
      showFooter: false,
    }));

    bookWizardState.set((state) => ({
      ...state,
      showBreadcrumbs: true,
    }));
  }, []);

  const handleCreatePaymentMethod = useCallback(
    async (elements: StripeElements, stripe: Stripe) => {
      if (!currentTrip) {
        return;
      }

      const returnPath = `${PUBLIC_URL}${path}`;
      const { error } = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${API_BASE_URL}/door_to_door_payments/resolve_setup_intent/?jwt=${token.access}&reservation_id=${currentTrip.id}&return=${returnPath}`,
        },
      });

      if (error) {
        return error?.message;
      }

      return true;
    },
    [currentTrip],
  );

  const handleSetPaymentMethod = useCallback(
    async (method: PaymentMethod) => {
      if (!currentTrip) {
        return;
      }

      const result = await setPaymentMethod(currentTrip.id, method.id);
      if (!result) {
        return t(
          'screens.tripDetail.tripWizard.actions.paymentMethod.updateFailed',
        );
      }

      return true;
    },
    [currentTrip],
  );

  // Updates the trip and payment method
  const handleComplete = useCallback(async () => {
    setLoading(true);
    const [createFormFields, updateFormFields] = await Promise.all([
      new Promise<CreatePaymentMethodFormFields>((resolve) => {
        createPaymentFormControl.handleSubmit(resolve)();
      }),
      new Promise<ExistingPaymentMethodFields>((resolve) => {
        updatePaymentFormControl.handleSubmit(resolve)();
      }),
    ]);

    let result;
    if (createFormFields?.stripeControl) {
      result = await handleCreatePaymentMethod(
        createFormFields.stripeElements,
        createFormFields.stripeControl,
      );
    } else if (updateFormFields.paymentMethod) {
      result = await handleSetPaymentMethod(updateFormFields.paymentMethod);
    }
    if (result !== true) {
      Toast.show({
        text1: result ?? 'Failed to update payment method',
        type: 'error',
      });
    } else {
      Toast.show({
        text1: 'Successfully updated payment method',
        type: 'success',
      });
      onPressNext();
    }
  }, [handleCreatePaymentMethod, handleSetPaymentMethod]);

  if (!currentTrip) {
    return (
      <CardContainer>
        <LoadingBus />
      </CardContainer>
    );
  }

  const departureD2DPrice = departureD2DLeg?.quote.total_price_rounded;
  const returnD2DPrice = returnD2DLeg
    ? returnD2DLeg?.quote?.total_price_rounded ?? departureD2DPrice
    : 0;

  const subtotal = departureD2DPrice + returnD2DPrice;
  const departureD2DAddress = format(
    departureD2DLeg.pick_up_details.pick_up_address,
  );
  const returnD2DAddress = returnD2DLeg
    ? format(returnD2DLeg.pick_up_details.drop_off_address)
    : '';

  return (
    <CardContainer>
      <Text h1>{t('screens.book.bookingSummaryCard.title')}</Text>
      <View
        style={{
          display: loading ? 'flex' : 'none',
        }}
      >
        <LoadingBus />
      </View>
      <View
        style={{
          display: loading ? 'none' : 'flex',
        }}
      >
        <SummaryRow
          from={departureD2DAddress}
          to={airlineDepartLeg?.depart.iata}
          passengerCount={currentTrip?.passengers.length}
          price={`$${departureD2DPrice.toFixed(2)}`}
          time={formatDate(
            parseISO(departureD2DLeg.departure_time),
            'MM/dd/yy hh:mm a',
          )}
          title={t('screens.book.bookingSummaryCard.departureTitle')}
          border
        />
        {airlineReturnLeg ? (
          <SummaryRow
            to={returnD2DAddress}
            from={airlineReturnLeg?.arrive.iata}
            passengerCount={currentTrip?.passengers.length}
            price={`$${returnD2DPrice.toFixed(2)}`}
            time={formatDate(
              parseISO(returnD2DLeg?.departure_time),
              'MM/dd/yy hh:mm a',
            )}
            title={t('screens.book.bookingSummaryCard.returnTitle')}
          />
        ) : null}
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View />
          <View
            style={{
              borderTopWidth: 1,
              borderTopColor: colors.primaryGray,
              width: 200,
              paddingTop: 20,
              paddingBottom: 25,
            }}
          >
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Text>{t('screens.book.bookingSummaryCard.subtotal')}</Text>
              <Text style={{ fontFamily: fontFamily.bold }}>
                ${subtotal?.toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: colors.primaryGray,
            justifyContent: 'space-between',
            flexDirection: 'row',
            paddingVertical: 10,
            marginBottom: 25,
          }}
        >
          <Text style={{ fontSize: 20, fontFamily: fontFamily.semiBold600 }}>
            {t('screens.book.bookingSummaryCard.total')} ($)
          </Text>
          <Text style={{ fontSize: 20, fontFamily: fontFamily.semiBold600 }}>
            ${subtotal?.toFixed(2)}
          </Text>
        </View>
        {!showPaymentCapture ? (
          <View style={{ alignItems: 'center' }}>
            <Button
              containerStyle={{ width: 265 }}
              title={t(
                'screens.book.bookingSummaryCard.actions.showPaymentCapture',
              )}
              onPress={() => setShowPaymentCapture(true)}
            />
          </View>
        ) : (
          <View style={{ marginTop: 25 }}>
            <Text h1>
              {t('screens.book.bookingSummaryCard.paymentInfoTitle')}
            </Text>
            <PaymentMethodForm
              createFormControl={createPaymentFormControl}
              updateFormControl={updatePaymentFormControl}
            />
            <Text
              style={{
                fontFamily: fontFamily.bold,
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {t('screens.book.bookingSummaryCard.paymentLegal')}
            </Text>
            <CheckBox
              title={t(
                'screens.book.bookingSummaryCard.promotionalEmailConfirmation',
              )}
              uncheckedIcon={<Icon name="square-o" size={24} />}
              checkedIcon={<Icon name="check-square-o" size={24} />}
              textStyle={{
                fontFamily: fontFamily.bold,
                fontSize: 14,
                lineHeight: 16.8,
                margin: 0,
              }}
              containerStyle={{ marginTop: 10 }}
              onPress={() => setAcceptPromotionalEmail(!acceptPromotionalEmail)}
              checked={acceptPromotionalEmail}
            />
            <Button
              title={t('screens.book.bookingSummaryCard.actions.submit')}
              containerStyle={{
                width: 300,
                alignSelf: 'center',
                marginTop: 25,
              }}
              onPress={handleComplete}
            />
          </View>
        )}
      </View>
    </CardContainer>
  );
}
