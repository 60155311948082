import { newRidgeState } from 'react-ridge-state';

import { QuoteResponse } from '../api/getPriceQuote';
import { Trip } from '../api/types';
import { PickupDetailsUpdate } from '../api/updatePickupDetails';

export interface TripState {
  currentTrip: Trip;
  upcomingTrips: Array<Trip>;
  pastTrips: Array<Trip>;
  lastSelectedAddress: PickupDetailsUpdate;
  lastSelectedQuote: QuoteResponse;
  tripQuotes: Array<QuoteResponse>;
}

// TODO split out state
export const tripState = newRidgeState<TripState>({
  // Trip Detail
  currentTrip: null,
  // Trip tabs
  upcomingTrips: [],
  pastTrips: [],
  // Trip Wizard
  lastSelectedAddress: null,
  lastSelectedQuote: null,
  tripQuotes: [],
});
