import { LEG_SERVICE_TYPE } from '../../../api/types';
import AirplaneIcon from '../icons/AirplaneIcon';
import BlackCarIcon from '../icons/BlackCarIcon';
import BusIcon from '../icons/BusIcon';

const serviceTypeToIcon = {
  [LEG_SERVICE_TYPE.AIRPLANE]: AirplaneIcon,
  [LEG_SERVICE_TYPE.BUS]: BusIcon,
  [LEG_SERVICE_TYPE.SELECT]: BlackCarIcon,
  [LEG_SERVICE_TYPE.EXPRESS]: BlackCarIcon,
  [LEG_SERVICE_TYPE.EXPRESS_HOME_SERVICE]: BlackCarIcon,
};

interface ServiceIconProps {
  serviceType: LEG_SERVICE_TYPE;
  height: number;
  width: number;
}

export function ServiceIcon({ serviceType, height, width }: ServiceIconProps) {
  const Icon = serviceTypeToIcon[serviceType];

  return <Icon height={height} width={width} />;
}
