import React from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from '@rneui/themed';

const lightLogo = require('../../../assets/landline-logo-light.png');
const darkLogo = require('../../../assets/landline-logo-dark.png');

interface LandlineLogoProps {
  light?: boolean;
}

export function LandlineLogo({ light }: LandlineLogoProps) {
  const { t } = useTranslation();

  return (
    <Image
      source={light ? lightLogo : darkLogo}
      accessibilityLabel={t('global.landline.logo')}
      style={{
        width: 147,
        height: 24,
      }}
    />
  );
}
