import { api } from '../../../api';
import { AuthenticateResult } from './authenticate';

interface UpgradeUserParams {
  phone_number: string;
  password: string;
}

export async function upgradeHashUserAccount({
  phone_number,
  password,
}: UpgradeUserParams): Promise<AuthenticateResult> {
  const { data } = await api.put('v2/accounts/password/add/', {
    phone_number,
    new_password: password,
    repeated_password: password,
  });

  return data;
}
