import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { Card, Text, useTheme } from '@rneui/themed';

import { sizing, spacing } from '../../../../components/theme/theme';
import { Leg } from '../../api/types';
import { InstructionsLabel } from './components/InstructionsLabel';
import { LegLocation } from './components/LegLocation';
import { LEG_FLIGHT_STATUS, LegStatus } from './components/LegStatus';
import { ServiceIcon } from './components/ServiceIcon';
import { SpecialRequestNotes } from './components/SpecialRequestNotes';

interface LegCardProps {
  status: LEG_FLIGHT_STATUS;
  leg: Leg;
  layoverTime?: string;
}

export function LegCard({ status, leg, layoverTime }: LegCardProps) {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const hasDepartureInstructions = leg.depart.ArrivalInstructions?.length;
  const hasArrivalInstructions = leg.arrive.ArrivalInstructions?.length;

  return (
    <Card>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ServiceIcon
            serviceType={leg.service_type}
            height={sizing.xl}
            width={sizing.xl}
          />
          <Text>{leg.airline.name}</Text>
        </View>
        <LegStatus status={status} />
      </View>
      <Card.Divider style={{ marginTop: spacing.sm }} />
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View style={{ width: 150, alignSelf: 'flex-start' }}>
          <LegLocation
            legId={leg.id}
            editable={leg.is_editable}
            airportIata={leg.depart.iata}
            airportName={leg.depart.AirportName}
            date={new Date(leg.departure_time)}
            address={leg.pick_up_details?.pick_up_address}
            details={leg.pick_up_details}
            quote={leg.quote}
            isDeparture
          />
        </View>

        <Icon
          name="arrow-right"
          size={sizing.lg}
          color={theme.colors.primary}
        />

        <View style={{ width: 150, alignSelf: 'flex-start' }}>
          <LegLocation
            legId={leg.id}
            editable={leg.is_editable}
            airportIata={leg.arrive.iata}
            airportName={leg.arrive.AirportName}
            date={new Date(leg.arrival_time)}
            address={leg.pick_up_details?.drop_off_address}
            details={leg.pick_up_details}
            isDeparture={false}
            layoverTime={layoverTime}
            quote={leg.quote}
          />
        </View>
      </View>
      {hasDepartureInstructions || hasArrivalInstructions ? (
        <Card.Divider style={{ marginTop: spacing.md }} />
      ) : null}
      {hasDepartureInstructions ? (
        <InstructionsLabel
          title={t('screens.tripDetail.legCard.instructions.departureTitle')}
          instructions={leg.depart.ArrivalInstructions}
        />
      ) : null}
      {hasArrivalInstructions ? (
        <InstructionsLabel
          title={t('screens.tripDetail.legCard.instructions.arrivalTitle')}
          instructions={leg.arrive.ArrivalInstructions}
          containerStyle={
            hasDepartureInstructions ? { marginTop: spacing.md } : null
          }
        />
      ) : null}
      <Card.Divider style={{ marginTop: spacing.md }} />
      <SpecialRequestNotes legID={leg.id} notes={leg.notes} />
    </Card>
  );
}
