import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';

import { createTopTabNavigator } from '../components/navigators/TopTabNavigator';
import { colors, sizing, spacing } from '../components/theme/theme';
import { AboutScreen } from '../screens/about/AboutScreen';
import { AccountScreen } from '../screens/account/Account';
import { LoginScreen } from '../screens/auth/Login';
import { RegisterScreen } from '../screens/auth/Register';
import { BookScreen } from '../screens/book/BookScreen';
import { MyTripsScreen } from '../screens/trip/MyTrips';
import { ReservationIngestScreen } from '../screens/trip/Reservation';
import { TripDetailScreen } from '../screens/trip/TripDetail';
import { useUserProfile } from './hooks/useUserProfile';

const Tab = createTopTabNavigator();

export function MainRoute() {
  const { t } = useTranslation();
  const initialRouteName = useMemo(() => {
    const path = (window?.location.pathname ?? '').substring(1);
    const [route] = path.split('/');
    return route;
  }, []);

  // Will potentially cause the user to logout if their token is invalid, but that
  // should only matter in the case that they are trying to access a protected
  // route
  useUserProfile();

  return (
    <Tab.Navigator
      initialRouteName={initialRouteName ?? 'my-trips'}
      backBehavior="history"
      screenOptions={{
        headerStyle: {
          backgroundColor: colors.primary,
        },
        headerTitleAlign: 'center',
        tabBarBackground: () => (
          <View
            style={{ height: '100%', backgroundColor: colors.primaryText }}
          />
        ),
        tabBarStyle: { paddingBottom: spacing.md },
        tabBarActiveTintColor: colors.primary,
      }}
    >
      <Tab.Screen
        name="account"
        component={AccountScreen}
        options={{
          title: t('routes.account'),
          headerTitle: t('routes.accountTab'),
          tabBarLabel: t('routes.accountTab'),
          tabBarTestID: 'account-tab-navigation',
          tabBarIcon: () => (
            <Icon name="user-circle" size={sizing.lg} color="white" />
          ),
        }}
      />
      <Tab.Screen
        name="my-trips"
        component={MyTripsScreen}
        options={{
          title: t('routes.tripsTab'),
          tabBarLabel: t('routes.tripsTab'),
          tabBarTestID: 'my-trips-tab-navigation',
          tabBarIcon: () => (
            <Icon name="ticket" size={sizing.lg} color="white" />
          ),
        }}
      />
      <Tab.Screen
        name="about"
        component={AboutScreen}
        options={{
          title: t('routes.about'),
          tabBarLabel: t('routes.aboutTab'),
          tabBarTestID: 'about-tab-navigation',
          tabBarIcon: () => (
            <Icon name="plus-circle" size={sizing.lg} color="white" />
          ),
        }}
      />
      <Tab.Screen
        name="book"
        component={BookScreen}
        options={{
          title: t('routes.book'),
          tabBarLabel: '',
          tabBarTestID: 'book-tab-navigation',
          tabBarIcon: () => (
            <Icon name="plus-circle" size={sizing.lg} color="white" />
          ),
        }}
      />

      {/* Routes not accessible via tab navigation */}
      {/* This is a test screen which lets you jump into specific screens
      <Tab.Screen
        name="test"
        component={TestScreen}
        options={{
          tabBarIcon: () => (
            <Icon name="user-circle" size={sizing.lg} color="white" />
          ),
        }}
      /> */}
      <Tab.Screen
        name="register"
        component={RegisterScreen}
        options={{
          title: t('routes.register'),
          hideTabButton: true,
        }}
      />
      <Tab.Screen
        name="reservation"
        component={ReservationIngestScreen}
        options={{
          title: t('routes.reservation'),
          hideTabButton: true,
        }}
      />
      <Tab.Screen
        name="trip-details"
        component={TripDetailScreen}
        options={{
          title: t('routes.tripDetailTab'),
          hideTabButton: true,
        }}
      />
      <Tab.Screen
        name="login"
        component={LoginScreen}
        options={{
          title: t('routes.login'),
          hideTabButton: true,
        }}
      />
    </Tab.Navigator>
  );
}
