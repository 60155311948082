export const en = {
  legCard: {
    status: {
      onTime: 'On Time',
      delayed: 'Delayed',
      cancelled: 'Cancelled',
      missingAddress: 'Address is missing',
      addressOutOfRange: 'Address is out of range',
    },
    expressHomeService: {
      label: 'Home Service',
    },
    instructions: {
      departureTitle: 'When you depart...',
      arrivalTitle: 'When you arrive...',
    },
    specialRequests: {
      title: 'Special requests',
    },
    actions: {
      updateAddress: {
        success: 'Updated address for this leg',
        error: 'Failed to update address for this leg',
      },
      updatePickupTime: {
        success: 'Updated pickup time for this leg',
        error: 'Failed to update pickup time for this leg',
      },
      updateSpecialRequest: {
        success: 'Updated special request',
        error: 'Failed to update special request',
      },
      deleteSpecialRequest: {
        success: 'Deleted special request',
        error: 'Failed to delete special request',
      },
      createSpecialRequest: {
        success: 'Created special request',
        error: 'Failed to create special request',
      },
    },
  },
};
