import { Button, ButtonProps, withTheme } from '@rneui/themed';

import { colors } from '../theme/theme';

export const SecondaryButton = withTheme<ButtonProps>((props) => {
  return (
    <Button
      {...props}
      buttonStyle={[{ backgroundColor: colors.primaryText }, props.buttonStyle]}
      titleStyle={[{ color: colors.primary }, props.titleStyle]}
    />
  );
});

declare module '@rneui/themed' {
  export interface FullTheme {
    SecondaryButton: Partial<ButtonProps>;
  }
}
