import { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { CheckBox } from '@rneui/themed';

type RadioItem = {
  id?: any;
  title: string;
  children?: React.ReactNode;
};

interface RadioGroupProps {
  items: Array<RadioItem>;
  value?: any;
  onChange: (item: RadioItem) => void;
  computeValueIndex?: (items: Array<RadioItem>, value: any) => number;
}

export function RadioGroup({
  items,
  value,
  onChange,
  computeValueIndex,
}: RadioGroupProps) {
  const valueIndex =
    computeValueIndex?.(items, value) ?? items.findIndex((n) => n.id === value);

  const [checked, setChecked] = useState<number>(valueIndex ?? 0);
  const updateCheckedAsRadio = useCallback(
    (newChecked) => {
      if (newChecked === checked) {
        return;
      }
      setChecked(newChecked);
      const item = items.find((_i, index) => index === newChecked);
      onChange(item);
    },
    [setChecked, checked],
  );

  useEffect(() => {
    const valueIndex =
      computeValueIndex?.(items, value) ??
      items.findIndex((n) => n.id === value);

    setChecked(valueIndex ?? 0);
  }, [value]);

  return (
    <View>
      {items.map((item, index) => {
        const { title, children } = item;
        const selected = checked === index;
        return (
          <View key={`radio-${index}`}>
            <CheckBox
              title={title}
              checkedIcon={<Icon name="dot-circle-o" size={24} />}
              uncheckedIcon={<Icon name="circle-o" size={24} />}
              checked={selected}
              onPress={() => updateCheckedAsRadio(index)}
            />
            {selected ? children : null}
          </View>
        );
      })}
    </View>
  );
}
