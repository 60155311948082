import { newRidgeState } from 'react-ridge-state';

import { Breadcrumb } from '../../../components/subnavs/Breadcrumbs';

export interface BookWizardState {
  breadcrumbs?: Array<Breadcrumb>;
  breadcrumbPrice?: string;
  showBreadcrumbs: boolean;
}

export const bookWizardState = newRidgeState<BookWizardState>({
  breadcrumbs: [
    {
      active: false,
      link: '/book/0',
      label: 'Flight Info',
    },
    {
      active: false,
      link: '/book/1',
      label: 'Locations',
    },
    {
      active: false,
      link: '/book/2',
      label: 'Times',
    },
    {
      active: false,
      link: '/book/3',
      label: 'Your Info',
    },
    {
      active: false,
      link: '/book/4',
      label: 'Payment',
    },
  ],
  breadcrumbPrice: null,
  showBreadcrumbs: false,
});

export function setCrumbActive(index: number) {
  const { breadcrumbs } = bookWizardState.get();

  for (let i = 0; i < breadcrumbs.length; i++) {
    if (!breadcrumbs[i]) {
      continue;
    }

    breadcrumbs[i].active = false;
  }

  if (breadcrumbs[index]) {
    breadcrumbs[index].active = true;

    bookWizardState.set((state) => ({
      ...state,
      breadcrumbs,
    }));
  }
}
