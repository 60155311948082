import { api } from '../../../api';

export async function deletePaymentMethod(methodId: number): Promise<boolean> {
  try {
    await api.delete(
      `door_to_door_payments/payment_method/?payment_method_id=${methodId}`,
    );
    return true;
  } catch (e) {
    return false;
  }
}
