import { format } from 'date-fns';
import { DateTime } from 'luxon';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, ViewStyle } from 'react-native';

import { Text } from '@rneui/themed';

import { FormRow } from '../../../../components/forms/FormRow';
import { Select } from '../../../../components/forms/Select';
import { TimeInterval } from '../../../trip/components/tripWizard/utils/dates';

export type TimeFormFields = {
  hasBaggage: boolean;
  flightTime: string;
  interval: TimeInterval;
  intervals?: Array<TimeInterval>;
};

interface TimeFormProps {
  type: 'pickup' | 'dropoff';
  formControl: UseFormReturn<TimeFormFields>;
  containerStyle?: ViewStyle;
}

export function TimeForm({ type, formControl, containerStyle }: TimeFormProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
  } = formControl;

  if (!getValues()?.flightTime) {
    return null;
  }

  // Flight time is missing the timezone, so we need to capture it from the interval and pass it down
  const intervalTimeZone = DateTime.fromISO(getValues().interval.value, {
    setZone: true,
  }).zone;

  const flightTime = format(
    DateTime.fromISO(getValues().flightTime, {
      zone: intervalTimeZone,
      setZone: true,
    }).toJSDate(),
    'hh:mm a',
  );
  const recommendedInterval = format(
    DateTime.fromISO(getValues().interval.value, { setZone: true }).toJSDate(),
    'hh:mm a',
  );

  return (
    <View style={{ ...containerStyle }}>
      <Text h1>
        {type === 'pickup'
          ? t('screens.book.timeCard.title')
          : t('screens.book.timeCard.dropoffTitle')}
      </Text>
      <Text>
        {t('screens.book.timeCard.basedOnTime')}
        <Text style={{ fontWeight: 'bold' }}>{flightTime}</Text>
        {t('screens.book.timeCard.weRecommend')}
        <Text style={{ fontWeight: 'bold' }}>{recommendedInterval}</Text>
        {t('screens.book.timeCard.changesAllowed')}
      </Text>
      <FormRow
        style={{
          marginTop: 25,
          zIndex: 10,
        }}
      >
        <Controller
          name="hasBaggage"
          control={control}
          render={({ field }) => {
            return (
              <Select
                label={t('screens.book.timeCard.fields.bags')}
                data={['Yes', 'No'].map((d) => ({
                  value: d === 'Yes',
                  title: d,
                }))}
                width={200}
                initialValue={{
                  value: field.value,
                  title: field.value ? 'Yes' : 'No',
                }}
                errorMessage={errors.hasBaggage?.message}
                onSelectItem={(baggage) => {
                  field.onChange(baggage.value);
                }}
              />
            );
          }}
        />
        <Controller
          name="interval"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => {
            return (
              <Select
                label={t('screens.book.timeCard.fields.time')}
                data={getValues().intervals.map((d) => ({
                  value: d.offset,
                  title: format(
                    DateTime.fromISO(d.value, { setZone: true }).toJSDate(),
                    'hh:mm a',
                  ),
                }))}
                initialValue={{
                  value: field.value.offset,
                  title: format(
                    DateTime.fromISO(field.value.value, {
                      setZone: true,
                    }).toJSDate(),
                    'hh:mm a',
                  ),
                }}
                required
                errorMessage={errors.interval?.message}
                width={380}
                onSelectItem={(item) => {
                  const interval = getValues().intervals.find(
                    (i) => i.offset === item.value,
                  );
                  field.onChange(interval);
                }}
              />
            );
          }}
        />
      </FormRow>
    </View>
  );
}
