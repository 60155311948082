import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import Toast from 'react-native-toast-message';

import { Text } from '@rneui/themed';

import { PlainButton } from '../../../../../components/button/PlainButton';
import { colors, sizing, spacing } from '../../../../../components/theme/theme';
import { QuoteResponse } from '../../../api/getPriceQuote';
import { refreshCurrentTrip } from '../../../api/refreshCurrentTrip';
import {
  HOME_SERVICE_VIRTUAL_AIRPORT_IATA,
  LegAddress,
  LegQuote,
  PickUpDetails,
} from '../../../api/types';
import { updateLegQuote } from '../../../api/updateLegQuote';
import {
  PickupDetailsUpdate,
  updatePickupDetails,
} from '../../../api/updatePickupDetails';
import { updatePickupTime } from '../../../api/updatePickupTime';
import { TimeInterval } from '../../tripWizard/utils/dates';
import { EditAddressModal } from '../modals/EditAddressModal';
import { EditPickupTimeModal } from '../modals/EditPickupTimeModal';
import { AddressLabel } from './AddressLabel';
import { AirportLabel } from './AirportLabel';
import { LayoverDuration } from './LayoverDuration';
import { QuoteLabel } from './QuoteLabel';

interface LegLocationProps {
  editable: boolean;
  legId: number;
  airportIata: string;
  airportName: string;
  date: Date;
  address?: LegAddress;
  isDeparture?: boolean;
  layoverTime?: string;
  details?: PickUpDetails;
  quote: LegQuote;
}

export function LegLocation({
  editable,
  legId,
  airportIata,
  airportName,
  date,
  address,
  isDeparture,
  layoverTime,
  details,
  quote,
}: LegLocationProps) {
  const { t } = useTranslation();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showPickupTimeModal, setShowPickupTimeModal] = useState(false);

  const hasValidAddress =
    address?.first_address_line.length > 0 &&
    address?.first_address_line !== '/';

  const isHomeService = airportIata === HOME_SERVICE_VIRTUAL_AIRPORT_IATA;

  const timeLabel = `${t(
    `global.common.${isDeparture ? 'departs' : 'arrives'}`,
  )} ${format(date, 'h:mma')}`;

  const handleUpdateAddress = useCallback(
    async (update: PickupDetailsUpdate, quote: QuoteResponse) => {
      const updates = [
        await updatePickupDetails(
          legId,
          isDeparture ? 'pickup_address' : 'drop_off_address',
          update,
        ),
        await updateLegQuote(legId, quote.quoteId),
      ];

      const success = updates.reduce((all, result) => result && all, true);

      if (success) {
        await refreshCurrentTrip();
        setShowAddressModal(false);
        Toast.show({
          type: 'success',
          text1: t('screens.tripDetail.legCard.actions.updateAddress.success'),
        });
      } else {
        Toast.show({
          type: 'error',
          text1: t('screens.tripDetail.legCard.actions.updateAddress.error'),
        });
      }
    },
    [legId, isDeparture],
  );

  const handleUpdatePickupTime = useCallback(
    async (update: TimeInterval) => {
      const success = await updatePickupTime(legId, update.offset);

      if (success) {
        await refreshCurrentTrip();
        setShowPickupTimeModal(false);
        Toast.show({
          type: 'success',
          text1: t(
            'screens.tripDetail.legCard.actions.updatePickupTime.success',
          ),
        });
      } else {
        Toast.show({
          type: 'error',
          text1: t('screens.tripDetail.legCard.actions.updatePickupTime.error'),
        });
      }
    },
    [legId],
  );

  return (
    <View
      style={{
        alignSelf: isDeparture ? 'flex-start' : 'flex-end',
        alignItems: isDeparture ? 'flex-start' : 'flex-end',
      }}
    >
      <AirportLabel
        airportIata={airportIata}
        airportName={airportName}
        isDeparture={isDeparture}
      />
      {isHomeService && editable && details ? (
        <PlainButton
          hideBorder
          buttonStyle={{ padding: 0, paddingHorizontal: 0 }}
          title={timeLabel}
          icon={{
            name: 'edit',
            type: 'font-awesome',
            size: sizing.md,
            color: colors.accent,
          }}
          iconRight
          onPress={() => setShowPickupTimeModal(true)}
        />
      ) : (
        <Text>{timeLabel}</Text>
      )}
      {layoverTime ? <LayoverDuration layoverTime={layoverTime} /> : null}
      {hasValidAddress ? (
        <View style={{ marginTop: spacing.md }}>
          {isHomeService && editable ? (
            <PlainButton
              hideBorder
              buttonStyle={{ padding: 0, paddingHorizontal: 0 }}
              icon={{
                name: 'edit',
                type: 'font-awesome',
                size: sizing.md,
                color: colors.accent,
              }}
              iconRight
              title={
                <View
                  style={Platform.select({
                    web: {
                      flex: 1,
                    },
                  })}
                >
                  <AddressLabel
                    address={address}
                    align={isDeparture ? 'left' : 'right'}
                  />
                </View>
              }
              onPress={() => setShowAddressModal(true)}
            />
          ) : (
            <AddressLabel
              address={address}
              align={isDeparture ? 'left' : 'right'}
            />
          )}
        </View>
      ) : null}
      {!isDeparture && quote ? <QuoteLabel quote={quote} /> : null}

      <EditAddressModal
        open={showAddressModal}
        iata={airportIata}
        type={isDeparture ? 'pickup' : 'dropoff'}
        addressValue={address}
        onRequestClose={() => setShowAddressModal(false)}
        onConfirm={handleUpdateAddress}
      />
      {details ? (
        <EditPickupTimeModal
          open={showPickupTimeModal}
          details={details}
          onRequestClose={() => setShowPickupTimeModal(false)}
          onConfirm={handleUpdatePickupTime}
        />
      ) : null}
    </View>
  );
}
