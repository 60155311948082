import { ScrollView, ViewStyle } from 'react-native';

import { useMaxViewHeight } from '../../../components/theme/theme';

interface CardContainerProps {
  children: React.ReactNode;
  containerStyle?: ViewStyle;
}

export function CardContainer({
  children,
  containerStyle,
}: CardContainerProps) {
  const maxViewHeight = useMaxViewHeight();

  return (
    <ScrollView
      style={{
        maxHeight: maxViewHeight,
        paddingBottom: 100,
        ...containerStyle,
      }}
    >
      {children}
    </ScrollView>
  );
}
