import React from 'react';
import { View } from 'react-native';

import { Text } from '@rneui/themed';

import { fontFamily } from '../theme/theme';
import NotFoundSvg from './NotFoundSvg';

interface NotFoundProps {
  title: string;
}

export function NotFound({ title }: NotFoundProps) {
  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
      <Text
        style={{
          fontFamily: fontFamily.bold,
        }}
      >
        {title}
      </Text>
      <NotFoundSvg width={450} height={450} />
    </View>
  );
}
