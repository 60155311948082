import { Button, ButtonProps, withTheme } from '@rneui/themed';

import { colors } from '../theme/theme';

export interface PlainButtonProps extends ButtonProps {
  hideBorder?: boolean;
}

export const PlainButton = withTheme<PlainButtonProps>(
  ({ buttonStyle, titleStyle, ...props }) => {
    return (
      <Button
        buttonStyle={[
          {
            backgroundColor: 'transparent',
            borderColor: colors.primaryText,
            borderWidth: props.hideBorder ? 0 : 1,
          },
          buttonStyle,
        ]}
        titleStyle={[{ color: colors.primaryText }, titleStyle]}
        {...props}
      />
    );
  },
);

declare module '@rneui/themed' {
  export interface FullTheme {
    PlainButton: Partial<ButtonProps>;
  }
}
