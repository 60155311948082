import { tripState } from '../state/tripState';
import { fetchCurrentTrip } from './fetchCurrentTrip';
import { Trip } from './types';

/**
 * Refreshes the current trip in state
 */
export async function refreshCurrentTrip(): Promise<Trip> {
  try {
    const trip = await fetchCurrentTrip();

    tripState.set((current) => ({
      ...current,
      currentTrip: trip,
    }));

    // Accessing data so it becomes available in the next screen immediately
    tripState.get();

    return trip;
  } catch (e) {
    return null;
  }
}
