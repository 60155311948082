import axios from 'axios';
import Constants from 'expo-constants';

import { get } from './state/secureStore';

const { API_BASE_URL } = Constants.manifest.extra;

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Attach the access token to requests when its available
api.interceptors.request.use(async (requestConfig) => {
  const accessToken = await get('accessToken');

  if (accessToken) {
    requestConfig.headers.Authorization = `JWT ${accessToken}`;
  }

  return requestConfig;
});

// Attach the reservation hash to the request when available
api.interceptors.request.use(async (requestConfig) => {
  const accessToken = await get('accessToken');
  const reservationHash = await get('reservationHash');

  if (!accessToken && reservationHash) {
    requestConfig.headers.reservation = decodeURIComponent(reservationHash);
  }

  return requestConfig;
});
