import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { Text, useTheme } from '@rneui/themed';

import { useDidUpdateEffect } from '../../../../../components/hooks/useDidUpdateEffect';
import { sizing, spacing } from '../../../../../components/theme/theme';

interface LayoverDurationProps {
  layoverTime: string;
}

function getLayoverDuration(layoverTime: string): string {
  const times = layoverTime.split(':');
  const hours = parseInt(times[0]);
  const minutes = parseInt(times[1]);

  if (minutes === 0) {
    return `${hours}h`;
  }

  if (hours === 0) {
    return `${minutes}m`;
  }

  return `${hours}h ${minutes}m`;
}

export function LayoverDuration({ layoverTime }: LayoverDurationProps) {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const fadeAnim = useRef(new Animated.Value(1)).current;

  useDidUpdateEffect(() => {
    fadeAnim.setValue(0.25);
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 400,
      useNativeDriver: true,
    }).start();
  }, [layoverTime]);

  return (
    <Animated.View
      style={{
        backgroundColor: theme.colors.grey5,
        padding: spacing.sm,
        flexDirection: 'row',
        alignItems: 'center',
        opacity: fadeAnim,
      }}
    >
      <Icon
        name="clock-o"
        size={sizing.md}
        style={{ marginRight: spacing.sm }}
      />
      <Text>
        {getLayoverDuration(layoverTime)}{' '}
        {t('global.common.layover').toLowerCase()}
      </Text>
    </Animated.View>
  );
}
