import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Toast from 'react-native-toast-message';

import { Text } from '@rneui/themed';

import { IconButton } from '../../../../../components/button/IconButton';
import { PlainButton } from '../../../../../components/button/PlainButton';
import {
  colors,
  fontFamily,
  sizing,
} from '../../../../../components/theme/theme';
import { createLegSpecialRequest } from '../../../api/createLegSpecialRequest';
import { deleteLegSpecialRequest } from '../../../api/deleteLegSpecialRequest';
import { refreshCurrentTrip } from '../../../api/refreshCurrentTrip';
import { LegNote } from '../../../api/types';
import { updateLegSpecialRequest } from '../../../api/updateLegSpecialRequest';
import { EditSpecialRequestsModal } from '../modals/EditSpecialRequestsModal';

interface SpecialRequestNotesProps {
  notes: Array<LegNote>;
  legID: number;
}

export function SpecialRequestNotes({
  notes,
  legID,
}: SpecialRequestNotesProps) {
  const { t } = useTranslation();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [editingNote, setEditingNote] = useState<LegNote>(null);

  // Perform change
  const handleCreateNote = useCallback(
    async (updatedNote: string) => {
      const success = await createLegSpecialRequest(legID, updatedNote);
      if (success) {
        await refreshCurrentTrip();
        setShowNotesModal(false);
        Toast.show({
          type: 'success',
          text1: t(
            'screens.tripDetail.legCard.actions.createSpecialRequest.success',
          ),
        });
      } else {
        Toast.show({
          type: 'error',
          text1: t(
            'screens.tripDetail.legCard.actions.createSpecialRequest.error',
          ),
        });
      }
    },
    [setShowNotesModal],
  );

  const handleDeleteNote = useCallback(async (noteId: number) => {
    const success = await deleteLegSpecialRequest(noteId);
    if (success) {
      await refreshCurrentTrip();
      Toast.show({
        type: 'success',
        text1: t(
          'screens.tripDetail.legCard.actions.deleteSpecialRequest.success',
        ),
      });
    } else {
      Toast.show({
        type: 'error',
        text1: t(
          'screens.tripDetail.legCard.actions.deleteSpecialRequest.error',
        ),
      });
    }
  }, []);

  const handleUpdateNote = useCallback(
    async (noteId: number, update: string) => {
      const success = await updateLegSpecialRequest(noteId, update);
      if (success) {
        await refreshCurrentTrip();
        setShowNotesModal(false);
        Toast.show({
          type: 'success',
          text1: t(
            'screens.tripDetail.legCard.actions.updateSpecialRequest.success',
          ),
        });
      } else {
        Toast.show({
          type: 'error',
          text1: t(
            'screens.tripDetail.legCard.actions.updateSpecialRequest.error',
          ),
        });
      }
    },
    [setShowNotesModal],
  );

  // Present change
  const handleEditCurrentNote = useCallback(
    (note: LegNote) => {
      setEditingNote(note);
      setShowNotesModal(true);
    },
    [setShowNotesModal, setEditingNote],
  );

  const handleEditNewNote = useCallback(() => {
    setEditingNote(null);
    setShowNotesModal(true);
  }, [setShowNotesModal, setEditingNote]);

  return (
    <View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={{ fontFamily: fontFamily.bold }}>
          {t('screens.tripDetail.legCard.specialRequests.title')}
        </Text>
        <IconButton icon="plus" onPress={handleEditNewNote} />
      </View>
      {notes.map((note) => (
        <View
          key={note.id}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <IconButton icon="edit" onPress={() => handleEditCurrentNote(note)} />
          <IconButton icon="remove" onPress={() => handleDeleteNote(note.id)} />
          <Text>{note.description}</Text>
        </View>
      ))}
      <EditSpecialRequestsModal
        open={showNotesModal}
        note={editingNote?.description}
        onRequestClose={() => setShowNotesModal(false)}
        onConfirm={(note) => {
          if (editingNote) {
            handleUpdateNote(editingNote.id, note);
            return;
          }
          handleCreateNote(note);
        }}
      />
    </View>
  );
}
