type States = {
  title: string;
  value: string;
};
export function getStates(): Array<States> {
  return [
    {
      title: 'Colorado',
      value: 'CO',
    },
    {
      title: 'Minnesota',
      value: 'MN',
    },
    {
      title: 'Pennsylvania',
      value: 'PA',
    },
  ];
}
