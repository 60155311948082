import React, { useEffect } from 'react';

import { LoadingBus } from '../../../../../components/loading/LoadingBus';
import { refreshCurrentTrip } from '../../../api/refreshCurrentTrip';

// Refreshes the trip, then closes
interface FinalCardProps {
  onDone: () => void;
}

export function FinalCard({ onDone }: FinalCardProps) {
  useEffect(() => {
    refreshCurrentTrip().finally(() => {
      onDone();
    });
  }, []);

  return <LoadingBus />;
}
