import { DateTime } from 'luxon';

export interface TimeInterval {
  value: string /* ISO8601 Date  */;
  offset: number;
}

export function getTimeIntervals(
  startTime: string,
  endTime: string,
  increment: number,
): Array<TimeInterval> {
  const start = DateTime.fromISO(startTime, {
    setZone: true,
  });

  const end = DateTime.fromISO(endTime, {
    setZone: true,
  });

  const result = [];

  let current = start;
  let currentOffset = 0;

  if (start < end) {
    while (current <= end) {
      result.push({ value: current.toISO(), offset: currentOffset });
      current = current.plus({
        minutes: increment,
      });

      currentOffset += increment;
    }
  } else {
    while (current >= end) {
      result.push({ value: current.toISO(), offset: currentOffset });
      current = current.minus({
        minutes: increment,
      });

      currentOffset += increment;
    }
  }

  return result;
}
