import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import Toast from 'react-native-toast-message';

import { Link, useRoute } from '@react-navigation/native';
import { Text } from '@rneui/themed';

import { LandlineLogo } from '../../components/logo/LandlineLogo';
import { fontFamily, spacing } from '../../components/theme/theme';
import { MainRouteConfig } from '../../routes/types';
import { set } from '../../state/secureStore';
import {
  CreateUserResult,
  CreateUserValidationErrorResult,
  createUser,
} from './api';
import { useAuth } from './components/context/useAuth';
import { AccountDetailsForm } from './components/forms/AccountDetailsForm';

export function RegisterScreen({ navigation }) {
  const { t } = useTranslation();
  const { setToken } = useAuth();

  const route = useRoute();
  const routeParams = route.params as MainRouteConfig['register'];

  const [serverErrors, setServerErrors] =
    useState<CreateUserValidationErrorResult>();

  const [loading, setLoading] = useState(false);

  const handleRegister = useCallback(
    async ({ email, password, first_name, last_name, phone_number }) => {
      setLoading(true);

      const result = await createUser({
        username: email,
        password,
        firstName: first_name,
        lastName: last_name,
        phoneNumber: phone_number,
      });

      if (!result) {
        Toast.show({
          type: 'error',
          text1: t('screens.auth.action.register.error'),
        });
      } else if ((result as CreateUserValidationErrorResult).validation_error) {
        Toast.show({
          type: 'error',
          text1: t('screens.auth.action.register.error'),
        });

        setServerErrors(result as CreateUserValidationErrorResult);
      } else {
        const createResult = result as CreateUserResult;
        setToken(createResult);
        await Promise.all([
          set('accessToken', createResult.access),
          set('refreshToken', createResult.refresh),
        ]);

        const returnTo =
          routeParams.return && routeParams.return !== 'undefined'
            ? routeParams.return
            : 'my-trips';

        navigation.navigate(returnTo);
      }

      setLoading(false);
    },
    [setToken, setLoading, setServerErrors],
  );

  return (
    <ScrollView
      testID="register-screen"
      contentContainerStyle={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
      style={{
        flex: 1,
        paddingTop: 25,
      }}
    >
      <View style={{ width: '100%', paddingHorizontal: spacing.md }}>
        <AccountDetailsForm
          serverErrors={serverErrors?.validation_error}
          loading={loading}
          type="create"
          onSubmit={handleRegister}
        />
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text>{t('screens.auth.login.label')}</Text>
        <View style={{ marginLeft: spacing.sm }}>
          <Text
            style={{
              fontFamily: fontFamily.bold,
            }}
          >
            <Link to={{ screen: 'login' }}>{t('screens.auth.login.link')}</Link>
          </Text>
        </View>
      </View>
    </ScrollView>
  );
}
