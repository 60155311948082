import { format, parseISO } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Text } from '@rneui/themed';

import { fontFamily, sizing } from '../../../../components/theme/theme';
import { SEGMENT_TYPE, Segment } from '../../api/types';

interface SegmentSectionHeaderProps {
  pnr: string;
  type: SEGMENT_TYPE;
  segment: Segment;
}

export function SegmentSectionHeader({
  pnr,
  type,
  segment,
}: SegmentSectionHeaderProps) {
  const { t } = useTranslation();

  return (
    <View
      style={{
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <Text style={{ fontFamily: fontFamily.bold, fontSize: sizing.lg }}>
        {pnr}
      </Text>
      <Text>
        <Text style={{ fontFamily: fontFamily.bold }}>
          {t(
            `global.common.${
              type == SEGMENT_TYPE.DEPARTURE ? 'departs' : 'returns'
            }`,
          )}
          :{' '}
        </Text>
        {format(parseISO(segment.legs[0].departure_time), 'eeee, MMMM co yyyy')}
      </Text>
    </View>
  );
}
