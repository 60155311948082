import React from 'react';
import { View } from 'react-native';

import { Text } from '@rneui/themed';

import { LegQuote } from '../../../api/types';
import { format } from '../../tripWizard/utils/money';

interface QuoteLabelProps {
  quote: LegQuote;
}

export function QuoteLabel({ quote }: QuoteLabelProps) {
  return (
    <View>
      <Text style={{ textAlign: 'right' }}>
        ({quote?.distance_rounded ?? 0} miles)
      </Text>
      <Text style={{ textAlign: 'right' }}>
        {format(quote?.total_price_rounded ?? 0)}
      </Text>
    </View>
  );
}
