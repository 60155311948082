import { api } from '../../../api';

export async function createLegSpecialRequest(
  legID: number,
  note: string,
): Promise<boolean> {
  const data = {
    description: note,
  };

  if (!note.length) {
    return true;
  }

  try {
    await api.post(`v2/booking/trips/${legID}/post_passenger_note/`, data);
    return true;
  } catch (e) {
    return false;
  }
}
