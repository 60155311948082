import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyle, View, ViewStyle } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { Text } from '@rneui/themed';

import { colors, sizing } from '../../../../../components/theme/theme';

interface AirportFromToLabelProps {
  iata: string;
  type: 'pickup' | 'dropoff';
  style?: ViewStyle;
  labelStyle?: TextStyle;
}

export function AirportFromToLabel({
  iata,
  type,
  style,
  labelStyle,
}: AirportFromToLabelProps) {
  const { t } = useTranslation();
  return (
    <View
      style={{
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        ...style,
      }}
    >
      <Text
        style={{
          fontSize: sizing.sz,
          ...labelStyle,
        }}
      >
        {type == 'pickup' ? t('global.common.homeService') : iata}
      </Text>
      <Icon name="arrow-right" color={colors.primary} size={sizing.md} />
      <Text style={{ fontSize: sizing.sz, ...labelStyle }}>
        {type == 'pickup' ? iata : t('global.common.homeService')}
      </Text>
    </View>
  );
}
