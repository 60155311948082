export const en = {
  updateUser: {
    title: 'Update your user profile',
  },
  updatePassword: {
    title: 'Update your password',
  },
  action: {
    updateUser: {
      buttonTitle: 'Update profile',
      error: 'Unable to update your profile',
      success: 'Updated user profile',
    },
  },
};
