import React from 'react';
import { Linking } from 'react-native';

import { PlainButton, PlainButtonProps } from './PlainButton';

interface ExternalLinkProps {
  url: string;
  title: PlainButtonProps['title'];
  titleStyle?: PlainButtonProps['titleStyle'];
  buttonStyle?: PlainButtonProps['buttonStyle'];
  containerStyle?: PlainButtonProps['containerStyle'];
}

export function ExternalLink({
  url,
  title,
  titleStyle,
  buttonStyle,
  containerStyle,
}: ExternalLinkProps) {
  return (
    <PlainButton
      hideBorder
      onPress={() => Linking.openURL(url)}
      title={title}
      titleStyle={titleStyle}
      buttonStyle={buttonStyle}
      containerStyle={containerStyle}
    />
  );
}
