import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@rneui/themed';

import { colors } from '../../../../../components/theme/theme';
import { Trip } from '../../../api/types';
import { CardLayout } from './CardLayout';

interface IntroCardProps {
  onPressNext: () => void;
  trip: Trip;
}

export function IntroCard({ onPressNext, trip }: IntroCardProps) {
  const { t } = useTranslation();

  const primaryPassengerName = `${trip.passengers[0].first_name} ${trip.passengers[0].last_name}`;

  return (
    <CardLayout
      icon="user-o"
      testID="trip-wizard-intro-card"
      title={t('screens.tripDetail.tripWizard.intro.title', {
        name: primaryPassengerName,
      })}
      subTitle={
        <Text
          style={{
            color: colors.secondaryText,
          }}
        >
          {t('screens.tripDetail.tripWizard.intro.body')}
        </Text>
      }
      onPressNext={onPressNext}
      showBackButton={false}
    />
  );
}
