import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import Wizard from 'react-native-wizard';

import { BookingConfirmedCard } from './cards/BookingConfirmedCard';
import { BookingSummaryCard } from './cards/BookingSummaryCard';
import { FlightInfoCard } from './cards/FlightInfoCard';
import { LocationCard } from './cards/LocationCard';
import { PassengerInfoCard } from './cards/PassengerInfoCard';
import { TimeCard } from './cards/TimeCard';
import { setCrumbActive } from './state/bookWizardState';

export type StepController = {
  setActiveStep: (index) => void;
};

interface BookWizardProps {
  stepControllerRef?: MutableRefObject<StepController>;
  stepIndex?: number;
  onNextStep?: () => void;
}

export function BookWizard({
  stepControllerRef,
  stepIndex = 0,
  onNextStep,
}: BookWizardProps) {
  const wizard = useRef(null);
  const [activeStep, setActiveStep] = useState(stepIndex);

  const steps = useMemo(
    () => [
      {
        id: 'flight-info',
        content: <FlightInfoCard onPressNext={() => wizard.current.next()} />,
      },
      {
        id: 'pickup-location',
        content: <LocationCard onPressNext={() => wizard.current.next()} />,
      },
      {
        id: 'pickup-time',
        content: <TimeCard onPressNext={() => wizard.current.next()} />,
      },
      {
        id: 'passenger-info',
        content: (
          <PassengerInfoCard onPressNext={() => wizard.current.next()} />
        ),
      },
      {
        id: 'summary',
        content: (
          <BookingSummaryCard onPressNext={() => wizard.current.next()} />
        ),
      },
      {
        id: 'confirmed',
        content: <BookingConfirmedCard />,
      },
    ],
    [],
  );

  useEffect(() => {
    if (stepControllerRef && wizard.current) {
      stepControllerRef.current = {
        setActiveStep: (i) => {
          setActiveStep(i);
          wizard.current.goTo(i);
        },
      };
    }
  }, [setActiveStep, wizard]);

  useEffect(() => {
    setCrumbActive(activeStep);
    onNextStep?.();
  }, [activeStep]);

  return (
    <Wizard
      steps={steps}
      ref={wizard}
      activeStep={activeStep}
      currentStep={({ currentStep }) => setActiveStep(currentStep)}
      duration={850}
    />
  );
}
