import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, View } from 'react-native';

import { Card, Text, useTheme } from '@rneui/themed';

import { PaymentMethod } from '../../screens/trip/api/getPaymentMethods';
import { VendorCardImage } from '../../screens/trip/components/tripWizard/cards/vendorImages/VendorCardImage';
import { IconButton } from '../button/IconButton';
import { colors, sizing } from '../theme/theme';

export type ExistingPaymentMethodFields = {
  paymentMethod?: PaymentMethod;
};

interface ExistingPaymentMethodFormProps {
  formControl: UseFormReturn<ExistingPaymentMethodFields>;
  paymentMethods: Array<PaymentMethod>;
  onRemoveMethod: (method: PaymentMethod) => void;
}

export function ExistingPaymentMethodForm({
  paymentMethods,
  formControl,
  onRemoveMethod,
}: ExistingPaymentMethodFormProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const value = formControl.watch('paymentMethod');

  const handleSelectPaymentMethod = useCallback((method: PaymentMethod) => {
    formControl.setValue('paymentMethod', method);
  }, []);

  return (
    <ScrollView>
      {paymentMethods.map((method, i) => {
        const isSelected = method.id === value?.id;

        return (
          <View
            key={`payment-method-item-${i}`}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Pressable
              style={{
                flex: 1,
              }}
              disabled={method.isExpired}
              onPress={() => handleSelectPaymentMethod(method)}
            >
              <Card
                containerStyle={{
                  borderColor: isSelected
                    ? colors.llSubnavDarkLink
                    : colors.llSubnavGrayLink,
                  borderRadius: 4,
                  height: 70,
                  justifyContent: 'center',
                }}
              >
                <View
                  style={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      opacity: method.isExpired ? 0.5 : 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <VendorCardImage paymentMethod={method} />
                    <Text
                      style={[
                        {
                          marginLeft: 25,
                          fontSize: sizing.sz,
                          textTransform: 'capitalize',
                        },
                        method.isExpired ? { color: colors.outlineGray } : null,
                      ]}
                    >
                      {`${method.vendor} ${method.number}`}
                    </Text>
                  </View>
                  <View style={{ alignItems: 'flex-end' }}>
                    <Text
                      style={[
                        {
                          fontSize: 14,
                        },
                        method.isExpired
                          ? {
                              color: theme.colors.error,
                            }
                          : null,
                      ]}
                    >
                      {t(
                        method.isExpired
                          ? 'global.common.expired'
                          : 'global.common.expires',
                      )}
                      : {method.expiration}
                    </Text>
                  </View>
                </View>
              </Card>
            </Pressable>
            <IconButton
              icon="remove"
              color={colors.secondaryText}
              onPress={() => onRemoveMethod(method)}
            />
          </View>
        );
      })}
    </ScrollView>
  );
}
