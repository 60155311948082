import { api } from '../../../api';

export interface UserProfileResult {
  id: number;
  first_name: string;
  last_name: string;
  avatar: string;
  // app_settings
  // company_details
}

export async function getUserProfile(): Promise<UserProfileResult> {
  try {
    const {
      data: { id, first_name, last_name, avatar },
    } = await api.get('v2/bossadmin/users/current_user');

    return {
      id,
      first_name,
      last_name,
      avatar,
    };
  } catch (e) {
    console.log('Failed to get user profile', e);
    return null;
  }
}

export interface FullUserResult {
  address: string;
  city: string;
  state: string;
  zip_code: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  // There is more data here...
}

export async function getFullUser(): Promise<FullUserResult> {
  try {
    const {
      data: {
        address,
        city,
        state,
        zip_code,
        first_name,
        last_name,
        email,
        phone_number,
      },
    } = await api.get('v2/accounts/profile/');

    return {
      address,
      city,
      state,
      zip_code,
      first_name,
      last_name,
      email,
      phone_number,
    };
  } catch (e) {
    console.log('Failed to get full user', e);
    return null;
  }
}
