import { api } from '../../../api';

export async function updateLegSpecialRequest(
  noteId: number,
  update: string,
): Promise<boolean> {
  const data = {
    description: update,
  };

  try {
    await api.patch(`v2/booking/notes/${noteId}/`, data);
    return true;
  } catch (e) {
    return false;
  }
}
