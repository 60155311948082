import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import { Button, Text, useTheme } from '@rneui/themed';

import { PlainButton } from '../../../../../components/button/PlainButton';
import { colors, sizing, spacing } from '../../../../../components/theme/theme';

interface CardLayoutProps {
  title: string;
  subTitle?: JSX.Element;
  testID?: string;
  icon?:
    | 'user-o'
    | 'map'
    | 'arrow-left'
    | 'arrow-right'
    | 'clock-o'
    | 'edit'
    | 'briefcase'
    | 'shopping-cart'
    | 'credit-card';
  children?: JSX.Element | Array<JSX.Element>;
  nextButtonTitle?: string;
  backButtonTitle?: string;
  showBackButton?: boolean;
  showNextButton?: boolean;
  nextButtonDisabled?: boolean;
  nextButtonLoading?: boolean;
  onPressNext?: () => void;
  onPressBack?: () => void;
}

export function CardLayout({
  title,
  subTitle,
  testID,
  icon,
  children,
  nextButtonTitle,
  backButtonTitle,
  showBackButton = true,
  showNextButton = true,
  nextButtonDisabled = false,
  nextButtonLoading = false,
  onPressNext,
  onPressBack,
}: CardLayoutProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <View
      style={{ height: '100%', justifyContent: 'space-between' }}
      testID={testID}
    >
      <View
        style={{
          alignItems: 'center',
          zIndex: 10,
        }}
      >
        {icon ? (
          <Icon
            name={icon}
            size={sizing['3xl']}
            color={colors.primary}
            style={{
              marginBottom: spacing.md,
            }}
          />
        ) : null}
        <Text
          h3
          h3Style={{
            fontSize: sizing.xl,
            marginBottom: spacing.md,
            textAlign: 'center',
          }}
        >
          {title}
        </Text>
        {subTitle}
      </View>
      {children}
      {showBackButton ? (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <PlainButton
            hideBorder
            icon={
              <Icon
                name="arrow-left"
                size={sizing.lg}
                color={theme.colors.grey1}
                style={{ marginRight: spacing.sm }}
              />
            }
            title={backButtonTitle ?? t('global.common.back')}
            onPress={onPressBack}
          />
          <Button
            disabled={nextButtonDisabled}
            loading={nextButtonLoading}
            title={nextButtonTitle ?? t('global.common.next')}
            onPress={onPressNext}
          />
        </View>
      ) : showNextButton ? (
        <Button
          disabled={nextButtonDisabled}
          loading={nextButtonLoading}
          title={nextButtonTitle ?? t('global.common.next')}
          onPress={onPressNext}
        />
      ) : null}
    </View>
  );
}
