import * as DriverSectionI18n from './components/driverSection/i18n';
import * as LegCardI18n from './components/legCard/i18n';
import * as PriceCardI18n from './components/priceCard/i18n';
import * as TripWizardI18n from './components/tripWizard/i18n';

export const en = {
  pnrNotFound: 'Unable to find that booking',
  tripsNotFound: 'No trips found',
  checkedBag: {
    label: 'Are you travelling with a checked bag?',
  },
  ...DriverSectionI18n.en,
  ...LegCardI18n.en,
  myTrips: {
    upcomingTripsTab: 'Upcoming trips',
    pastTripsTab: 'Past trips',
    homeIata: 'Home',
  },
  ...TripWizardI18n.en,
  ...PriceCardI18n.en,
};
