import { newRidgeState } from 'react-ridge-state';

import { UserProfile } from './types';

export interface GlobalState {
  userProfile: UserProfile;
  showFooter?: boolean;
}

export const globalState = newRidgeState<GlobalState>({
  userProfile: {
    id: null,
    firstName: null,
    lastName: null,
    avatar: null,
  },
  showFooter: true,
});
