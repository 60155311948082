import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';

import { useRoute } from '@react-navigation/native';

import { LoadingBus } from '../../components/loading/LoadingBus';
import { NotFound } from '../../components/notFound/NotFound';
import { layoutRegions, spacing } from '../../components/theme/theme';
import { checkIfMissingAddress } from '../../components/utils/address';
import { getPriceItems } from '../../components/utils/quote';
import { MainRouteConfig } from '../../routes/types';
import { get } from '../../state/secureStore';
import { refreshCurrentTrip } from './api/refreshCurrentTrip';
import { SEGMENT_TYPE } from './api/types';
import { PriceCard } from './components/priceCard/PriceCard';
import { SegmentSection } from './components/segmentSection/SegmentSection';
import { TripWizard } from './components/tripWizard/TripWizard';
import { getHomeServiceLegs } from './components/tripWizard/utils/legs';
import { tripState } from './state/tripState';

export function TripDetailScreen() {
  const { t } = useTranslation();
  const route = useRoute();
  const routeParams = route.params as MainRouteConfig['trip-details'];
  const { currentTrip } = tripState.useValue();
  const [loading, setLoading] = useState(true);
  const [showTripWizard, setShowTripWizard] = useState(false);
  const priceItems = getPriceItems(currentTrip);

  const handleFetchCurrentTrip = useCallback(async () => {
    const [currentPnr, lastName] = await Promise.all([
      get('currentPnr'),
      get('lastname'),
    ]);

    if (!currentTrip && currentPnr && lastName) {
      await refreshCurrentTrip();
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    handleFetchCurrentTrip();
  }, []);

  useEffect(() => {
    if (!currentTrip || loading) {
      return;
    }

    const departureHomeLeg =
      getHomeServiceLegs(currentTrip.segments[0])?.[0] ?? null;

    const showWizard = checkIfMissingAddress(departureHomeLeg?.leg) ?? false;
    setShowTripWizard(showWizard);
  }, [currentTrip, loading]);

  const handleCloseTripWizard = useCallback(
    () => setShowTripWizard(false),
    [setShowTripWizard],
  );

  if (loading) {
    return <LoadingBus />;
  }

  // The currentTrip must be set BEFORE accessing this screen to avoid PNR lookups
  if (!loading && (!currentTrip || currentTrip.rloc !== routeParams?.pnr)) {
    return <NotFound title={t('screens.tripDetail.pnrNotFound')} />;
  }

  return (
    <View style={[layoutRegions.centered]}>
      <ScrollView style={{ flex: 1, width: '100%', paddingBottom: spacing.md }}>
        {currentTrip.segments.map((segment, i) => {
          return (
            <SegmentSection
              key={segment.id}
              segment={segment}
              pnr={currentTrip.rloc}
              type={i === 0 ? SEGMENT_TYPE.DEPARTURE : SEGMENT_TYPE.RETURN}
            />
          );
        })}
        <PriceCard items={priceItems} />
      </ScrollView>
      <TripWizard
        open={showTripWizard}
        trip={currentTrip}
        onRequestClose={handleCloseTripWizard}
        onRequestRefreshTrip={handleFetchCurrentTrip}
      />
    </View>
  );
}
