import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Text } from '@rneui/themed';

import { FormRow } from '../../../../components/forms/FormRow';
import { Select } from '../../../../components/forms/Select';
import { TextInput } from '../../../../components/forms/TextInput';
import { getPassengerTitles } from '../../api/getPassengerTitles';

export type FlightInfoFormFields = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  airport: string;
  flight: string;
  numPassengers: string;
  departureTime: string;
  arrivalTime: string;
};

interface FlightInfoFormProps {
  formControl: UseFormReturn<FlightInfoFormFields>;
}

export function FlightInfoForm({ formControl }: FlightInfoFormProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = formControl;

  return (
    <View>
      <Text h1>{t('screens.book.welcomeCard.title')}</Text>
      <View
        style={{
          // Enable once we have this block
          display: 'none',
          height: 200,
          backgroundColor: '#ccc',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 25,
        }}
      >
        <Text>Marketing image/copy</Text>
      </View>
      <FormRow
        style={{
          marginTop: 0,
          zIndex: 10,
        }}
      >
        <Controller
          name="title"
          control={control}
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <Select
              label={t('screens.book.welcomeCard.fields.title')}
              required
              data={getPassengerTitles()}
              width={100}
              errorMessage={errors?.title?.message}
              onSelectItem={(item) => {
                field.onChange(item.value);
              }}
              initialValue={getPassengerTitles().find(
                (n) => n.value === field.value,
              )}
            />
          )}
        />
        <Controller
          name="firstName"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.welcomeCard.fields.firstName')}
              required
              errorMessage={errors?.firstName?.message}
              containerStyle={{
                width: 220,
              }}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.welcomeCard.fields.lastName')}
              required
              errorMessage={errors?.lastName?.message}
              containerStyle={{
                width: 240,
              }}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('screens.book.welcomeCard.fields.emailValidation'),
            },
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.welcomeCard.fields.email')}
              required
              errorMessage={errors?.email?.message}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="airport"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.welcomeCard.fields.airport')}
              required
              disabled
              errorMessage={errors?.airport?.message}
              containerStyle={{
                width: 286,
              }}
            />
          )}
        />
        <Controller
          name="flight"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.welcomeCard.fields.flight')}
              required
              disabled
              errorMessage={errors?.flight?.message}
              containerStyle={{
                width: 104,
              }}
              inputStyle={{
                width: 102,
              }}
            />
          )}
        />
        <Controller
          name="numPassengers"
          control={control}
          defaultValue="1"
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.welcomeCard.fields.numPassengers')}
              required
              disabled
              errorMessage={errors?.numPassengers?.message}
              containerStyle={{
                width: 170,
              }}
              inputStyle={{
                width: 168,
              }}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="departureTime"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.welcomeCard.fields.departureTime')}
              required
              disabled
              errorMessage={errors?.departureTime?.message}
              containerStyle={{
                width: 286,
              }}
            />
          )}
        />
        <Controller
          name="arrivalTime"
          control={control}
          defaultValue=""
          rules={{
            required: t('global.form.required'),
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label={t('screens.book.welcomeCard.fields.arrivalTime')}
              required
              disabled
              errorMessage={errors?.arrivalTime?.message}
              containerStyle={{
                width: 294,
              }}
            />
          )}
        />
      </FormRow>
    </View>
  );
}
