import React from 'react';

import { Image } from '@rneui/themed';

import { VendorCardImageProps } from './types';

export function VisaCardImage({ scale = 2 }: VendorCardImageProps) {
  return (
    <Image
      style={{
        width: 115 / scale,
        height: 72 / scale,
      }}
      source={{
        uri: require('../../../../../../../assets/paymentVendors/visa.png'),
      }}
      accessibilityLabel="Visa Credit Card Logo"
      placeholderStyle={{ opacity: 0 }}
    />
  );
}
