export const en = {
  home: 'Landline - Home',
  login: 'Landline - Login',
  register: 'Landline - Create Account',
  trips: 'Landline - My Trips',
  tripsTab: 'My Trips',
  reservation: 'Landline - Reservation',
  tripDetail: 'Landline - Trip Detail',
  tripDetailTab: 'Trip Detail',
  account: 'Landline - My Landline',
  accountTab: 'My Landline',
  about: 'Landline - More',
  aboutTab: 'More',
  book: 'Landline - Book Your Trip',
};
