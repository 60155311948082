import { api } from '../../../api';
import { PaginatedResult, Trip } from './types';

export async function getUpcomingTrips(
  limit: number = 10,
): Promise<PaginatedResult<Trip>> {
  try {
    const { data } = await api.get(
      `v2/booking/reservations/upcoming/?limit=${limit}`,
    );
    return data;
  } catch (e) {
    return null;
  }
}
