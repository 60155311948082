import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@rneui/themed';

import { sizing } from '../../../../../components/theme/theme';
import { HOME_SERVICE_VIRTUAL_AIRPORT_IATA } from '../../../api/types';

interface AirportLabelProps {
  airportName: string;
  airportIata: string;
  isDeparture?: boolean;
}

export function getServiceLabel(iata: string, name: string): string {
  const isHomeService = iata === HOME_SERVICE_VIRTUAL_AIRPORT_IATA;
  const serviceLabel = isHomeService ? name : iata;
  return serviceLabel;
}

export function AirportLabel({
  airportName,
  airportIata,
  isDeparture = false,
}: AirportLabelProps) {
  const { theme } = useTheme();

  const isHomeService = airportIata === HOME_SERVICE_VIRTUAL_AIRPORT_IATA;
  const serviceLabel = getServiceLabel(airportIata, airportName);

  return (
    <View
      style={{
        alignSelf: isDeparture ? 'flex-start' : 'flex-end',
        alignItems: isDeparture ? 'flex-start' : 'flex-end',
      }}
    >
      <Text style={{ fontSize: sizing.xl }}>{serviceLabel}</Text>
      <Text
        style={{
          fontSize: sizing.md,
          color: theme.colors.grey3,
          textAlign: isDeparture ? 'left' : 'right',
        }}
      >
        {isHomeService ? ' ' : airportName}
      </Text>
    </View>
  );
}
